import React, { useEffect } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { useLocation,Link, useNavigate } from "react-router-dom";
import PaymentErrorIcon from "../../assets/images/account/payment-error.png"
import { CheckNull } from "../../utils/validate";

const PaymentFailed = () => {
    const location = useLocation();
    const navigate = useNavigate()

    useEffect(()=>{
        if(CheckNull(location?.state?.reason)===""){
            navigate("/")
        }
    },[location?.state])

    return (
        <section id="payment-method-error">
            <Container>
                <Row className="payment-method-row">
                    <Col className="payment-error-page">
                        <Card className="mx-5">
                            <Card.Body>
                                <Row className="text-center">
                                    <Col md={12} className="mt-5">
                                        <img src={PaymentErrorIcon} />
                                    </Col>
                                    <Col md={12} className="mt-3">
                                        <p><span>Payment Failed</span></p>
                                        <p>Oops! It looks like there was an issue processing your payment</p>
                                        <p>{location?.state?.reason}</p>
                                    </Col>
                                    <Col md={12} className="mt-3">
                                    <Link to="/cart"><button className="payment-btn">Try again</button></Link>
                                    </Col>
                                    <Col md={12} >
                                    <Link to="/"><button>Continue Shopping</button></Link>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default PaymentFailed;