import {
  PostData,
  CreateEntry,
  GetData,
  DeleteEntry,
  GetList,
  DeleteNotificationById,
} from "../providers/queryData";
import { resourceconstant } from "../constants";
import customLogger from "../common/loggerService";
import { showSuccessToastr } from "../common/toastrService";
import { formatDate, DateFormatShow, PriceFormat } from "../utils/validate";
import moment from "moment";
import Amplify, { Auth } from 'aws-amplify';

export const MoveToWishList = async (wishListData, setResponseData) => {
  PostData(resourceconstant?.CREATE_WISH_LIST, wishListData, setResponseData);
};

export const DeleteFromWishList = async (wishListData, setResponseData) => {
  PostData(resourceconstant?.DELETE_WISH_LISTS, wishListData, setResponseData);
};

export const AddToCart = async (cartData, setResponseData, isLoggedin) => {
  CreateEntry(
    resourceconstant?.PRODUCT_ADD_TO_CART,
    isLoggedin,
    setResponseData,
    cartData
  );
};

export const UpdateUserAddress = async (addressData, setResponseData) => {
  PostData(resourceconstant?.UPDATE_USER_ADDRESS, addressData, setResponseData);
};

export const UpdateNotificationStatus = async (addressData, setResponseData) => {
  PostData(resourceconstant?.UPDATE_NOTIFICATION, addressData, setResponseData);
};

export const DeleteNotification = async (userid, notificationId, setResponseData, fuctionName) => {
  return await DeleteNotificationById("deleteNotifications", userid, notificationId, setResponseData, fuctionName);
};

export const GetUserDetails = async (userData, setResponseData) => {
  GetData(resourceconstant?.LIST_USER_DETAILS, userData, setResponseData);
};

export const GetCountryList = async (countryData, setResponseData) => {
  GetData(resourceconstant?.LIST_COUNTRY_DETAILS, countryData, setResponseData);
};

export const DeleteCart = async (cartData, isLoggedin, setResponseData) => {
  DeleteEntry(
    resourceconstant?.PRODUCT_ADD_TO_CART_DELETE,
    isLoggedin,
    cartData,
    setResponseData
  );
};
export const GetProductList = async (productData, setResponseData) => {
  GetData(resourceconstant?.PRODUCT_LIST, productData, setResponseData);
};
export const GetProductDetailList = async (
  productDetailData,
  setResponseData
) => {
  GetData(
    resourceconstant?.PRODUCT_DETAIL_LIST,
    productDetailData,
    setResponseData
  );
};

export const CreateGeneralContactUs = async (querieData, setResponseData) => {
  PostData(
    resourceconstant?.CREATE_GENERAL_CONTACTUS,
    querieData,
    setResponseData
  );
};

export const HandleAddToCartToLocal = async (
  productList,
  productDetailList,
  userId,
  userEmail,
  mobileNo,
  quantity,
  setLocalCartData,
  setDataSuccess,
  selectedAttrValue,
  setCartPageItem
) => {

  const userName = localStorage.getItem("fullName");
  const lowercaseUserName = userName ? userName.toLowerCase() : "";
  const salePrice = productDetailList?.salePrice || 0;
  const taxCharge = quantity * productDetailList?.taxCharge || 0;
  const sales = selectedAttrValue ? selectedAttrValue?.salePrice : productDetailList?.salePrice
  const cartTotal = productList?.taxDetail?.reduce(
    (total, item) => total + (item?.taxCharge * sales / 100),
    0
  );
  const totalPrice = quantity * salePrice + quantity * cartTotal;
  const newCartItem = {
    userId: userId,
    productID: productList?.id,
    productImage: productList?.thumbnailUrl
      ? productList?.thumbnailUrl
      : productList?.featuredImage?.url,
    productName: productList?.productName,
    price: selectedAttrValue
      ? selectedAttrValue?.regularPrice
      : productDetailList?.regularPrice,
    salesPrice: selectedAttrValue
      ? selectedAttrValue?.salePrice
      : productDetailList?.salePrice,
    selectedQuantity: quantity,
    brandDetail: {
      brandID: productList?.brandID,
      brandName: productList?.brandName,
    },
    shippingInfo: {
      heightUnit: `${productDetailList?.shipplingInfo?.heightUnit}`,
      heightValue: `${productDetailList?.shipplingInfo?.heightValue}`,
      lengthUnit: `${productDetailList?.shipplingInfo?.lengthUnit}`,
      lengthValue: `${productDetailList?.shipplingInfo?.lengthValue}`,
      weightUnit: `${productDetailList?.shipplingInfo?.weightUnit}`,
      weightValue: `${productDetailList?.shipplingInfo?.weightValue}`,
      widthUnit: `${productDetailList?.shipplingInfo?.widthUnit}`,
      widthValue: `${productDetailList?.shipplingInfo?.widthValue}`,
    },
    wareHouseName: `${selectedAttrValue ? selectedAttrValue?.storageInfo?.wareHouse : productDetailList?.storageInfo?.wareHouse}`,
    wareHouseId: `${selectedAttrValue ? selectedAttrValue?.storageInfo?.wareHouseId : productDetailList?.storageInfo?.wareHouseId}`,
    userName: userName,
    userMail: userEmail,
    model: selectedAttrValue
      ? selectedAttrValue?.model
      : productDetailList?.model,
    taxCharge: cartTotal ? cartTotal : 0,
    sku: `${selectedAttrValue ? selectedAttrValue.sku : productDetailList?.sku
      }`,
    searchKey: lowercaseUserName,
    totalPrice: totalPrice,
    mobileNumber: mobileNo,
    currentStock: `${productDetailList?.stockQuantity - productDetailList?.reservedStock}`,
    mainCategory: `${productList?.mainCategory}`,
    mainCategoryId: `${productList?.mainCategoryId}`,
    subCategory: `${productList?.subCategory}`,
    subCategoryId: `${productList?.subCategoryId}`,
    rating: `${productList?.rating}`,
    attrName: selectedAttrValue?.attrValueID ? selectedAttrValue?.attrName : productDetailList?.attrName,
    attrValueID: selectedAttrValue?.attrValueID ? selectedAttrValue?.attrValueID : productDetailList?.attrValueID,
    attrValue: selectedAttrValue?.attrValue
      ? selectedAttrValue?.attrValue
      : productDetailList?.attrValue,
    mainCategory: `${productList?.mainCategory}`,
    subCategoryN: `${productList?.subCategory}`,
    stockUnit: `${selectedAttrValue?.attrValueID ? selectedAttrValue?.stockUnit : productDetailList?.stockUnit}`,
    itemsPerUnit: selectedAttrValue?.attrValueID ? selectedAttrValue?.itemsPerUnit : productDetailList?.itemsPerUnit,
    currentStock: selectedAttrValue?.attrValueID ? selectedAttrValue?.stockQuantity - selectedAttrValue?.reservedStock : productDetailList?.stockQuantity - productDetailList?.reservedStock,
    mainCategoryId: productList?.mainCategoryID,
    subCategoryId: productList?.subCategoryID
  };

  try {
    let existingCartData = localStorage.getItem("cartData");
    existingCartData = existingCartData ? JSON.parse(existingCartData) : [];
    const existingProductIndex = existingCartData.findIndex(
      (item) =>
        item.productID === newCartItem.productID &&
        item.userId === newCartItem.userId &&
        item.attrValue === newCartItem.attrValue
    );
    if (existingProductIndex > -1) {
      showSuccessToastr("This product is already in the cart");
      setDataSuccess("success");
      return;
    } else {
      existingCartData.push(newCartItem);
    }

    localStorage.setItem("cartData", JSON.stringify(existingCartData));
    setCartPageItem(existingCartData)
    setLocalCartData(existingCartData);
    setDataSuccess("success");
    showSuccessToastr("Product is added to cart");
  } catch (err) {
    customLogger.error(err);
  }
};
export const ProductBuyNow = async (orderData, isLoggedin, setResponseData) => {
  GetList(resourceconstant?.PRODUCT_BUY_NOW, orderData, isLoggedin, setResponseData);
};
export const GetOrderItemList = async (orderData, setResponseData) => {
  GetData(resourceconstant?.LIST_ORDER_ITEM, orderData, setResponseData);
};

export const GetCategoryList = async (productData, setResponseData) => {
  GetData(resourceconstant?.LIST_CATEGORY, productData, setResponseData);
};

export const BestSellingProducts = async (
  cartData,
  setResponseData,
  isLoggedin
) => {
  CreateEntry(
    resourceconstant?.BEST_SELLERS,
    isLoggedin,
    setResponseData,
    cartData
  );
};

export const GetBrandList = async (productData, setResponseData) => {
  GetData(resourceconstant?.BRAND_LIST, productData, setResponseData);
};

export const GetTokenDetailList = async (productData, setResponseData) => {
  GetData(resourceconstant?.LIST_TOKEN_DETAILS, productData, setResponseData);
};

export const CreateTokenDetail = async (querieData, setResponseData) => {
  PostData(resourceconstant?.CREATE_TOKEN_DETAIL, querieData, setResponseData);
};

export const UpdateTokenDetail = async (querieData, setResponseData) => {
  PostData(resourceconstant?.UPDATE_TOKEN_DETAIL, querieData, setResponseData);
};

export const GetCartList = async (productData, setResponseData) => {
  GetData(resourceconstant?.LIST_CARTS, productData, setResponseData);
};

export const UpdateCart = async (querieData, setResponseData) => {
  PostData(resourceconstant?.UPDATE_CART, querieData, setResponseData);
};

export const GetOrderList = async (productData, setResponseData) => {
  GetData(resourceconstant?.LIST_ORDERS, productData, setResponseData);
};

export const CreateOrder = async (cartData, setResponseData, isLoggedin) => {
  CreateEntry(
    resourceconstant?.CREATE_ORDER,
    isLoggedin,
    setResponseData,
    cartData
  );
};

export const UpdateOrder = async (cartData, setResponseData, isLoggedin) => {
  CreateEntry(
    resourceconstant?.UPDATE_ORDER,
    isLoggedin,
    setResponseData,
    cartData
  );
};

export const UpdateOrderItem = async (orderItemData, setResponseData) => {
  PostData(resourceconstant?.UPDATE_ORDER_ITEM, orderItemData, setResponseData);
};

export const AddProductReview = async (productReviewData, setResponseData) => {
  PostData(resourceconstant?.CREATE_PRODUCT_REVIEW, productReviewData, setResponseData)
}
export const UpdateProductReview = async (productReviewData, setResponseData) => {
  PostData(resourceconstant?.UPDATE_PRODUCT_REVIEW, productReviewData, setResponseData)
}

export const GetDropDownMaster = async (dropDownData, setResponseData) => {
  GetData(resourceconstant?.GET_DROPDOWN_MASTER, dropDownData, setResponseData);
};

export const GetCompanyDetails = async (dropDownData, setResponseData) => {
  GetData(resourceconstant?.GET_COMPANY_DETAILS, dropDownData, setResponseData);
}

export const PhoneNoSendOtp = async (cartData, setResponseData, isLoggedin) => {
  CreateEntry(resourceconstant?.PROFILE_PHONE_SEND_OTP, isLoggedin, setResponseData, cartData);
};

export const PhoneNoVerifyOtp = async (cartData, setResponseData, isLoggedin) => {
  CreateEntry(resourceconstant?.PROFILE_PHONE_VERIFY_OTP, isLoggedin, setResponseData, cartData);
};

export const MailVerifyOtp = async (cartData, setResponseData, isLoggedin) => {
  CreateEntry(resourceconstant?.PROFILE_MAIL_OTP, isLoggedin, setResponseData, cartData);
};

export const ProductVisit = async (cartData, setResponseData, isLoggedin) => {
  PostData(resourceconstant?.PRODUCT_VISIT_COUNT, isLoggedin, setResponseData, cartData);
};

export const ProductVisitUpdate = async (cartData, setResponseData, isLoggedin) => {
  PostData(resourceconstant?.PRODUCT_VISIT_COUNT_UPDATE, isLoggedin, setResponseData, cartData);
};

export const ProductVisitList = async (cartData, setResponseData, isLoggedin) => {
  GetData(resourceconstant?.PRODUCT_VISIT_COUNT_LIST, isLoggedin, setResponseData, cartData);
};

export const ProductRatingList = async (cartData, setResponseData, isLoggedin) => {
  GetData(resourceconstant?.PRODUCT_RATING_LIST, isLoggedin, setResponseData, cartData);
};

const GetOutForDeliveryDetails = (item) => {
  if (item?.deliveryBy === "By Delivery Partner") {
    return "Delivery by: " + item?.deliveryPartner + " <br/>" +
      " Tracking No: " + item?.trackingID + "<br/>" +
      " Tracking URL: " + item?.trackingUrl
  }
  else {
    return "Delivery: " + item?.deliveryBy + " <br/>" +
      "Contact Name: " + item?.deliveryPersonInfo?.name + " <br/>" +
      " Contact Number: " + item?.deliveryPersonInfo?.contactNumber
  }
}

const GetAcceptedDetails = (orderItem, orderDetail) => {
  if (orderDetail?.deliveryInfo?.deliveryType === "Doorstep")
    if (orderItem?.returnRefundActivity?.pickUpDetails?.pickUpBy === "By Delivery Partner")
      return "<br/>Pickup: " + orderItem?.returnRefundActivity?.pickUpDetails?.pickUpBy + "<br/>Delivery Partner: " + orderItem?.returnRefundActivity?.pickUpDetails?.name + "";
    else
      return "<br/>Pickup: " + orderItem?.returnRefundActivity?.pickUpDetails?.pickUpBy + "<br/>Expected PickUp Date: " + DateFormatShow(orderItem?.returnRefundActivity?.pickUpDetails?.expectedPickUpDate, false) + "<br/>Contact name: " + orderItem?.returnRefundActivity?.pickUpDetails?.name + "<br/>Phone Number: " + orderItem?.returnRefundActivity?.pickUpDetails?.phoneNumber + "";
  else return "";
}
export const GetOrderStatus = (item, orderItem, orderDetail, listWareHouse) => {
  const pickupAddress1 = [
    listWareHouse?.name, 
    listWareHouse?.addressLine1,
  ].filter(Boolean).join(", ");
  
  const pickupAddress2 = [
    listWareHouse?.addressLine2,
    listWareHouse?.city, 
    listWareHouse?.state, 
    listWareHouse?.country, 
     - listWareHouse?.zipCode
  ].filter(Boolean).join(", ");
  
  if (item?.status === "Received")
    return "<b>Confirmed </b><br/><p> Your order has been confirmed</p>"
  else if (item?.status === "Processing")
    return "<b>Order Processing</b> <br/><p> Your order has been Processed</p>"
  else if (item?.status === "Packing" && orderDetail?.deliveryInfo?.deliveryType === "Doorstep")
    return "<b>Packing </b><br/><p> Your items are packed and ready for shipping</p>"
   else if (item?.status === "Packing" && orderDetail?.deliveryInfo?.deliveryType === "Self Pickup")
    return `<b>Packing </b><br/><p> Your items are packed and ready for pickup</p><p>Pickup Address: ${pickupAddress1},${pickupAddress2}</p>`
  else if (item?.status === "OutForDelivery")
    return "<b>Out For Delivery</b> <br/><p> Your order is out for delivery and expected to be arrived on " + DateFormatShow(orderItem?.expectedDeliveryDate, false) + "<br/>" +
      GetOutForDeliveryDetails(orderItem) + "</p>"
  else if (item?.status === "Delivered")
    return "<b>Delivered</b> <br/><p> Your item has been delivered</p>"
  else if (item?.status === "Cancelled")
    return "<b>Cancelled </b><br/><p> Your items has been cancelled</p>"
  else if (item?.status === "Refunded")
    return "<b>Refunded </b><br/><p> Your money has been refunded</p>"
}

export const GetReturnStatus = (item, orderItem, orderDetail,logoUrl) => {
  if (item?.returnStatus === "Requested")
    return "<b>Requested </b><br/><p> Your return has been requested</p>"
  else if (item?.returnStatus === "Cancelled")
    return "<b>Cancelled</b> <br/><p> Return has been cancelled</p>"
  else if (item?.returnStatus === "Accepted")
    return "<b>Accepted </b><br/><p> Your return items has been accepted." + GetAcceptedDetails(orderItem, orderDetail) + "</p>"
  else if (item?.returnStatus === "Rejected")
    return "<b>Rejected</b> <br/><p> Your return items has been rejected.<br><b>Reason:</b> " + orderItem?.returnRefundActivity?.rejectReason + "</p>"
  else if (item?.returnStatus === "Shipped")
    return "<b>Return Item Picked</b> <br/><p>We have picked your return item.</p>"
  else if (item?.returnStatus === "Received")
    return "<b>Received </b><br/><p> Your items has been Received.<br/>Received On: " + DateFormatShow(orderItem?.returnRefundActivity?.receivedInfo?.receivedOn, false) + "<br/>Condition: " + orderItem?.returnRefundActivity?.receivedInfo?.receivedAs + "</p>"
  else if (item?.returnStatus === "RefundApproved")
    return "<b>Refund Approved </b><br/><p> Your refund has been approved.<br/> Refund Amount: " + logoUrl?.currency + " " + PriceFormat(orderItem?.returnRefundActivity?.refundInfo?.refundAmount) + "<br/>Expected Refund Date: " + DateFormatShow(orderItem?.returnRefundActivity?.refundInfo?.date, false) + "</p>"
  else if (item?.returnStatus === "RefundRejected")
    return "<b>Refund Rejected </b><br/><p> Your refund has been rejected<br/><b>Reason:</b> " + orderItem?.returnRefundActivity?.refundInfo?.rejectedReason + "</p>"
}

export const OrderTabStatus = (type, status, deliveryType) => {
  if (type === "order" && status === "Received")
    return <span style={{ color: "#E9C02B" }}>Order Confirmed</span>
  if (type === "order" && status === "Processing")
    return <span style={{ color: "#338FDC" }}>Order Processing</span>
  if (type === "order" && status === "Packing" && deliveryType === "Doorstep")
    return <span style={{ color: "#DC339E" }}>Ready For Shipping</span>
  if (type === "order" && status === "Packing" && deliveryType === "Self Pickup")
    return <span style={{ color: "#DC339E" }}>Ready For Pickup</span>
  if (type === "order" && status === "OutForDelivery")
    return <span style={{ color: "#F58543" }}>Out For Delivery</span>
  if (type === "order" && status === "Delivered")
    return <span style={{ color: "#32B08C" }}>Delivered</span>
  if (type === "order" && status === "Cancelled")
    return <span style={{ color: "#E44961" }}>Order Cancelled</span>
  if (type === "order" && status === "Refunded")
    return <span style={{ color: "#65BCDB" }}>Refunded</span>
  if (type === "return" && status === "Requested")
    return <span style={{ color: "#32B08C" }}>Return Requested</span>
  if (type === "return" && status === "Cancelled")
    return <span style={{ color: "#E44961" }}>Return Cancelled</span>
  if (type === "return" && status === "Accepted")
    return <span style={{ color: "#32B08C" }}>Return Accepted</span>
  if (type === "return" && status === "Rejected")
    return <span style={{ color: "#E44961" }}>Return Rejected</span>
  if (type === "return" && status === "Shipped")
    return <span style={{ color: "#B533DC" }}>Return Item Picked</span>
  if (type === "return" && status === "Received")
    return <span style={{ color: "#32B08C" }}>Return Item Received</span>
  if (type === "return" && status === "RefundApproved")
    return <span style={{ color: "#65BCDB" }}>Refund Approved</span>
  if (type === "return" && status === "RefundRejected")
    return <span style={{ color: "#E44961" }}>Refund Rejected</span>
}

export const PromoCode = async (cartData, setResponseData, isLoggedin) => {
  GetData(resourceconstant?.PROMO_CODE_LIST, isLoggedin, setResponseData, cartData);
};

export const TopDealsProducts = async (cartData, setResponseData, isLoggedin) => {
  CreateEntry(resourceconstant?.TOP_DEALS, isLoggedin, setResponseData, cartData);
};

export const CategoryWiseProducts = async (cartData, setResponseData, isLoggedin) => {
  CreateEntry(resourceconstant?.CATEGORY_WISE, isLoggedin, setResponseData, cartData);
};

export const updateProductDetails = async (productDetailData, setResponseData) => {
  PostData(resourceconstant?.UPDATE_PRODUCT_ITEM, productDetailData, setResponseData);
}

export const ListingContents = async (productDetailData, setResponseData) => {
  GetData(resourceconstant?.POLICY_CONTENTS, productDetailData, setResponseData);
}

export const ListingFaqs = async (productDetailData, setResponseData) => {
  GetData(resourceconstant?.FAQS_CONTENTS, productDetailData, setResponseData);
}

export const OrderConfirmation = async (detail, setUpdateData, setDeleteResponse) => {
  const currentUser = await Auth.currentAuthenticatedUser();
  const userid = currentUser.attributes.sub;
  const buyData = localStorage.getItem('buyNow')
  let data = {
    "userid": `${userid}`,
    "orderid": detail?.orderid,
    "paymentStatus": detail?.paymentStatus,
    "paymentDetails": {
      "paymentMethod": detail?.paymentMethod,
      "paymentDate": detail?.paymentDate,
      "referenceNumber": detail?.referenceNumber,
      "receivedAmount": detail?.receivedAmount,
    }
  };
  try {
    await CreateEntry(resourceconstant?.UPDATE_ORDER, true, setUpdateData, data)
    if (!buyData) {
      HandleCartDelete(setDeleteResponse)
    }
  } catch (err) {
    customLogger.error(err);
  }
}

const HandleCartDelete = async (setDeleteResponse) => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    const userID = currentUser.attributes.sub;
    let data = {
      userId: userID
    };
    await DeleteEntry(resourceconstant.PRODUCT_ADD_TO_CART_DELETE, true, data, setDeleteResponse);

  } catch (error) {
    customLogger.error(error);
  }
};
export const ListingDeliveryFee = async (productDetailData, setResponseData) => {
  GetData(resourceconstant?.DELIVERY_FEE, productDetailData, setResponseData);
}

export const ListingPaymentMethod = async (productDetailData, setResponseData) => {
  GetData(resourceconstant?.PAYMENT_MODE, productDetailData, setResponseData);
}

export const ListingCarousalImages = async (productDetailData, setResponseData) => {
  GetData(resourceconstant?.CAROUSAL_IMAGES, productDetailData, setResponseData);
}
export const Productfilters = async (cartData, isLoggedin, setResponseData) => {
  GetList(resourceconstant?.PRODUCTS_FILTERS, cartData, isLoggedin, setResponseData);
};

export const NewMailVerifyOtp = async (cartData, setResponseData, isLoggedin) => {
  CreateEntry(resourceconstant?.PROFILE_NEW_MAIL, isLoggedin, setResponseData, cartData);
};

export const Deleteuser = async (cartData, isLoggedin, setResponseData) => {
  CreateEntry(
    resourceconstant?.USER_DELETE,
    isLoggedin,
    cartData,
    setResponseData
  );
};

export const ProductfiltersList = async (cartData, isLoggedin, setResponseData) => {
  GetList(resourceconstant?.FILTER_LIST, cartData, isLoggedin, setResponseData);
};

export const CreateUser = async (productReviewData, setResponseData) => {
  PostData(resourceconstant?.CREATE_USER, productReviewData, setResponseData)
};

export const ForgetPassword = async (cartData, setResponseData, isLoggedin) => {
  CreateEntry(resourceconstant?.FORGET_PASSWORD, isLoggedin, setResponseData, cartData);
};
export const ListWarehouses = async (productDetailData, setResponseData) => {
  GetData(resourceconstant?.LIST_WAREHOUSES, productDetailData, setResponseData);
}