import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Footer from "../../../pages/footer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FieldNameConstant, ValidateName } from "../../../constants/validateMsgConstant";
import ValidateField from "../../../utils/useValid";
import { CreateEntry } from "../../../providers/queryData";
import { showErrorToastr, showSuccessToastr } from "../../../common/toastrService";
import { resourceconstant } from "../../../constants";
import customLogger from "../../../common/loggerService";
import useProfile from "../../../hooks/useProfile";
import { ToastrMessages } from "../../../constants/toastrMessages";
import useLogoImage from "../../../hooks/useLogoImage";
const formValues = {
  emailOtp: '',
}
const EmailOtp = ({ setEmailNewChange, setEmailChange, emailNewOtpChange, emailNewChange, emailOtpChange, setEmailOtpChange, setEmailNewOtpChange }) => {

  const [values, setValues] = useState(formValues)
  const [errors, setErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [sendEmailOtp, setSendEmailOtp] = useState('')
  const navigate = useNavigate()
  const [userProfile, setUserProfile] = useProfile()
  const userid = userProfile?.attributes?.sub;
  const oldemail = localStorage.getItem('profileOldEmail')
  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTime = localStorage.getItem('timeLeft');
    return savedTime ? parseInt(savedTime, 10) : 30;
  });  
  const initialTime = 30;
  const [resendEmail, setResendEmail] = useState([])
  const [logoUrl, setLogoUrl] = useLogoImage();
  
  useEffect(() => {
    localStorage.setItem('timeLeft', timeLeft);

    if (timeLeft > 0) {
      const timerId = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearTimeout(timerId);
    }
  }, [timeLeft]);
  
    const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes.toString().padStart(2, '0')}.${seconds.toString().padStart(2, '0')}`;
    };

  const HandleChangeOtp = (e) => {
    const { name, value } = e.target

    setErrors({
      ...errors,
      [name]: ""
    });
    setValues({
      ...values,
      [name]: value,
    });
  }

  const HandleBlur = (e) => {
    const { name, value } = e.target;
    const fieldErrors = validation({
      ...values,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: fieldErrors[name]
    });
  };

  const validation = (values) => {
    const errors = {};
    errors.emailOtp = ValidateField(
      values.emailOtp,
      FieldNameConstant?.OTP,
      [ValidateName?.REQUIRED, ValidateName?.OTPCODE],
    );

    let error = {}
    Object.keys(errors).map((key, index) => {
      if (errors[key] !== undefined)
        error[key] = errors[key]
    })
    return error
  }

  const PhoneOtp = (e) => {
    e.preventDefault();
    setErrors(validation(values));
    setIsSubmit(true);
  }

  useEffect(() => {
    const HandleEmailOtp = async () => {
      try {
        const data = {
          user_id: userid,
          old_email: oldemail,
          otp: values?.emailOtp
        };

        await CreateEntry(resourceconstant?.PROFILE_MAIL_OTP, true, setSendEmailOtp, data)
      } catch (error) {
        if (error.response) {
          customLogger.error('Response:', error.response.data);
        }
      }
    };
    if (Object.keys(errors).length === 0 && isSubmit) {
      HandleEmailOtp();
    }
  }, [errors])

  const HandleResendEmailOtp = async () => {
    try {
      const data = {
        user_id: userid,
        old_email: oldemail
      };

      await CreateEntry(resourceconstant?.PROFILE_MAIL_OTP, true, setResendEmail, data)
    } catch (error) {
      if (error.response) {
        customLogger.error(error.response.data);
      }
    }
  };

  useEffect(() => {
    if (sendEmailOtp?.status === true) {
      showSuccessToastr(sendEmailOtp?.message)
      navigate('/new-email')
    }
    else if(sendEmailOtp?.status === false) {
      showErrorToastr(sendEmailOtp?.message);
    }
  }, [sendEmailOtp])

  useEffect(() => {
    if (resendEmail?.status === true) {
      showSuccessToastr(ToastrMessages?.OTPMESSAGE)
      setTimeLeft(initialTime);
    }
  }, [resendEmail])

  return (
    <>
      <div className="login-page otp profile-email-otp">
        <Card>
          <Row>
            <Col md={12}>
              <div className="login-sec">
                <div className="logo-sec">
                  <Link to="/create-account" style={{ color: "inherit" }}>
                  </Link>
                </div>
                <div className="login-form" style={{ maxWidth: "650px" }}>
                  <form>
                    <Row>
                      <Col md={12}>
                        <Link to="/change-email" style={{ color: "inherit" }} className="text-decoration-none">
                          <div class="back-btn">
                            <i className="fa fa-chevron-left"></i>
                          </div>
                        </Link>
                        <h1>Verify Your Identity</h1>
                      </Col>
                      <Col sm={12} md={12}>
                        <div class="form-group">
                          <p>
                            Please enter the One-Time Password (OTP) sent to {oldemail}.This helps us ensure the security of your account
                          </p>
                        </div>
                      </Col>
                      <Col sm={12} md={12}>
                        <div class="form-group">
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter"
                            name="emailOtp"
                            id="emailOtp"
                            onChange={(e) => HandleChangeOtp(e)}
                            onBlur={(e) => HandleBlur(e)} />
                          {errors?.emailOtp && (
                            <p className="form-error">{errors?.emailOtp}</p>
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <p className="register-link mt-2 text-start" onClick={(e) => {
                          if (timeLeft === 0) {
                            HandleResendEmailOtp(e);
                          }
                        }}>
                          <a style={{
                            color: timeLeft > 0 ? "#B0B0B0" : "#045EC6",
                            cursor: timeLeft > 0 ? "not-allowed" : "pointer",
                            pointerEvents: timeLeft > 0 ? "none" : "auto"
                          }}
                          >
                            Resend OTP
                          </a>
                        </p>
                      </Col>
                      <Col md={6}>
                        <p className="text-end mt-2">
                          {formatTime(timeLeft)}
                        </p>
                      </Col>
                      <Col md={12}>
                        <button className="btn submit-btn" type="submit" onClick={(e) => PhoneOtp(e)}>
                          Verify Code
                        </button>
                      </Col>
                      <Col md={12}>
                        <p className="text-center">
                          By continuing, you agree to {logoUrl?.brandName}’s{" "}
                          <Link
                            to="/terms-condition"
                            state={{ activeKey: "terms" }}
                            onClick={() => {
                              window.scroll(0, 0);
                            }}>
                            Conditions of Use
                          </Link>{" "}
                          and{" "}
                          <Link
                            to="/privacy-policy"
                            state={{ activeKey: "privacy" }}
                            onClick={() => {
                              window.scroll(0, 0);
                            }}>
                            Privacy Policy
                          </Link>
                          .
                        </p>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
export default EmailOtp;
