import {
  FieldNameConstant,
  ValidateMessages,
  ValidateName,
} from "../../../constants/validateMsgConstant";
import validateField from "../../../utils/useValid";

export const contactValidation = (values, userProfile, captchaVerified, validNo) => {
  const errors = {};
  errors.userName = validateField(values.userName, FieldNameConstant?.NAME, [
    ValidateName?.REQUIRED,
    ValidateName?.ISTEXT_ONLY,
  ]);

  errors.email = validateField(values.email, FieldNameConstant?.EMAIL, [
    ValidateName?.REQUIRED,
    ValidateName?.ISVALID_EMAIL,
  ]);
  
  if(validNo){
    errors.phoneNumber = validateField(
      values.phoneNumber,
      FieldNameConstant?.PHONE_NUMBER,
      [ValidateName?.ISVALID_PHONE]
    );
  }

  errors.companyName = validateField(
    values?.companyName,
    FieldNameConstant?.COMPANY_NAME
  );
  errors.subject = validateField(values?.subject, FieldNameConstant?.SUBJECT, [
    ValidateName?.REQUIRED,
  ]);

  errors.message = validateField(values?.message, FieldNameConstant?.MESSAGE, [
    ValidateName?.REQUIRED,
  ]);

  const isUserProfileEmpty =
    !Array.isArray(userProfile) ||
    !userProfile.some((obj) => obj !== null && Object.keys(obj).length > 0);

  if (isUserProfileEmpty) {
    if (!values.captcha) {
      errors.captcha = validateField(values?.captcha, FieldNameConstant?.CAPTCHA, [
        ValidateName?.REQUIRED,
      ]);
    }
    else if (values.captcha !== captchaVerified) {
      errors.captcha = ValidateMessages?.NOT_MATCHED_CAPTCHA;

    }
  }

  let error = {};
  Object.keys(errors).map((key, index) => {
    if (errors[key] !== undefined) error[key] = errors[key];
  });
  return error;
};
