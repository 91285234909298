import React, { useState } from "react";
  
export const LogoContext = React.createContext({});
export const LogoContextProvider = ({ children }) => {
    const [ logoUrl, setLogoUrl ] = useState([]);
  
    return (
        <LogoContext.Provider value={{ logoUrl, setLogoUrl }}>
            {children}
        </LogoContext.Provider>
    );
};