import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import Logo from '../../assets/images/logo.png'
import Amplify, { Auth } from 'aws-amplify';
import Footer from "../../pages/footer";
import useRegisterForm from "./useRegisterForm";
import customLogger from "../../common/loggerService";
import { useNavigate } from 'react-router-dom';
import { showErrorToastr, showSuccessToastr } from "../../common/toastrService";
import { commonImages, resourceconstant } from "../../constants";
import { Link } from "react-router-dom";
import { ValidateName } from "../../constants/validateMsgConstant";
import { FieldNameConstant } from "../../constants/validateMsgConstant";
import ValidateField from "../../utils/useValid";
import useLogoImage from "../../hooks/useLogoImage";
import { ToastrMessages } from "../../constants/toastrMessages";
import { CreateEntry } from "../../providers/queryData";
const formValues = {
    otpVerify: '',
}
const ResetOtp = () => {
    const [values, setValues] = useState(formValues)
    const [errors, setErrors] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const navigate = useNavigate()
    const [logoUrl, setLogoUrl] = useLogoImage();
    const [isDisabled, setIsDisabled] = useState(false);
    const [timeLeftPassword, setTimeLeftPassword] = useState(() => {
        const savedTime = localStorage.getItem('timeLeftPassword');
        return savedTime ? parseInt(savedTime, 10) : 120;
      });  
      const initialTimePassword = 120;
    const [resentForgetEmail, setResentForgetEmail] = useState(null) 
    const [confirmOtp, setConfirmOtp] = useState(null) 

      useEffect(() => {
        localStorage.setItem('timeLeftPassword', timeLeftPassword);
    
        if (timeLeftPassword > 0) {
          const timerId = setTimeout(() => {
            setTimeLeftPassword(timeLeftPassword - 1);
          }, 1000);
    
          return () => clearTimeout(timerId);
        }
      }, [timeLeftPassword]);

      const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}.${seconds.toString().padStart(2, '0')}`;
      };

    const logginedemil = localStorage.getItem('forgetEmail');
    const HandleChangeOtp = (e) => {
        const { name, value } = e.target

        setErrors({
            ...errors,
            [name]: ""
        });
        setValues({
            ...values,
            [name]: value,
        });
    }

    const HandleBlur = (e) => {
        const { name, value } = e.target;
        const fieldErrors = validation({
            ...values,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: fieldErrors[name]
        });
    };

    const validation = (values) => {
        const errors = {};
        errors.otpVerify = ValidateField(
            values.otpVerify,
            FieldNameConstant?.OTP,
            [ValidateName?.REQUIRED, ValidateName?.OTPCODE],
        );

        let error = {}
        Object.keys(errors).map((key, index) => {
            if (errors[key] !== undefined)
                error[key] = errors[key]
        })
        return error
    }

    const confirmSignUpWithOTP = (e) => {
        e.preventDefault();
        setErrors(validation(values));
        setIsSubmit(true);
    }

    useEffect(() => {
        const confirmSignUpWithOTP = async (e) => {
            localStorage.setItem("code", values.otpVerify)
            if(timeLeftPassword === 0){
                showErrorToastr(ToastrMessages?.OTPERRORMSG)
            }
            else{
            const data = {
                    email: logginedemil,
                    otp: values.otpVerify
            };

            await CreateEntry(resourceconstant?.FORGET_PASSWORD, false, setConfirmOtp, data)
            }
        };
        if (Object.keys(errors).length === 0 && isSubmit) {
            confirmSignUpWithOTP();
        }
    }, [errors])

    useEffect(() => {
        if (confirmOtp?.status === true) {
            showSuccessToastr(confirmOtp?.message)
            navigate('/reset-password')
        }
        else if(confirmOtp?.status === false) {
          showErrorToastr(confirmOtp?.message);
        }
      }, [confirmOtp])
    
    const resendOTP = async () => {
        try {
            const data = {
                email: logginedemil
            };

            await CreateEntry(resourceconstant?.FORGET_PASSWORD, false, setResentForgetEmail, data)
          } catch (error) {
            customLogger.error(error)
        }
    };

    useEffect(() => {
        if (resentForgetEmail?.status === true) {
            showSuccessToastr(ToastrMessages?.OTPMESSAGE)
            setTimeLeftPassword(initialTimePassword);      
        }
        else if(resentForgetEmail?.status === false) {
          showErrorToastr(resentForgetEmail?.message);
        }
      }, [resentForgetEmail])

    return (
        <>
            <div className="login-page otp">
                <Card>
                    <Row>
                        <Col md={12}>
                            <div className="login-sec">
                                <div className="logo-sec">
                                    <Link to="/forgot-password" style={{ color: "inherit" }} className="text-decoration-none">
                                        <div class="back-btn" >
                                            <i className="fa fa-chevron-left"></i>
                                        </div>
                                    </Link>
                                </div>
                                <div className="login-form">
                                    <form onSubmit={e => confirmSignUpWithOTP(e)}>
                                        <Row>
                                            <Col md={12}>
                                                <h1>Verify Your Identity</h1>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div class="form-group">
                                                    <p>Please enter the One-Time Password (OTP) sent to {logginedemil}.This helps us ensure the security of your account <Link to="/forgot-password"></Link></p>
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div class="form-group">
                                                    <label>Enter OTP</label>
                                                    <input class="form-control"
                                                        type="text"
                                                        placeholder="Enter"
                                                        name='otpVerify'
                                                        id="otpVerify"
                                                        onChange={(e) => HandleChangeOtp(e)}
                                                        onBlur={(e) => HandleBlur(e)} />
                                                    {
                                                        errors?.otpVerify && <p className="form-error">{errors?.otpVerify}</p>
                                                    }
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <p className="register-link mt-2 float-start" onClick={(e) => {
                                                if (timeLeftPassword === 0) {
                                                    resendOTP(e);
                                                }
                                                }}><a style={{
                                                    color: timeLeftPassword > 0 ? "#B0B0B0" : "#045EC6",
                                                    cursor: timeLeftPassword > 0 ? "not-allowed" : "pointer",
                                                    pointerEvents: timeLeftPassword > 0 ? "none" : "auto"
                                                }}> Resend OTP</a></p>
                                            </Col>
                                            <Col md={6}>
                                                <p className="text-end mt-2">
                                                {formatTime(timeLeftPassword)}
                                                </p>
                                            </Col>
                                            <Col md={12}>
                                                <button className="btn submit-btn" type="submit">Verify Code</button>
                                            </Col>
                                            <Col md={12}>
                                                <p className="text-center">By continuing, you agree to {logoUrl?.brandName}’s<Link to="/terms-condition">Conditions of Use</Link>and<Link to='/privacy-policy' onClick={() => { window.scroll(0, 0); }}>Privacy Policy.</Link></p>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
            <div className="footer-sec">
                <div className="footer-wrapper">
                    <Footer />
                </div>
            </div>
        </>
    )
};
export default ResetOtp