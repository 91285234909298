import React, { useEffect } from 'react';
import Lottie from "react-lottie";
import animationData from './Animation - 1715597853514.json';

const NotFound = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData
    };

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <a href='/'>
                        <Lottie options={defaultOptions} width={"auto"} height={"auto"} />
                    </a>
                </div>
            </div>
        </div>

    )
}

export default NotFound