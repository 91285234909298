import React, { useState } from "react";
  
export const ChangeLocationContext = React.createContext({});
export const ChangeLocationContextProvider = ({ children }) => {
    const [changeLocation, setChangeLocation] = useState([]);
  
    return (
        <ChangeLocationContext.Provider value={{ changeLocation, setChangeLocation }}>
            {children}
        </ChangeLocationContext.Provider>
    );
};