import React, { useEffect, useState, useRef } from "react";
import { GetOrderList } from "../../utils/generalMethod"
import { PaymentReturnUrlApp, PaymentAppCredential } from "../../constants"
import { validateAndRefreshToken } from "../../providers/queryData"
import { useLocation, useNavigate } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import { showErrorToastr } from "../../common/toastrService";
import useLogoImage from "../../hooks/useLogoImage";
const QPayPaymentApp = () => {
    const location = useLocation();
    const [secretHash, setSecretHash] = useState(null);
    const [responseData, setResponseData] = useState(null)
    const [orderDetail, setOrderDetail] = useState(null)
    const [currentUser, setCurrentUser] = useState(null);
    const formRef = useRef(null);
    const myParam = new URLSearchParams(location.search);
    const [logoUrl, setLogoUrl] = useLogoImage();
    const current = new URL(window.location.href);
    const pathSegments = current?.origin

    useEffect(() => {
        const GetOrders = async () => {
            if (myParam?.get('orderId')) {
                try {
                    const response = await Auth.signIn(PaymentAppCredential?.USEREMAIL, PaymentAppCredential?.PASSWORD);
                    const currentUser = await Auth.currentAuthenticatedUser();
                    setCurrentUser(currentUser)
                    localStorage.setItem("orderId", myParam.get("orderId"))
                } catch (e) {
                    console.error(e)
                }
            }
        }
        GetOrders()
    }, [myParam?.get('orderId')])


    useEffect(() => {
        if (currentUser?.attributes?.sub) {
            const orderID = localStorage.getItem('orderId')
            GetOrdersDetails(orderID)
        }
    }, [currentUser?.attributes])

    const GetOrdersDetails = (orderID) => {
        let filter = {
            id: { eq: `${orderID}` },
        };
        GetOrderList(filter, setResponseData);
    };

    useEffect(() => {
        if (responseData?.severity === "success") {
            if (responseData?.data?.data?.listOrders?.items?.length <= 0) {
                showErrorToastr("This order is not available")
                return;
            }
            setOrderDetail(responseData?.data?.data?.listOrders?.items?.[0]);
            CreateHashCode(responseData?.data?.data?.listOrders?.items?.[0]);
        }
        if (responseData?.severity === "error") {
            showErrorToastr("Invalid OrderId")
        }
    }, [responseData]);

    const CreateHashCode = async (orders) => {
        const data = {
            referenceId: orders?.id,
            amount: parseFloat(orders?.totalAmount).toFixed(2),
        };
        //const token = localStorage.getItem("IdToken");
        const token = await validateAndRefreshToken();
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        };
        if (data) {
            requestOptions.body = JSON.stringify(data);
        }
        requestOptions.headers.Authorization = token;
        fetch(process.env.REACT_APP_GENERATE_HASHCODE, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setSecretHash(data?.secure_hash)
            })
            .catch((error) => {
                console.error("error.message", error.message)
            });
    }

    useEffect(() => {
        if (secretHash) {
            formRef.current.submit();
        }
    }, [secretHash]);

    return (<><div>
        <p class="redirecting-message">Please wait! Redirecting to payment...</p>
    </div>
        <form method="POST" ref={formRef} action="https://paymentapi.qib.com.qa/api/gateway/v2.0">
            <input name="action" hidden value="capture" />
            <input name="gatewayId" hidden value="017855659" />
            <input name="signatureFields" hidden value="gatewayId,amount,referenceId" />
            <input name="signature" hidden value={secretHash} />
            <input name="referenceId" hidden value={orderDetail?.id} />
            <input name="amount" hidden value={parseFloat(orderDetail?.totalAmount).toFixed(2)} />
            <input name="currency" hidden value="QAR" />
            <input name="mode" hidden value="LIVE" />
            <input name="description" hidden value="PRODUCT 1" />
            <input name="returnUrl" hidden value={`${pathSegments}${PaymentReturnUrlApp?.RETURN_URL}`} />
            <input name="name" hidden value={orderDetail?.userName} />
            <input name="country" hidden value="QA" />

            <input name="address" hidden value={orderDetail?.shippingAddress?.addressLine1 + "," + orderDetail?.shippingAddress?.addressLine2 + "," + orderDetail?.shippingAddress?.street} />
            <input name="city" hidden value={orderDetail?.shippingAddress?.city} />
            <input name="state" hidden value={orderDetail?.shippingAddress?.state} />
            <input name="phone" hidden value={orderDetail?.shippingUserDetails?.phoneNumber} />
            <input name="email" hidden value={orderDetail?.shippingUserDetails?.email} />
        </form>
    </>
    )
}

export default QPayPaymentApp