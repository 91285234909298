import React, { useState } from "react";
  
export const CountryContext = React.createContext({});
export const CountryContextProvider = ({ children }) => {
    const [countryList, setCountryList] = useState([]);
  
    return (
        <CountryContext.Provider value={{ countryList, setCountryList }}>
            {children}
        </CountryContext.Provider>
    );
};