
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CreateEntry } from "../../../providers/queryData";
import { resourceconstant } from "../../../constants";
import customLogger from "../../../common/loggerService";
import { showErrorToastr, showSuccessToastr } from "../../../common/toastrService";
import MyOtpInput from "../../../common/otpInput"
import useProfile from "../../../hooks/useProfile";
import useUserDetails from '../../../hooks/useUserDetails'
import { Auth } from 'aws-amplify'
import { ToastrMessages } from "../../../constants/toastrMessages";
import useLogoImage from "../../../hooks/useLogoImage";
const DeleteAccountOtp = ({ setPhoneOtp }) => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [useuserDetails, setUseUserDetails] = useUserDetails();
    const [userDetails, setUserDetails] = useProfile()
    const [deleteOtpResponse, setDeleteOtpResponse] = useState([])
    const [deleteOtpResend, setDeleteOtpResend] = useState([])
    const [timeLeft, setTimeLeft] = useState(() => {
        const savedTime = localStorage.getItem('timeLeftAccount');
        return savedTime ? parseInt(savedTime, 10) : 30;
      });  
    const initialTime = 30;
    const isOtpComplete = otp.replace(/\s/g, '').length === 6;
    const [logoUrl, setLogoUrl] = useLogoImage();

    useEffect(() => {
        localStorage.setItem('timeLeftAccount', timeLeft);
    
        if (timeLeft > 0) {
          const timerId = setTimeout(() => {
            setTimeLeft(timeLeft - 1);
          }, 1000);
    
          return () => clearTimeout(timerId);
        }
      }, [timeLeft]);
    
      const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}.${seconds.toString().padStart(2, '0')}`;
      };

    const DeleteAccountSuccess = async (e) => {
        e.preventDefault();
        try {
            const data = {
                user_id: useuserDetails?.id || userDetails?.attributes?.sub,
                email: useuserDetails?.email || userDetails?.attributes?.email,
                otp: otp
            };
    
            await CreateEntry(resourceconstant?.USER_DELETE, true, setDeleteOtpResponse, data)
           
          } catch (error) {
            if (error.response) {
              customLogger.error(error.response.data);
            }
          }
    }

    const DeleteAccountResent = async (e) => {
        e.preventDefault();
        try {
            const data = {
                user_id: useuserDetails?.id || userDetails?.attributes?.sub,
                email: useuserDetails?.email || userDetails?.attributes?.email
            };

            await CreateEntry(resourceconstant?.USER_DELETE, true, setDeleteOtpResend, data)
          } catch (error) {
            if (error.response) {
              customLogger.error(error.response.data);
            }
          }
    }

    useEffect(() => {
        const handleResponse = async () => {
            if (deleteOtpResponse?.status === true) {
              showSuccessToastr(deleteOtpResponse?.message);
      
              try {
                navigate('/delete-account-success');
                await Auth.signOut();
                localStorage.clear()
                window.location.reload();
              } catch (error) {
                customLogger.error(error)
              }
            } else if (deleteOtpResponse?.status === false) {
              showErrorToastr(deleteOtpResponse?.message);
            }
          };
      
          handleResponse();
      }, [deleteOtpResponse])

      useEffect(() => {
        if (deleteOtpResend?.status === true) {
          showSuccessToastr(ToastrMessages?.OTPMESSAGE)
          setTimeLeft(initialTime);
        }
      }, [deleteOtpResend])

    return (
        <>
            <div className="login-page otp">
                <Card>
                    <Row>
                        <Col md={12}>
                            <div className="login-sec">
                                <div className="logo-sec">
                                    <Link to="/delete-account" style={{ color: "inherit" }}>
                                        <div class="back-btn">
                                            <i className="fa fa-chevron-left"></i>
                                        </div>
                                    </Link>
                                </div>
                                <div className="login-form" style={{ maxWidth: "650px" }}>
                                    <form>
                                        <Row>
                                            <Col md={12}>
                                                <h1>Delete Account</h1>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div class="form-group">
                                                    <p>
                                                        Deleting your account will permanently remove all your data, including order history, saved addresses, and payment methods. This action cannot be undone.
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <h1>Enter OTP to Complete Deletion</h1>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div class="form-group">
                                                    <p>
                                                        To delete account please enter the One-Time Password (OTP) sent to {useuserDetails?.email || userDetails?.attributes?.email}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div class="form-group">
                                                    <div class="otp-container">
                                                        <MyOtpInput setOtp={setOtp} otp={otp} />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <p className="register-link mt-2 text-start" onClick={(e) => {
                                                if (timeLeft === 0) {
                                                    DeleteAccountResent(e);
                                                }
                                                }}>
                                                    <a style={{
                                                        color: timeLeft > 0 ? "#B0B0B0" : "#045EC6",
                                                        cursor: timeLeft > 0 ? "not-allowed" : "pointer",
                                                        pointerEvents: timeLeft > 0 ? "none" : "auto"
                                                    }}
                                                    >
                                                        Resend OTP
                                                    </a>
                                                </p>
                                            </Col>
                                            <Col md={6}>
                                                <p className="text-end mt-2">
                                                {formatTime(timeLeft)}
                                                </p>
                                            </Col>
                                            <Col md={12}>
                                                <button className="btn submit-btn" type="submit" onClick={(e) => DeleteAccountSuccess(e)} disabled={!isOtpComplete}>
                                                    Confirm
                                                </button>
                                            </Col>
                                            <Col md={12}>
                                                <p className="text-center">
                                                    By continuing, you agree to {logoUrl?.brandName}’s{" "}
                                                    <Link
                                                        to="/terms-condition"
                                                        state={{ activeKey: "terms" }}
                                                        onClick={() => {
                                                            window.scroll(0, 0);
                                                        }}
                                                    >
                                                        Conditions of Use
                                                    </Link>{" "}
                                                    and{" "}
                                                    <Link
                                                        to="/privacy-policy"
                                                        state={{ activeKey: "privacy" }}
                                                        onClick={() => {
                                                            window.scroll(0, 0);
                                                        }}
                                                    >
                                                        Privacy Policy
                                                    </Link>
                                                    .
                                                </p>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
    );
};
export default DeleteAccountOtp;
