import React from "react";
import { Accordion } from "react-bootstrap";
import FilterSpinner from "./filterSpinner";

const ProductNewFilter = ({
    page,
    mainCategoryId,
    subCategoryId,
    selectedMainCategories,
    selectedSubCategories,
    selectedBrands,
    selectedRatings,
    HandleMainCategoryChange,
    HandleSubCategoryChange,
    searchQuery,
    HandleSearchChange,
    HandleClearAll,
    HandleBrandChange,
    HandleRatingChange,
    ApplyFilter,
    getUniqueObjects,
    productFilterData
}) => {
    return (
        <Accordion>
            {page === "allProducts" && <Accordion.Item eventKey={1} key={1}>
                <Accordion.Header onClick={(e) => e.stopPropagation()}>
                    Main Category{" "}
                </Accordion.Header>
                <Accordion.Body>
                    <ul>
                        {getUniqueObjects(productFilterData, "mainCategoryID")?.map((item, index) => {
                            const isChecked = mainCategoryId === item.id || selectedMainCategories && selectedMainCategories?.some(category => category.id === item.mainCategoryID);
                            return (
                                <li key={index} onClick={(e) => { e.stopPropagation(); HandleMainCategoryChange(e, item.mainCategoryID, item.mainCategory) }}>
                                    <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={(e) => HandleMainCategoryChange(e, item.mainCategoryID, item.mainCategory)}
                                    />
                                    <label>{item.mainCategory}</label>
                                </li>
                            );
                        })}
                    </ul>
                </Accordion.Body>
            </Accordion.Item>}
            {(page === "allProducts" || page === "mainCategory") && <Accordion.Item eventKey={2} key={2}>
                <Accordion.Header onClick={(e) => e.stopPropagation()}>
                    Sub Category{" "}
                </Accordion.Header>
                <Accordion.Body>
                    <ul>
                        {getUniqueObjects(productFilterData, "subCategoryID")?.map((item, index) => {
                            const isChecked = subCategoryId === item.id || selectedSubCategories && selectedSubCategories?.some(category => category.id === item.subCategoryID);
                            return (
                                <li key={index} onClick={(e) => { e.stopPropagation(); HandleSubCategoryChange(item.subCategoryID, item?.subCategory) }}>
                                    <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={() => HandleSubCategoryChange(item.subCategoryID, item?.subCategory)}
                                    />
                                    <label>{item.subCategory}</label>
                                </li>
                            );
                        })}
                    </ul>
                </Accordion.Body>
            </Accordion.Item>}
            <Accordion.Item eventKey={3} key={3}>
                <Accordion.Header onClick={(e) => e.stopPropagation()}>
                    Brand{" "}
                </Accordion.Header>
                <Accordion.Body>
                    <div className="input-group">
                        <span className="input-group-text">
                            <i className="fa fa-search"></i>
                        </span>
                        <input
                            type="Search"
                            placeholder="Search"
                            aria-label="Input group"
                            aria-describedby="btnGroupAddon"
                            value={searchQuery}
                            onClick={(e) => e.stopPropagation()}
                            onChange={HandleSearchChange}
                        />
                    </div>
                    <ul>
                        {getUniqueObjects(productFilterData, "brandID")
                            ?.filter((brand) =>
                                brand.brandName
                                    ?.toLowerCase()
                                    .includes(searchQuery?.toLowerCase())
                            )
                            .map((brand, index) => {
                                const isChecked = selectedBrands && selectedBrands?.some(category => category.id === brand.brandID);

                                return (
                                    <li key={index} onClick={(e) => { e.stopPropagation(); HandleBrandChange(brand.brandID, brand?.brandName) }}>
                                        <input
                                            type="checkbox"
                                            checked={isChecked}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={() => HandleBrandChange(brand.brandID, brand?.brandName)}
                                        />
                                        <label>{brand.brandName}</label>
                                    </li>
                                );
                            })}
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey={4} key={4}>
                <Accordion.Header onClick={(e) => e.stopPropagation()}>
                    Rating
                </Accordion.Header>
                <Accordion.Body>
                    <ul>
                        {getUniqueObjects(productFilterData, "overallRating")?.map((rating, index) => (
                            <li key={index} onClick={(e) => { e.stopPropagation(); HandleRatingChange(parseInt(rating?.overallRating)) }}>
                                <input
                                    type="checkbox"
                                    onClick={(e) => e.stopPropagation()}
                                    checked={selectedRatings?.includes(parseInt(rating?.overallRating))}
                                    onChange={() => HandleRatingChange(parseInt(rating?.overallRating))}
                                />
                                <label>
                                    {rating?.overallRating} <i className="fa fa-star"></i>
                                </label>
                            </li>
                        ))}
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            <div className="d-flex">
                <button className="btn cancel-btn" onClick={HandleClearAll}>Clear All</button>
                <button className="btn shop-btn" onClick={ApplyFilter}>Apply</button>
            </div>
        </Accordion>
    );

};

export default ProductNewFilter;
