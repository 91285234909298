import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import Logo from '../../assets/images/logo.png'
import Amplify, { Auth } from 'aws-amplify';
import Footer from "../../pages/footer";
import useRegisterForm from "./useRegisterForm";
import customLogger from "../../common/loggerService";
import { useNavigate } from 'react-router-dom';
import { showErrorToastr, showSuccessToastr } from "../../common/toastrService";
import { commonImages } from "../../constants";
import { Link } from "react-router-dom";
import { ValidateName } from "../../constants/validateMsgConstant";
import { FieldNameConstant } from "../../constants/validateMsgConstant";
import ValidateField from "../../utils/useValid";
import useLogoImage from "../../hooks/useLogoImage";
const formValues = {
    otpVerify: '',
}
const OtpVerification = () => {
    const [values, setValues] = useState(formValues)
    const [errors, setErrors] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const navigate = useNavigate()
    const [logoUrl, setLogoUrl] = useLogoImage();
    const logginedemil = localStorage.getItem('username');
    const HandleChangeOtp = (e) => {
        const { name, value } = e.target

        setErrors({
            ...errors,
            [name]: ""
        });
        setValues({
            ...values,
            [name]: value,
        });
    }

    const HandleBlur = (e) => {
        const { name, value } = e.target;
        const fieldErrors = validation({
            ...values,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: fieldErrors[name]
        });
    };

    const validation = (values) => {
        const errors = {};
        errors.otpVerify = ValidateField(
            values.otpVerify,
            FieldNameConstant?.OTP,
            [ValidateName?.REQUIRED, ValidateName?.OTPCODE],
        );

        let error = {}
        Object.keys(errors).map((key, index) => {
            if (errors[key] !== undefined)
                error[key] = errors[key]
        })
        return error
    }

    const confirmSignUpWithOTP = (e) => {
        e.preventDefault();
        setErrors(validation(values));
        setIsSubmit(true);
    }

    useEffect(() => {
        const confirmSignUpWithOTP = async (e) => {
            try {
                await Auth.confirmSignUp(logginedemil, values.otpVerify);
                showSuccessToastr("Your email has been verified successfully. Welcome on board!")
                navigate('/login');
            } catch (error) {
                customLogger.error(error);
                showErrorToastr(error.message)
            }
        };
        if (Object.keys(errors).length === 0 && isSubmit) {
            confirmSignUpWithOTP();
        }
    }, [errors])

    const resendOTP = async () => {
        try {
            await Auth.resendSignUp(logginedemil);
            showSuccessToastr("A new OTP has been sent to your email. Please check your inbox.")
        } catch (error) {
            customLogger.error(error);
            throw error;
        }
    };

    return (
        <>
            <div className="login-page otp">
                <Card>
                    <Row>
                        <Col md={12}>
                            <div className="login-sec">
                                <div className="logo-sec">
                                    <Link to="/create-account" style={{ color: "inherit" }}>
                                    </Link>
                                </div>
                                <div className="login-form">
                                    <form onSubmit={e => confirmSignUpWithOTP(e)}>
                                        <Row>
                                            <Col md={12}>
                                                <h1>Verification Code Required</h1>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div class="form-group">
                                                    <label className="fw-bold mb-2">{logginedemil}</label>
                                                    <p>We’ve sent a One Time Password (OTP) to the registered email address. Please enter it to complete Verification</p>
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div class="form-group">
                                                    <label>Enter OTP</label>
                                                    <input class="form-control" type="text" placeholder="Enter" name='otpVerify' id="otpVerify" onChange={(e) => HandleChangeOtp(e)} onBlur={(e) => HandleBlur(e)} />
                                                    {
                                                        errors?.otpVerify && <p className="form-error">{errors?.otpVerify}</p>
                                                    }
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <button className="btn submit-btn" type="submit">Verify Email</button>
                                            </Col>
                                            <Col md={12}>
                                                <p className="register-link mt-2" onClick={resendOTP}><a style={{ color: "#483335", cursor: "pointer", border: "1px solid #e8e6e6", padding: "5px 10px" }}>Resend OTP</a></p>

                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
            <div className="footer-sec">
                <div className="footer-wrapper">
                    <Footer />
                </div>
            </div>
        </>
    )
};
export default OtpVerification