import React, { useEffect, useState, useMemo } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
  Table,
} from "react-bootstrap";
import VisaCard from "../../assets/images/icons/visa.png";
import ProductItem from "./productItem";
import OrderConfirmSummery from "./orderConfirmSummery";
import { GetData } from "../../providers/queryData";
import { resourceconstant } from "../../constants";
import { useNavigate } from 'react-router-dom';
import useProfile from "../../hooks/useProfile";
import useCart from "../../hooks/useCartPage";
const OrderConfirm = () => {
  const [orderList, setOrderList] = useState([]);
  const [orderItemList, setOrderItemList] = useState([]);
  const orderID = localStorage.getItem("orderId");
  const [orderData, setOrderData] = useState([]);
  const [orderItemData, setOrderItemData] = useState([]);
  const [cartPageItem, setCartPageItem] = useCart()
  const navigate = useNavigate()
  const buyData = useMemo(() => {
    return localStorage.getItem('buyNow');
  }, []);

  const Orders = async () => {
    let filter = {
      id: {
        eq: `${orderID}`
      }
    };
    GetData(resourceconstant.LIST_ORDERS, filter, setOrderData);
  };

  useEffect(() => {
    if (orderData?.severity === "success") {
      setOrderList(orderData?.data?.data?.listOrders?.items);
    }
  }, [orderData]);

  const OrdersItems = async () => {
    let filter = {
      pk: {
        eq: `ORDERI#${orderID}`,
      },
    };
    GetData(resourceconstant.LIST_ORDER_ITEM, filter, setOrderItemData);
  };

  useEffect(() => {
    if (orderItemData?.severity === "success") {
      setOrderItemList(orderItemData?.data?.data?.listOrderItems?.items);
    }
  }, [orderItemData]);

  useEffect(() => {
    Orders();
    OrdersItems();
  }, []);

  useEffect(() => {
    if (!buyData) {
      localStorage.removeItem('cartData')
      setCartPageItem([])
    }
    else{
      localStorage.removeItem('buyNow')
    }
  }, [!buyData]);

  const isDifferentAddress = orderList.some(list => {
    const { shippingAddress, billingAddress } = list;
    return (
      shippingAddress.addressLine1 !== billingAddress.addressLine1 ||
      shippingAddress.addressLine2 !== billingAddress.addressLine2 ||
      shippingAddress.street !== billingAddress.street ||
      shippingAddress.city !== billingAddress.city ||
      shippingAddress.state !== billingAddress.state ||
      shippingAddress.country !== billingAddress.country ||
      shippingAddress.postalCode !== billingAddress.postalCode
    );
  });

  return (
    <section id="order-confirm" className="mt-3">
      <Container>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <div className="order-placed">
                  <h4 className="fw-medium">
                    Order number: <span>#{orderID}</span>
                  </h4>
                  <div className="me-2 d-flex gap-1 justify-content-center">
                    <i className="fa fa-check-circle-o"></i>
                    <h2>
                      Thank you for your order, your order has been placed
                      successfully
                    </h2>
                  </div>
                  <p>
                    Your order has been confirmed and you will receive order
                    and shipping updates via email
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col md={12}>
            <Card>
              <CardBody>
                <ProductItem
                  orderList={orderList}
                  orderItemList={orderItemList}
                />
                <Row className="order-shipment justify-content-between">
                  <>
                    <Col md={4} sm={12} className="my-1">
                      <Card className="order-summary-card">
                        <CardHeader>
                          <h2>Billing Addresss</h2>
                        </CardHeader>
                        <CardBody>
                          {orderList.map((list, index) => (
                            <div className="address" key={index}>
                              <p>
                                {list.billingUserDetails.userName}
                                <br />
                                {list.billingAddress.addressLine1 && (
                                  <>
                                    {list.billingAddress.addressLine1}
                                    <br />
                                  </>
                                )}
                                {list.billingAddress.addressLine2 && (
                                  <>
                                    {list.billingAddress.addressLine2}
                                    <br />
                                  </>
                                )}
                                {list.billingAddress.street && (
                                  <>
                                    {list.billingAddress.street}
                                    <br />
                                  </>
                                )}
                                {list.billingAddress.city && (
                                  <>
                                    {list.billingAddress.city},
                                    <br />
                                  </>
                                )}
                                {list.billingAddress.state && (
                                  <>
                                    {list.billingAddress.state}
                                    <br />
                                  </>
                                )}
                                {list.billingAddress.country && (
                                  <>
                                    {list.billingAddress.country}
                                  </>
                                )}
                                {list.billingAddress.postalCode && (
                                  <>
                                    {' - '}{list.billingAddress.postalCode}
                                  </>
                                )}
                              </p>
                              <p>{list.billingUserDetails.phoneNumber}</p>
                              <p>{list.billingUserDetails.email}</p>
                            </div>
                          ))}
                        </CardBody>
                      </Card>
                    </Col>
                    {isDifferentAddress ? (
                      <Col md={4} sm={12} className="my-1">
                        <Card className="order-summary-card">
                          <CardHeader>
                            <h2>Shipping Address</h2>
                          </CardHeader>
                          <CardBody>
                            {orderList.map((list, index) => (
                              <div className="address" key={index}>
                                <p>
                                  {list.shippingUserDetails.userName}
                                  <br />
                                  {list.shippingAddress.addressLine1 && (
                                    <>
                                      {list.shippingAddress.addressLine1}
                                      <br />
                                    </>
                                  )}
                                  {list.shippingAddress.addressLine2 && (
                                    <>
                                      {list.shippingAddress.addressLine2}
                                      <br />
                                    </>
                                  )}
                                  {list.shippingAddress.street && (
                                    <>
                                      {list.shippingAddress.street}
                                      <br />
                                    </>
                                  )}
                                  {list.shippingAddress.city && (
                                    <>
                                      {list.shippingAddress.city},
                                      <br />
                                    </>
                                  )}
                                  {list.shippingAddress.state && (
                                    <>
                                      {list.shippingAddress.state}
                                      <br />
                                    </>
                                  )}
                                  {list.shippingAddress.country && (
                                    <>
                                      {list.shippingAddress.country}
                                    </>
                                  )}
                                  {list.shippingAddress.postalCode && (
                                    <>
                                      {' - '}{list.shippingAddress.postalCode}
                                    </>
                                  )}
                                </p>
                                <p>{list.shippingUserDetails.phoneNumber}</p>
                                <p>{list.shippingUserDetails.email}</p>
                              </div>
                            ))}
                          </CardBody>
                        </Card>
                      </Col>
                    ) : orderList[0]?.deliveryInfo?.deliveryType === "Self Pickup" && !isDifferentAddress ? (
                      <Col md={4} sm={12} className="my-1" style={{ borderRight: "1px solid #ece1e1", borderLeft: "1px solid #ece1e1" }}>
                        <Card className="order-summary-card">
                          <CardHeader>
                            <p style={{ fontSize: "17px", fontWeight: '600' }}>Delivery Type: <span style={{ fontWeight: '400' }}>{orderList[0]?.deliveryInfo?.deliveryType}</span></p>
                          </CardHeader>
                        </Card>
                      </Col>
                    ) : !isDifferentAddress && (
                      <Col md={4} sm={12} className="my-1" style={{ borderRight: "1px solid #ece1e1", borderLeft: "1px solid #ece1e1" }}>
                        <Card className="order-summary-card">
                          <CardHeader>
                            <h2>Shipping Address</h2>
                          </CardHeader>
                          <CardBody>
                            <p>Same as billing address</p>
                          </CardBody>
                        </Card>
                      </Col>
                    )
                    }
                  </>
                  <OrderConfirmSummery orderList={orderList} />
                </Row>
                <Row>
                  <div className="continue-shop d-flex justify-content-between align-items-center mt-3 flex-wrap">
                    <p>
                      Need Help ? <a href="/contact-us">Contact Us</a>
                    </p>
                    <button
                      className="btn shop-btn"
                      onClick={() => (window.location.href = "/")}
                    >
                      Continue Shopping
                    </button>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OrderConfirm;
