import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { GetData } from "../providers/queryData";
import { resourceconstant } from "../constants";
const HeaderDropdown = ({ products, MainCategory, category, index }) => {

  const [subCategory, setSubCategory] = useState([])
  const [subCategoryList, setSubCategoryList] = useState([])
  const navigate = useNavigate();

  const SubCategoryList = () => {
    let filter = {
      pk: {
        eq: `SUB#${MainCategory.id}`,
      },
    }
    GetData(resourceconstant.LIST_CATEGORY, filter, setSubCategoryList)
  };

  useEffect(() => {
    if (subCategoryList?.severity === "success") {
      setSubCategory(subCategoryList?.data?.data?.listCategories?.items)
    }
  }, [subCategoryList])

  const handleLinkClick = (e) => {
    e.preventDefault();
    navigate(`/products/${decodeURIComponent(MainCategory.slug?.toLowerCase()).replace(/ /g, '-')}`, {
      state: {
        values: {
          category_id: `${MainCategory.id}`,
          search: `${MainCategory.id}`,
        },
      },
      search: `?category_id=${MainCategory.id}`
    });
  };

  useEffect(() => {
    SubCategoryList()
  }, [])

  return (
    <>
  {subCategory?.length > 0 && (
    <div className="menu-div">
      <li className="dropbtn large-screen-dropbtn">
        <a href={`/products/${decodeURIComponent(MainCategory.slug?.toLowerCase()).replace(/ /g, '-')}`} target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}>{MainCategory.catName}{subCategory?.length > 0 && (<i className="fa fa-chevron-down"></i>)}</a>
          <div className="dropdown-content nav-dropdown">
            <h2>{MainCategory.catName}</h2>
            <hr />
            <ul>
              {subCategory?.filter(subList => products.some(prod => prod.subCategoryID === subList.id)).map((subList, subIndex) => {
                const handleClick = (e) => {
                  e.preventDefault();
                  navigate(`/products/${decodeURIComponent(MainCategory.slug).replace(/ /g, '-')}/${decodeURIComponent(subList.slug).replace(/ /g, '-')}`, {
                    state: {
                      values: {
                        category_id: `${subList.id}`,
                        search: `${subList.id}`,
                        mainCategoryname: `${MainCategory.catName}`,
                        subcategoryName: `${subList.catName}`,
                        mainCategoryId: `${MainCategory.id}`,
                        subCategoryId: `${subList.id}`,
                        slug: `${MainCategory?.slug}`
                      },
                    },
                    search: `?category_id=${subList.id}`
                  });
                };
                return (
                  subList.mainCategoryID === MainCategory.id && (
                    <li key={subIndex}>
                      <a href={`/products/${decodeURIComponent(MainCategory.slug).replace(/ /g, '-')}/${decodeURIComponent(subList.slug).replace(/ /g, '-')}`} onClick={handleClick}>
                        {subList.catName}
                      </a>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
      </li>
    </div>
     )}
    </>
  )
}

export default HeaderDropdown