import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { GetData } from '../providers/queryData';
import { resourceconstant } from '../constants';
const NavbarDropdown = ({ products, userid, localName, userName, category, MainCategory, setNavbarDropdown, navbarDropdown }) => {
    const handleClose = () => setNavbarDropdown(false);
    const handleShow = () => setNavbarDropdown(true);
    const navigate = useNavigate()
    const [categoryName, setCategoryName] = useState('')

    const [subContainerContent, setSubContainerContent] = useState(false);
    const [mainMenuContent, setmainMenuContent] = useState(true);

    const openMainCategory = (catName) => {
      setSubContainerContent(true);
      setmainMenuContent(false);
      setCategoryName(catName);
    };
    const openMainCategoryList = () => {
      setmainMenuContent(true);
      setSubContainerContent(false);
    };

    const [subCategory, setSubCategory] = useState([])
    const [subCategoryList, setSubCategoryList] = useState([])

        const SubCategoryList = () => {
          let filter = {}
          GetData(resourceconstant.LIST_CATEGORY, filter, setSubCategoryList)
        };
    
        useEffect(() => {
          if (subCategoryList?.severity === "success")
          {
            setSubCategory(subCategoryList?.data?.data?.listCategories?.items)
          }
        }, [subCategoryList])
    
        const handleLinkClick = (e, catname) => {
          e.preventDefault();
          handleClose()
          navigate(`/products/${decodeURIComponent(catname.slug?.toLowerCase()).replace(/ /g, '-')}`, {
            state: {
              values: {
                category_id: `${catname.id}`,
                search: `${catname.id}`,
              },
            },
            search: `?category_id=${catname.id}`
          });
        };

        const handleClick = (e, catname, subList) => {
            handleClose()
          e.preventDefault();
          navigate(`/products/${decodeURIComponent(catname.slug).replace(/ /g, '-')}/${decodeURIComponent(subList.slug).replace(/ /g, '-')}`, {
            state: {
              values: {
                category_id: subList.id,
                search: subList.id,
                mainCategoryname: catname.catName,
                subcategoryName: subList.catName,
                mainCategoryId: catname.id,
                subCategoryId: subList.id,
                slug: `${MainCategory?.slug}`
              },
            },
            search: `?category_id=${subList.id}`
          });
        };
    
        useEffect(()=> {
          SubCategoryList()
      },[])

    return (
        <>
        <Modal show={navbarDropdown} onHide={handleClose} animation={false} className="navbar-modal">
        <Modal.Header>
            <h2 style={{ textTransform: 'capitalize' }}>Hello, {userid ? `${userName ? userName : "Sign in"}` : "Sign in"}</h2>
            <i className="fa fa-close" style={{ cursor: "pointer" }} onClick={handleClose}></i>
        </Modal.Header>
        <Modal.Body>
            {subContainerContent ? (
              <div id="sub-container">
                <div className="sub-container-content">
                  <div className="sidenav-content-header" onClick={openMainCategoryList}>
                    <i className="fa fa-chevron-left"></i>
                    <h2>{categoryName.catName}</h2>
                  </div>
                  <hr />
                  <ul>
                    {subCategory?.filter(subList => products.some(prod => prod.subCategoryID === subList.id))?.map((subList, subIndex) => (
                      subList.mainCategoryID === categoryName.id && (
                        <li>
                          <a
                            href={`/products/${encodeURIComponent(categoryName.slug).replace(/ /g, '-')}/${encodeURIComponent(subList.slug).replace(/ /g, '-')}`}
                            onClick={(e) => handleClick(e, categoryName, subList)}
                          >
                            {subList.catName}
                          </a>
                        </li>
                      )
                    ))}
                  </ul>
                </div>
              </div>
            ) : (
              category.map((catname, index) => (
              <div id="main-container">
                <div className="sidenavRow" onClick={() => openMainCategory(catname)}>
                  <a href={`/products/${decodeURIComponent(catname.slug?.toLowerCase()).replace(/ /g, '-')}`} onClick={(e) => handleLinkClick(e, catname)}>
                    {catname.catName}
                  </a>
                  {subCategory?.length > 0 && (<i className="fa fa-chevron-right"></i>)}
                </div>
              </div>
            ))
            )}
       
      </Modal.Body>
    </Modal>
        </>
    );
}

export default NavbarDropdown;