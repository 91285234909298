import React, { useEffect, useState } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 525 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 525, min: 0 },
      items: 2
    }
  };
const AddressSlider = ({ isSelfPickup, isShipped, setIsSameCheck, billValues, setCheckedBill, isChangeAddress, countryItem, setIsBilled, setBillValues, setChangeBill, isSameCheck, changeBill, changeShipp, setChangeShipp, setIsChangeAddress, userAddress, setShippValues, setIsShipped, shippValues, setCheckedShipp, checkedShipp }) => {
  const defaultAddress = userAddress?.addressDetails?.find(item => item.default);
  useEffect(()=>{
    if (defaultAddress) {
      setCheckedShipp(defaultAddress)
        setShippValues(defaultAddress)
        setIsShipped(true)
        setChangeShipp(defaultAddress)
        if(isSameCheck && !isShipped){
          setBillValues(defaultAddress)
          setChangeBill(defaultAddress)
          setIsBilled(true)
          setIsChangeAddress(false)
        }
        if(isSameCheck === true && !isSelfPickup){
          setBillValues(shippValues)
          setChangeBill(shippValues)
          setIsBilled(true)
          setIsChangeAddress(false)
      }
        else if(isShipped){
          setBillValues(shippValues)
          setChangeBill(shippValues)
          setIsBilled(true)
          setIsChangeAddress(false)
        }
        if(changeBill?.length !==0){
          setIsChangeAddress(false)
        }
        if(shippValues && isChangeAddress){
          setShippValues(shippValues)
          setIsShipped(true)
          setChangeShipp(changeShipp)
        }
        if(isSameCheck && isChangeAddress){
          setBillValues(billValues)
          setChangeBill(billValues)
          setIsBilled(true)
        }
        if (isChangeAddress && JSON.stringify(shippValues) !== JSON.stringify(billValues))
          {
            setIsSameCheck(false);
          }
          
        if(!isSameCheck && isChangeAddress && JSON.stringify(shippValues) !== JSON.stringify(billValues)){
          setCheckedBill([])
        setBillValues([])
        setIsBilled(false)
        }
    }
    else{
      setCheckedShipp('')
        setShippValues([])
        setIsShipped(false)
        setChangeShipp([])
    }
  },[defaultAddress])
  
    useEffect(()=>{
      if(!isSelfPickup && JSON.stringify(shippValues) !== JSON.stringify(billValues)){
        setIsSameCheck(false);
      }
    },[shippValues, billValues, !isSelfPickup])

    return(
        
      <Carousel
      responsive={responsive}
      arrows={true}
      className='address-list'
      >
        {(userAddress?.addressDetails || []).map((item, index) => {
          const country = countryItem?.filter((country)=>country?.id===item?.address?.country) 
          const handleCheckboxChange = (event) => {
            const value = event.target.checked
            if (value) {
              setCheckedShipp(value)
                setShippValues(item)
                setIsShipped(true)
                setChangeShipp(item)
                if(isSameCheck){
                  setBillValues(item)
                  setChangeBill(item)
                  setIsBilled(true)
                  setIsChangeAddress(false)
                }
                if(changeBill?.length !==0){
                  setIsChangeAddress(false)
                }
            }
            else{
              setCheckedShipp('')
                setShippValues([])
                setIsShipped(false)
                setChangeShipp([])
            }
        };
        
         return (
          <div className='address-tile' key={index}>
              <div className='user-detail'>
                <input 
                  type='checkbox' 
                  checked={checkedShipp && item === shippValues} 
                  onChange={handleCheckboxChange}
                />
                <h2>{item.name}</h2>
                <p>
                  {item?.address?.addressLine1 && (
                    <>
                      {item.address.addressLine1} <br />
                    </>
                  )}
                  {item?.address?.addressLine2 && (
                    <>
                      {item.address.addressLine2}, <br />
                    </>
                  )}
                  {item?.address?.street && (
                    <>
                      {item.address.street}, <br />
                    </>
                  )}
                  {item?.address?.city && (
                    <>
                      {item.address.city}, <br />
                    </>
                  )}
                  {item?.address?.state && (
                    <>
                      {item.address.state}, <br />
                    </>
                  )}
                  {item?.address?.country && (
                    <>
                      {item.address.country}
                    </>
                  )}
                  {item?.address?.postalCode && (
                    <>
                      {' - '}{item.address.postalCode}
                    </>
                  )}
                </p>
                <p>{item?.phoneNumber}</p>
                <p>{item?.email}</p>
              </div>
            </div>
         );
      })}
     </Carousel>
    )
}
export default AddressSlider