import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import Logo from '../../assets/images/logo.png'
import Amplify, { Auth } from 'aws-amplify';
import Footer from "../../pages/footer";
import useRegisterForm from "./useRegisterForm";
import customLogger from "../../common/loggerService";
import { useNavigate } from 'react-router-dom';
import { showErrorToastr, showSuccessToastr } from "../../common/toastrService";
import { commonImages, resourceconstant } from "../../constants";
import { Link } from "react-router-dom";
import { ValidateName } from "../../constants/validateMsgConstant";
import { AccountFieldNameConstant, PasswordValidation } from "../../constants/validateMsgConstant";
import ValidateField from "../../utils/useValid";
import useLogoImage from "../../hooks/useLogoImage";
import { ToastrMessages } from "../../constants/toastrMessages";
import InputGroup from 'react-bootstrap/InputGroup';
import { CreateEntry } from "../../providers/queryData";
const formValues = {
    newPassword: '',
    confirmPassword: ''
}
const ResetPassword = () => {
    const [values, setValues] = useState(formValues)
    const [errors, setErrors] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const navigate = useNavigate()
    const [logoUrl, setLogoUrl] = useLogoImage();

    const loggedEmail = localStorage.getItem('forgetEmail');
    const verifyCode = localStorage.getItem('code')
    const [passwordRequirements, setPasswordRequirements] = useState({
        minLength: false,
        hasUpperLower: false,
        hasNumber: false,
        hasSpecialChar: false
    });

    const [isEditingNewPassword, setIsEditingNewPassword] = useState(false);
    const [resetPassword, setResetPassword] = useState(null)

    const HandleChangePassword = (e) => {
        const { name, value } = e.target;

        setValues({
            ...values,
            [name]: value,
        });

        if (name === 'newPassword') {
            setPasswordRequirements({
                minLength: value.length >= 8,
                hasUpperLower: /[a-z]/.test(value) && /[A-Z]/.test(value),
                hasNumber: /\d/.test(value),
                hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value)
            });
        }

        setErrors({
            ...errors,
            [name]: ""
        });

        if (name === 'confirmPassword' && value !== values.newPassword) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                confirmPassword: AccountFieldNameConstant?.PASSWORDNOTMATCH
            }));
        }
    };

    const HandleBlur = (e) => {
        const { name, value } = e.target;
        const fieldErrors = validation({
            ...values,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: fieldErrors[name]
        });

        if (name === 'newPassword') {
            setIsEditingNewPassword(false);
        }

        if (name === 'confirmPassword' && value !== values.newPassword) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                confirmPassword: AccountFieldNameConstant?.PASSWORDNOTMATCH
            }));
        }
    };

    const validation = (values) => {
        const errors = {};
        errors.newPassword = ValidateField(
            values?.newPassword,
            AccountFieldNameConstant?.NEWPASSWORD,
            [ValidateName?.REQUIRED, ValidateName?.LOGINPASSWORD],
        );

        errors.confirmPassword = ValidateField(
            values?.confirmPassword,
            AccountFieldNameConstant?.CONFIRMPASSWORD,
            [ValidateName?.REQUIRED],
        );

        let error = {}
        Object.keys(errors).map((key, index) => {
            if (errors[key] !== undefined)
                error[key] = errors[key]
        })
        return error
    }

    const ConfirmPassword = (e) => {
        e.preventDefault();
        
        const validationErrors = validation(values);
        if (values.confirmPassword !== values.newPassword) {
            validationErrors.confirmPassword = AccountFieldNameConstant?.PASSWORDNOTMATCH;
        }
    
        setErrors(validationErrors);
        setIsSubmit(true);
    };

    useEffect(() => {
        const confirmSignUpWithOTP = async (e) => {
            try {
                const data = {
                    email: loggedEmail,
                    new_password: values?.newPassword
              };

              await CreateEntry(resourceconstant?.FORGET_PASSWORD, false, setResetPassword, data)

            } catch (error) {
                customLogger.error(error);
                showErrorToastr(error.message);
            }
        };
        if (Object.keys(errors).length === 0 && isSubmit) {
            confirmSignUpWithOTP();
        }
    }, [errors])

    useEffect(() => {
        if (resetPassword?.status === true) {
            showSuccessToastr(resetPassword?.message)
            navigate('/login');
        }
        else if(resetPassword?.status === false) {
          showErrorToastr(resetPassword?.message);
        }
      }, [resetPassword])

    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);


    const NewPasswordVisibility = () => {
        setNewPasswordShown(!newPasswordShown);
    };

    const ConfirmPasswordVisibility = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
    };


    return (
        <>
            <div className="login-page otp">
                <Card>
                    <Row>
                        <Col md={12}>
                            <div className="login-sec">
                                <div className="logo-sec">
                                    <Link to="/forgot-password" style={{ color: "inherit" }} className="text-decoration-none">
                                        <div class="back-btn">
                                            <i className="fa fa-chevron-left"></i>
                                        </div>
                                    </Link>

                                </div>
                                <div className="login-form">
                                    <form onSubmit={e => ConfirmPassword(e)}>
                                        <Row>
                                            <Col md={12}>
                                                <h1>Create New Password</h1>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div class="form-group">
                                                    <p>Enter a new password for your account. Make sure it's strong to keep your account secure</p>
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div className="form-group mt-1">
                                                    <label>New Password</label>
                                                    <InputGroup>
                                                        <Form.Control
                                                            type={newPasswordShown ? "text" : "password"}
                                                            placeholder="Enter"
                                                            name="newPassword"
                                                            id="newPassword"
                                                            onChange={HandleChangePassword}
                                                            onBlur={HandleBlur}
                                                            onFocus={() => setIsEditingNewPassword(true)}
                                                            style={{ borderRight: 0 }}
                                                        />
                                                        <InputGroup.Text onClick={NewPasswordVisibility} style={{ cursor: 'pointer' }}>
                                                            <i className={`fa ${newPasswordShown ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                    {!(passwordRequirements.minLength && passwordRequirements.hasUpperLower && passwordRequirements.hasNumber && passwordRequirements.hasSpecialChar) && (
                                                        <>
                                                            <p className={passwordRequirements?.minLength ? "success-msg" : "form-error"} >{PasswordValidation?.CHARACTER}</p>
                                                            <p className={passwordRequirements?.hasUpperLower ? "success-msg" : "form-error"} >{PasswordValidation?.CASES}</p>
                                                            <p className={passwordRequirements?.hasNumber ? "success-msg" : "form-error"} >{PasswordValidation?.NUMBER}</p>
                                                            <p className={passwordRequirements?.hasSpecialChar ? "success-msg" : "form-error"} >{PasswordValidation?.SPECIALCHARACTER}</p>
                                                        </>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div className="form-group">
                                                    <label>Confirm New Password</label>
                                                    <InputGroup>
                                                        <Form.Control
                                                            type={confirmPasswordShown ? "text" : "password"}
                                                            placeholder="Re-Enter New Password"
                                                            name="confirmPassword"
                                                            id="confirmPassword"
                                                            onChange={HandleChangePassword}
                                                            onBlur={HandleBlur}
                                                            style={{ borderRight: 0 }}
                                                        />
                                                        <InputGroup.Text onClick={ConfirmPasswordVisibility} style={{ cursor: 'pointer' }}>
                                                            <i className={`fa ${confirmPasswordShown ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                    {errors?.confirmPassword && <p className="form-error">{errors?.confirmPassword}</p>}
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <button className="btn submit-btn" type="submit">Continue</button>
                                            </Col>
                                            <Col md={12}>
                                                <p className="text-center">By continuing, you agree to {logoUrl?.brandName}’s<Link to="/terms-condition" state={{ activeKey: "terms" }} onClick={() => { window.scroll(0, 0); }}>Conditions of Use</Link>and<Link to='/privacy-policy' onClick={() => { window.scroll(0, 0); }}>Privacy Policy.</Link></p>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
            <div className="footer-sec">
                <div className="footer-wrapper">
                    <Footer />
                </div>
            </div>
        </>
    )
};
export default ResetPassword