import React, { useState, useEffect } from "react";
import Select from "react-select";

const style = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    border: "1px solid #eae8e8",
    backgroundColor: "#f9f9f9",
    fontSize: "14px",
    fontWeight: "500",
    minHeight: "35px",
    height: "35px",
    borderRadius: "4px",
    color: "#000811",
  }),
};

export const DropDownComponent = ({
  isDisabled,
  placeHolder,
  pageName,
  dpDownName,
  stateData,
  controlName,
  handleChange,
  value,
  multiSelect,
}) => {
  const [dropdownOption, setDropdownOption] = useState([]);
  useEffect(() => {
    const GetData = () => {
      if (stateData) {
        const list = FormatdrpOption(stateData);
        setDropdownOption(list);
      }
    };
    GetData();
  }, [stateData]);

  const FormatdrpOption = (list) => {
    var te = list?.map((data, index) => ({
      label: GetLabel(data),
      value: GetId(data),
    }));
    return te;
  };

  const GetLabel = (data) => {
    if (dpDownName === "country") return data?.name;
    if (dpDownName === "reason") return data?.value;
    if (dpDownName === "type") return data?.label;
    else return "";
  };

  const GetId = (data) => {
    if (dpDownName === "country") return data?.name;
    if (dpDownName === "reason") return data?.value;
    if (dpDownName === "type") return data?.value;
    else return "";
  };

  return (
    <Select
      isMulti={multiSelect}
      name={controlName}
      options={dropdownOption}
      className="basic-multi-select"
      onChange={handleChange}
      value={value}
      styles={style}
      placeholder={placeHolder}
      theme={(theme) => ({
        ...theme,
        colors: { ...theme.colors, neutral80: "#000000" },
      })}
      isDisabled={isDisabled}
    // isClearable={dpDownName !== "customerStatus"}
    />
  );
};
