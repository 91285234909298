/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateSettings = /* GraphQL */ `
  subscription OnCreateSettings(
    $filter: ModelSubscriptionSettingsFilterInput
    $owner: String
  ) {
    onCreateSettings(filter: $filter, owner: $owner) {
      id
      pk
      notificationType
      createdAt
      emailTo
      productExpiryDuration
      theme
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateSettings = /* GraphQL */ `
  subscription OnUpdateSettings(
    $filter: ModelSubscriptionSettingsFilterInput
    $owner: String
  ) {
    onUpdateSettings(filter: $filter, owner: $owner) {
      id
      pk
      notificationType
      createdAt
      emailTo
      productExpiryDuration
      theme
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteSettings = /* GraphQL */ `
  subscription OnDeleteSettings(
    $filter: ModelSubscriptionSettingsFilterInput
    $owner: String
  ) {
    onDeleteSettings(filter: $filter, owner: $owner) {
      id
      pk
      notificationType
      createdAt
      emailTo
      productExpiryDuration
      theme
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateCompanyDetails = /* GraphQL */ `
  subscription OnCreateCompanyDetails(
    $filter: ModelSubscriptionCompanyDetailsFilterInput
    $owner: String
  ) {
    onCreateCompanyDetails(filter: $filter, owner: $owner) {
      id
      pk
      createdAt
      primaryLogo {
        alterText
        title
        url
        description
        type
        __typename
      }
      secondaryLogo {
        alterText
        title
        url
        description
        type
        __typename
      }
      companyName
      webSite
      phoneNumber
      email
      ccEmail
      address {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      status
      currency
      categorySequenced
      copyrightText
      mobileAppUrls {
        type
        title
        alterText
        url
        certificationName
        redirectTo
        __typename
      }
      theme {
        preferredTheme
        headerBackgroundColor
        headerTextColor
        headerIconColor
        footerBackgroundColor
        footerTextColor
        footerHeadingColor
        font
        navigationMenu {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        navigationMenuColor
        navigationSubMenu {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        navigationSubMenuColor
        productTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productPrice {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailName {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailPrice {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDescriptionHeading {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDescription {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        customerReviewsTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        customerReviewsDetails {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        notificationsTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        notificationsSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        profileHeading {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        profileContent {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        contentTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        contentDescription {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        titleHeader {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        titleDetails {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        primaryButton {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        primaryButtonBackgroundColor
        primaryButtonTextColor
        secondaryButton {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        secondaryButtonBackgroundColor
        secondaryButtonTextColor
        __typename
      }
      mobileTheme {
        locationBackgroundColor
        locationTextColor
        locationIconColor
        searchBarBackgroundColor
        newArrivalsColor
        bestSellersColor
        recentlyViewedColor
        navBackgroundColor
        navIconColor
        font
        productListTitle
        productListBrand
        productListStock
        productListPrice
        productDetailName
        productDetailPrice
        productDetailStock
        productDetailBrand
        customerReviewsTitle
        customerReviewsDetails
        notificationsTitle
        notificationsSubTitle
        profileName
        profileHeadings
        primaryButtonText
        primaryButtonBackgroundColor
        primaryButtonTextColor
        secondaryButtonText
        secondaryButtonBackgroundColor
        secondaryButtonTextColor
        __typename
      }
      seoDescription
      daysToReturn
      brandName
      iconUrl
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCompanyDetails = /* GraphQL */ `
  subscription OnUpdateCompanyDetails(
    $filter: ModelSubscriptionCompanyDetailsFilterInput
    $owner: String
  ) {
    onUpdateCompanyDetails(filter: $filter, owner: $owner) {
      id
      pk
      createdAt
      primaryLogo {
        alterText
        title
        url
        description
        type
        __typename
      }
      secondaryLogo {
        alterText
        title
        url
        description
        type
        __typename
      }
      companyName
      webSite
      phoneNumber
      email
      ccEmail
      address {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      status
      currency
      categorySequenced
      copyrightText
      mobileAppUrls {
        type
        title
        alterText
        url
        certificationName
        redirectTo
        __typename
      }
      theme {
        preferredTheme
        headerBackgroundColor
        headerTextColor
        headerIconColor
        footerBackgroundColor
        footerTextColor
        footerHeadingColor
        font
        navigationMenu {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        navigationMenuColor
        navigationSubMenu {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        navigationSubMenuColor
        productTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productPrice {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailName {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailPrice {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDescriptionHeading {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDescription {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        customerReviewsTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        customerReviewsDetails {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        notificationsTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        notificationsSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        profileHeading {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        profileContent {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        contentTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        contentDescription {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        titleHeader {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        titleDetails {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        primaryButton {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        primaryButtonBackgroundColor
        primaryButtonTextColor
        secondaryButton {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        secondaryButtonBackgroundColor
        secondaryButtonTextColor
        __typename
      }
      mobileTheme {
        locationBackgroundColor
        locationTextColor
        locationIconColor
        searchBarBackgroundColor
        newArrivalsColor
        bestSellersColor
        recentlyViewedColor
        navBackgroundColor
        navIconColor
        font
        productListTitle
        productListBrand
        productListStock
        productListPrice
        productDetailName
        productDetailPrice
        productDetailStock
        productDetailBrand
        customerReviewsTitle
        customerReviewsDetails
        notificationsTitle
        notificationsSubTitle
        profileName
        profileHeadings
        primaryButtonText
        primaryButtonBackgroundColor
        primaryButtonTextColor
        secondaryButtonText
        secondaryButtonBackgroundColor
        secondaryButtonTextColor
        __typename
      }
      seoDescription
      daysToReturn
      brandName
      iconUrl
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCompanyDetails = /* GraphQL */ `
  subscription OnDeleteCompanyDetails(
    $filter: ModelSubscriptionCompanyDetailsFilterInput
    $owner: String
  ) {
    onDeleteCompanyDetails(filter: $filter, owner: $owner) {
      id
      pk
      createdAt
      primaryLogo {
        alterText
        title
        url
        description
        type
        __typename
      }
      secondaryLogo {
        alterText
        title
        url
        description
        type
        __typename
      }
      companyName
      webSite
      phoneNumber
      email
      ccEmail
      address {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      status
      currency
      categorySequenced
      copyrightText
      mobileAppUrls {
        type
        title
        alterText
        url
        certificationName
        redirectTo
        __typename
      }
      theme {
        preferredTheme
        headerBackgroundColor
        headerTextColor
        headerIconColor
        footerBackgroundColor
        footerTextColor
        footerHeadingColor
        font
        navigationMenu {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        navigationMenuColor
        navigationSubMenu {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        navigationSubMenuColor
        productTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productPrice {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailName {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDetailPrice {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDescriptionHeading {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        productDescription {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        customerReviewsTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        customerReviewsDetails {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        notificationsTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        notificationsSubTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        profileHeading {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        profileContent {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        contentTitle {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        contentDescription {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        titleHeader {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        titleDetails {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        primaryButton {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        primaryButtonBackgroundColor
        primaryButtonTextColor
        secondaryButton {
          title
          example
          fontWeight
          fontSize
          __typename
        }
        secondaryButtonBackgroundColor
        secondaryButtonTextColor
        __typename
      }
      mobileTheme {
        locationBackgroundColor
        locationTextColor
        locationIconColor
        searchBarBackgroundColor
        newArrivalsColor
        bestSellersColor
        recentlyViewedColor
        navBackgroundColor
        navIconColor
        font
        productListTitle
        productListBrand
        productListStock
        productListPrice
        productDetailName
        productDetailPrice
        productDetailStock
        productDetailBrand
        customerReviewsTitle
        customerReviewsDetails
        notificationsTitle
        notificationsSubTitle
        profileName
        profileHeadings
        primaryButtonText
        primaryButtonBackgroundColor
        primaryButtonTextColor
        secondaryButtonText
        secondaryButtonBackgroundColor
        secondaryButtonTextColor
        __typename
      }
      seoDescription
      daysToReturn
      brandName
      iconUrl
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreatePaymentMode = /* GraphQL */ `
  subscription OnCreatePaymentMode(
    $filter: ModelSubscriptionPaymentModeFilterInput
    $owner: String
  ) {
    onCreatePaymentMode(filter: $filter, owner: $owner) {
      id
      pk
      createdAt
      method
      description
      provider
      logoUrl
      stagingUrl
      productionUrl
      status
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdatePaymentMode = /* GraphQL */ `
  subscription OnUpdatePaymentMode(
    $filter: ModelSubscriptionPaymentModeFilterInput
    $owner: String
  ) {
    onUpdatePaymentMode(filter: $filter, owner: $owner) {
      id
      pk
      createdAt
      method
      description
      provider
      logoUrl
      stagingUrl
      productionUrl
      status
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeletePaymentMode = /* GraphQL */ `
  subscription OnDeletePaymentMode(
    $filter: ModelSubscriptionPaymentModeFilterInput
    $owner: String
  ) {
    onDeletePaymentMode(filter: $filter, owner: $owner) {
      id
      pk
      createdAt
      method
      description
      provider
      logoUrl
      stagingUrl
      productionUrl
      status
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onCreateUser(filter: $filter, owner: $owner) {
      id
      pk
      userName
      firstName
      lastName
      companyName
      profileImageUrl
      email
      phoneNumber
      role
      password
      multiFactorAuth
      emailNotification
      mandatePasswordChange
      emailVerified
      otp
      status
      searchKey
      addressDetails {
        name
        phoneNumber
        email
        address {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        type
        addressName
        default
        latitude
        longitude
        __typename
      }
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onUpdateUser(filter: $filter, owner: $owner) {
      id
      pk
      userName
      firstName
      lastName
      companyName
      profileImageUrl
      email
      phoneNumber
      role
      password
      multiFactorAuth
      emailNotification
      mandatePasswordChange
      emailVerified
      otp
      status
      searchKey
      addressDetails {
        name
        phoneNumber
        email
        address {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        type
        addressName
        default
        latitude
        longitude
        __typename
      }
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onDeleteUser(filter: $filter, owner: $owner) {
      id
      pk
      userName
      firstName
      lastName
      companyName
      profileImageUrl
      email
      phoneNumber
      role
      password
      multiFactorAuth
      emailNotification
      mandatePasswordChange
      emailVerified
      otp
      status
      searchKey
      addressDetails {
        name
        phoneNumber
        email
        address {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        type
        addressName
        default
        latitude
        longitude
        __typename
      }
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory(
    $filter: ModelSubscriptionCategoryFilterInput
    $owner: String
  ) {
    onCreateCategory(filter: $filter, owner: $owner) {
      pk
      slug
      id
      catName
      mainCategory
      mainCategoryID
      categoryImg
      description
      webBannerImg
      webBannerImgBottom
      mobBannerImg
      mobBannerImgBottom
      categoryType
      searchKey
      catStatus
      sequenceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory(
    $filter: ModelSubscriptionCategoryFilterInput
    $owner: String
  ) {
    onUpdateCategory(filter: $filter, owner: $owner) {
      pk
      slug
      id
      catName
      mainCategory
      mainCategoryID
      categoryImg
      description
      webBannerImg
      webBannerImgBottom
      mobBannerImg
      mobBannerImgBottom
      categoryType
      searchKey
      catStatus
      sequenceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory(
    $filter: ModelSubscriptionCategoryFilterInput
    $owner: String
  ) {
    onDeleteCategory(filter: $filter, owner: $owner) {
      pk
      slug
      id
      catName
      mainCategory
      mainCategoryID
      categoryImg
      description
      webBannerImg
      webBannerImgBottom
      mobBannerImg
      mobBannerImgBottom
      categoryType
      searchKey
      catStatus
      sequenceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateBrand = /* GraphQL */ `
  subscription OnCreateBrand(
    $filter: ModelSubscriptionBrandFilterInput
    $owner: String
  ) {
    onCreateBrand(filter: $filter, owner: $owner) {
      pk
      id
      brandName
      searchKey
      manufacturerName
      status
      countryOfOrigin
      brandLogo
      brandUrl
      brandDescription
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateBrand = /* GraphQL */ `
  subscription OnUpdateBrand(
    $filter: ModelSubscriptionBrandFilterInput
    $owner: String
  ) {
    onUpdateBrand(filter: $filter, owner: $owner) {
      pk
      id
      brandName
      searchKey
      manufacturerName
      status
      countryOfOrigin
      brandLogo
      brandUrl
      brandDescription
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteBrand = /* GraphQL */ `
  subscription OnDeleteBrand(
    $filter: ModelSubscriptionBrandFilterInput
    $owner: String
  ) {
    onDeleteBrand(filter: $filter, owner: $owner) {
      pk
      id
      brandName
      searchKey
      manufacturerName
      status
      countryOfOrigin
      brandLogo
      brandUrl
      brandDescription
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateAttributeName = /* GraphQL */ `
  subscription OnCreateAttributeName(
    $filter: ModelSubscriptionAttributeNameFilterInput
    $owner: String
  ) {
    onCreateAttributeName(filter: $filter, owner: $owner) {
      pk
      slug
      id
      sequenceNumber
      searchKey
      status
      attrName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateAttributeName = /* GraphQL */ `
  subscription OnUpdateAttributeName(
    $filter: ModelSubscriptionAttributeNameFilterInput
    $owner: String
  ) {
    onUpdateAttributeName(filter: $filter, owner: $owner) {
      pk
      slug
      id
      sequenceNumber
      searchKey
      status
      attrName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteAttributeName = /* GraphQL */ `
  subscription OnDeleteAttributeName(
    $filter: ModelSubscriptionAttributeNameFilterInput
    $owner: String
  ) {
    onDeleteAttributeName(filter: $filter, owner: $owner) {
      pk
      slug
      id
      sequenceNumber
      searchKey
      status
      attrName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateCountry = /* GraphQL */ `
  subscription OnCreateCountry(
    $filter: ModelSubscriptionCountryFilterInput
    $owner: String
  ) {
    onCreateCountry(filter: $filter, owner: $owner) {
      pk
      slug
      id
      name
      shortName
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCountry = /* GraphQL */ `
  subscription OnUpdateCountry(
    $filter: ModelSubscriptionCountryFilterInput
    $owner: String
  ) {
    onUpdateCountry(filter: $filter, owner: $owner) {
      pk
      slug
      id
      name
      shortName
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCountry = /* GraphQL */ `
  subscription OnDeleteCountry(
    $filter: ModelSubscriptionCountryFilterInput
    $owner: String
  ) {
    onDeleteCountry(filter: $filter, owner: $owner) {
      pk
      slug
      id
      name
      shortName
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateDropDownMaster = /* GraphQL */ `
  subscription OnCreateDropDownMaster(
    $filter: ModelSubscriptionDropDownMasterFilterInput
    $owner: String
  ) {
    onCreateDropDownMaster(filter: $filter, owner: $owner) {
      pk
      id
      value
      status
      searchKey
      sequenceNumber
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateDropDownMaster = /* GraphQL */ `
  subscription OnUpdateDropDownMaster(
    $filter: ModelSubscriptionDropDownMasterFilterInput
    $owner: String
  ) {
    onUpdateDropDownMaster(filter: $filter, owner: $owner) {
      pk
      id
      value
      status
      searchKey
      sequenceNumber
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteDropDownMaster = /* GraphQL */ `
  subscription OnDeleteDropDownMaster(
    $filter: ModelSubscriptionDropDownMasterFilterInput
    $owner: String
  ) {
    onDeleteDropDownMaster(filter: $filter, owner: $owner) {
      pk
      id
      value
      status
      searchKey
      sequenceNumber
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateCart = /* GraphQL */ `
  subscription OnCreateCart(
    $filter: ModelSubscriptionCartFilterInput
    $owner: String
  ) {
    onCreateCart(filter: $filter, owner: $owner) {
      pk
      id
      userId
      productID
      createdAt
      productImage
      productName
      mainCategoryId
      mainCategory
      subCategoryId
      subCategoryN
      attrValueID
      price
      salesPrice
      stockUnit
      itemsPerUnit
      expiryDate
      selectedQuantity
      brandDetail {
        brandID
        brandName
        brandDescription
        manufacturerName
        imageUrl
        __typename
      }
      wareHouseId
      wareHouseName
      attrName
      attrValue
      shippingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      userName
      userMail
      model
      cartURL
      taxCode
      taxCharge
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      sku
      searchKey
      totalPrice
      mobileNumber
      currentStock
      brandId
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCart = /* GraphQL */ `
  subscription OnUpdateCart(
    $filter: ModelSubscriptionCartFilterInput
    $owner: String
  ) {
    onUpdateCart(filter: $filter, owner: $owner) {
      pk
      id
      userId
      productID
      createdAt
      productImage
      productName
      mainCategoryId
      mainCategory
      subCategoryId
      subCategoryN
      attrValueID
      price
      salesPrice
      stockUnit
      itemsPerUnit
      expiryDate
      selectedQuantity
      brandDetail {
        brandID
        brandName
        brandDescription
        manufacturerName
        imageUrl
        __typename
      }
      wareHouseId
      wareHouseName
      attrName
      attrValue
      shippingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      userName
      userMail
      model
      cartURL
      taxCode
      taxCharge
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      sku
      searchKey
      totalPrice
      mobileNumber
      currentStock
      brandId
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCart = /* GraphQL */ `
  subscription OnDeleteCart(
    $filter: ModelSubscriptionCartFilterInput
    $owner: String
  ) {
    onDeleteCart(filter: $filter, owner: $owner) {
      pk
      id
      userId
      productID
      createdAt
      productImage
      productName
      mainCategoryId
      mainCategory
      subCategoryId
      subCategoryN
      attrValueID
      price
      salesPrice
      stockUnit
      itemsPerUnit
      expiryDate
      selectedQuantity
      brandDetail {
        brandID
        brandName
        brandDescription
        manufacturerName
        imageUrl
        __typename
      }
      wareHouseId
      wareHouseName
      attrName
      attrValue
      shippingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      userName
      userMail
      model
      cartURL
      taxCode
      taxCharge
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      sku
      searchKey
      totalPrice
      mobileNumber
      currentStock
      brandId
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateWareHouse = /* GraphQL */ `
  subscription OnCreateWareHouse(
    $filter: ModelSubscriptionWareHouseFilterInput
    $owner: String
  ) {
    onCreateWareHouse(filter: $filter, owner: $owner) {
      pk
      id
      name
      createdAt
      addressLine1
      addressLine2
      searchKey
      countryId
      country
      state
      city
      zipCode
      contactPerson
      email
      phoneNumber
      workingHoursFrom
      workingHoursTo
      status
      latitude
      longitude
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateWareHouse = /* GraphQL */ `
  subscription OnUpdateWareHouse(
    $filter: ModelSubscriptionWareHouseFilterInput
    $owner: String
  ) {
    onUpdateWareHouse(filter: $filter, owner: $owner) {
      pk
      id
      name
      createdAt
      addressLine1
      addressLine2
      searchKey
      countryId
      country
      state
      city
      zipCode
      contactPerson
      email
      phoneNumber
      workingHoursFrom
      workingHoursTo
      status
      latitude
      longitude
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteWareHouse = /* GraphQL */ `
  subscription OnDeleteWareHouse(
    $filter: ModelSubscriptionWareHouseFilterInput
    $owner: String
  ) {
    onDeleteWareHouse(filter: $filter, owner: $owner) {
      pk
      id
      name
      createdAt
      addressLine1
      addressLine2
      searchKey
      countryId
      country
      state
      city
      zipCode
      contactPerson
      email
      phoneNumber
      workingHoursFrom
      workingHoursTo
      status
      latitude
      longitude
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateGeneralContactUs = /* GraphQL */ `
  subscription OnCreateGeneralContactUs(
    $filter: ModelSubscriptionGeneralContactUsFilterInput
    $owner: String
  ) {
    onCreateGeneralContactUs(filter: $filter, owner: $owner) {
      pk
      id
      referenceNumber
      userName
      email
      phoneNumber
      companyName
      subject
      message
      replyMessage
      enquiryStatus
      attachments
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateGeneralContactUs = /* GraphQL */ `
  subscription OnUpdateGeneralContactUs(
    $filter: ModelSubscriptionGeneralContactUsFilterInput
    $owner: String
  ) {
    onUpdateGeneralContactUs(filter: $filter, owner: $owner) {
      pk
      id
      referenceNumber
      userName
      email
      phoneNumber
      companyName
      subject
      message
      replyMessage
      enquiryStatus
      attachments
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteGeneralContactUs = /* GraphQL */ `
  subscription OnDeleteGeneralContactUs(
    $filter: ModelSubscriptionGeneralContactUsFilterInput
    $owner: String
  ) {
    onDeleteGeneralContactUs(filter: $filter, owner: $owner) {
      pk
      id
      referenceNumber
      userName
      email
      phoneNumber
      companyName
      subject
      message
      replyMessage
      enquiryStatus
      attachments
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateNotifications = /* GraphQL */ `
  subscription OnCreateNotifications(
    $filter: ModelSubscriptionNotificationsFilterInput
    $owner: String
  ) {
    onCreateNotifications(filter: $filter, owner: $owner) {
      pk
      id
      userId
      userName
      notificationType
      Title
      Message
      orderID
      DateTime
      notificationStatus
      notificationModule
      referenceID
      targetURL
      searchKey
      expiryDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateNotifications = /* GraphQL */ `
  subscription OnUpdateNotifications(
    $filter: ModelSubscriptionNotificationsFilterInput
    $owner: String
  ) {
    onUpdateNotifications(filter: $filter, owner: $owner) {
      pk
      id
      userId
      userName
      notificationType
      Title
      Message
      orderID
      DateTime
      notificationStatus
      notificationModule
      referenceID
      targetURL
      searchKey
      expiryDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteNotifications = /* GraphQL */ `
  subscription OnDeleteNotifications(
    $filter: ModelSubscriptionNotificationsFilterInput
    $owner: String
  ) {
    onDeleteNotifications(filter: $filter, owner: $owner) {
      pk
      id
      userId
      userName
      notificationType
      Title
      Message
      orderID
      DateTime
      notificationStatus
      notificationModule
      referenceID
      targetURL
      searchKey
      expiryDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateModuleMaster = /* GraphQL */ `
  subscription OnCreateModuleMaster(
    $filter: ModelSubscriptionModuleMasterFilterInput
    $owner: String
  ) {
    onCreateModuleMaster(filter: $filter, owner: $owner) {
      pk
      id
      moduleName
      sequenceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateModuleMaster = /* GraphQL */ `
  subscription OnUpdateModuleMaster(
    $filter: ModelSubscriptionModuleMasterFilterInput
    $owner: String
  ) {
    onUpdateModuleMaster(filter: $filter, owner: $owner) {
      pk
      id
      moduleName
      sequenceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteModuleMaster = /* GraphQL */ `
  subscription OnDeleteModuleMaster(
    $filter: ModelSubscriptionModuleMasterFilterInput
    $owner: String
  ) {
    onDeleteModuleMaster(filter: $filter, owner: $owner) {
      pk
      id
      moduleName
      sequenceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateRoleManagement = /* GraphQL */ `
  subscription OnCreateRoleManagement(
    $filter: ModelSubscriptionRoleManagementFilterInput
    $owner: String
  ) {
    onCreateRoleManagement(filter: $filter, owner: $owner) {
      pk
      id
      menuId
      roleName
      module {
        moduleId
        menu
        view
        add
        edit
        delete
        __typename
      }
      status
      searchKey
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const onUpdateRoleManagement = /* GraphQL */ `
  subscription OnUpdateRoleManagement(
    $filter: ModelSubscriptionRoleManagementFilterInput
    $owner: String
  ) {
    onUpdateRoleManagement(filter: $filter, owner: $owner) {
      pk
      id
      menuId
      roleName
      module {
        moduleId
        menu
        view
        add
        edit
        delete
        __typename
      }
      status
      searchKey
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const onDeleteRoleManagement = /* GraphQL */ `
  subscription OnDeleteRoleManagement(
    $filter: ModelSubscriptionRoleManagementFilterInput
    $owner: String
  ) {
    onDeleteRoleManagement(filter: $filter, owner: $owner) {
      pk
      id
      menuId
      roleName
      module {
        moduleId
        menu
        view
        add
        edit
        delete
        __typename
      }
      status
      searchKey
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const onCreateContent = /* GraphQL */ `
  subscription OnCreateContent(
    $filter: ModelSubscriptionContentFilterInput
    $owner: String
  ) {
    onCreateContent(filter: $filter, owner: $owner) {
      pk
      id
      contentName
      title
      sequenceNumber
      content
      searchKey
      docUrl
      visibleToOthers
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateContent = /* GraphQL */ `
  subscription OnUpdateContent(
    $filter: ModelSubscriptionContentFilterInput
    $owner: String
  ) {
    onUpdateContent(filter: $filter, owner: $owner) {
      pk
      id
      contentName
      title
      sequenceNumber
      content
      searchKey
      docUrl
      visibleToOthers
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteContent = /* GraphQL */ `
  subscription OnDeleteContent(
    $filter: ModelSubscriptionContentFilterInput
    $owner: String
  ) {
    onDeleteContent(filter: $filter, owner: $owner) {
      pk
      id
      contentName
      title
      sequenceNumber
      content
      searchKey
      docUrl
      visibleToOthers
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateFAQ = /* GraphQL */ `
  subscription OnCreateFAQ(
    $filter: ModelSubscriptionFAQFilterInput
    $owner: String
  ) {
    onCreateFAQ(filter: $filter, owner: $owner) {
      pk
      id
      type
      sequenceNumber
      name
      FAQSections {
        question
        answer
        __typename
      }
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateFAQ = /* GraphQL */ `
  subscription OnUpdateFAQ(
    $filter: ModelSubscriptionFAQFilterInput
    $owner: String
  ) {
    onUpdateFAQ(filter: $filter, owner: $owner) {
      pk
      id
      type
      sequenceNumber
      name
      FAQSections {
        question
        answer
        __typename
      }
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteFAQ = /* GraphQL */ `
  subscription OnDeleteFAQ(
    $filter: ModelSubscriptionFAQFilterInput
    $owner: String
  ) {
    onDeleteFAQ(filter: $filter, owner: $owner) {
      pk
      id
      type
      sequenceNumber
      name
      FAQSections {
        question
        answer
        __typename
      }
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateProductReview = /* GraphQL */ `
  subscription OnCreateProductReview(
    $filter: ModelSubscriptionProductReviewFilterInput
    $owner: String
  ) {
    onCreateProductReview(filter: $filter, owner: $owner) {
      pk
      id
      orderItemId
      productId
      productImage
      productName
      userId
      userName
      email
      overallRating
      title
      reviewComment
      removeAbusiveComment
      abusiveReportedBy {
        userId
        userName
        email
        phoneNumber
        reasons
        reportedDate
        __typename
      }
      attachments
      createdAt
      reply
      repliedOnDate
      orderedDate
      reviewedDate
      reviewStatus
      searchKey
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateProductReview = /* GraphQL */ `
  subscription OnUpdateProductReview(
    $filter: ModelSubscriptionProductReviewFilterInput
    $owner: String
  ) {
    onUpdateProductReview(filter: $filter, owner: $owner) {
      pk
      id
      orderItemId
      productId
      productImage
      productName
      userId
      userName
      email
      overallRating
      title
      reviewComment
      removeAbusiveComment
      abusiveReportedBy {
        userId
        userName
        email
        phoneNumber
        reasons
        reportedDate
        __typename
      }
      attachments
      createdAt
      reply
      repliedOnDate
      orderedDate
      reviewedDate
      reviewStatus
      searchKey
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteProductReview = /* GraphQL */ `
  subscription OnDeleteProductReview(
    $filter: ModelSubscriptionProductReviewFilterInput
    $owner: String
  ) {
    onDeleteProductReview(filter: $filter, owner: $owner) {
      pk
      id
      orderItemId
      productId
      productImage
      productName
      userId
      userName
      email
      overallRating
      title
      reviewComment
      removeAbusiveComment
      abusiveReportedBy {
        userId
        userName
        email
        phoneNumber
        reasons
        reportedDate
        __typename
      }
      attachments
      createdAt
      reply
      repliedOnDate
      orderedDate
      reviewedDate
      reviewStatus
      searchKey
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateTokenDetails = /* GraphQL */ `
  subscription OnCreateTokenDetails(
    $filter: ModelSubscriptionTokenDetailsFilterInput
    $owner: String
  ) {
    onCreateTokenDetails(filter: $filter, owner: $owner) {
      pk
      id
      expiresIn
      token
      tokenType
      userID
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateTokenDetails = /* GraphQL */ `
  subscription OnUpdateTokenDetails(
    $filter: ModelSubscriptionTokenDetailsFilterInput
    $owner: String
  ) {
    onUpdateTokenDetails(filter: $filter, owner: $owner) {
      pk
      id
      expiresIn
      token
      tokenType
      userID
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteTokenDetails = /* GraphQL */ `
  subscription OnDeleteTokenDetails(
    $filter: ModelSubscriptionTokenDetailsFilterInput
    $owner: String
  ) {
    onDeleteTokenDetails(filter: $filter, owner: $owner) {
      pk
      id
      expiresIn
      token
      tokenType
      userID
      loginAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateTax = /* GraphQL */ `
  subscription OnCreateTax(
    $filter: ModelSubscriptionTaxFilterInput
    $owner: String
  ) {
    onCreateTax(filter: $filter, owner: $owner) {
      pk
      id
      taxCode
      taxType
      status
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateTax = /* GraphQL */ `
  subscription OnUpdateTax(
    $filter: ModelSubscriptionTaxFilterInput
    $owner: String
  ) {
    onUpdateTax(filter: $filter, owner: $owner) {
      pk
      id
      taxCode
      taxType
      status
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteTax = /* GraphQL */ `
  subscription OnDeleteTax(
    $filter: ModelSubscriptionTaxFilterInput
    $owner: String
  ) {
    onDeleteTax(filter: $filter, owner: $owner) {
      pk
      id
      taxCode
      taxType
      status
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateCarouselImage = /* GraphQL */ `
  subscription OnCreateCarouselImage(
    $filter: ModelSubscriptionCarouselImageFilterInput
    $owner: String
  ) {
    onCreateCarouselImage(filter: $filter, owner: $owner) {
      pk
      id
      type
      deviceType
      animation
      pauseTime
      transitionSpeed
      stopAnimation
      displayArrows
      displayNavigationBullets
      image {
        alterText
        title
        url
        description
        type
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCarouselImage = /* GraphQL */ `
  subscription OnUpdateCarouselImage(
    $filter: ModelSubscriptionCarouselImageFilterInput
    $owner: String
  ) {
    onUpdateCarouselImage(filter: $filter, owner: $owner) {
      pk
      id
      type
      deviceType
      animation
      pauseTime
      transitionSpeed
      stopAnimation
      displayArrows
      displayNavigationBullets
      image {
        alterText
        title
        url
        description
        type
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCarouselImage = /* GraphQL */ `
  subscription OnDeleteCarouselImage(
    $filter: ModelSubscriptionCarouselImageFilterInput
    $owner: String
  ) {
    onDeleteCarouselImage(filter: $filter, owner: $owner) {
      pk
      id
      type
      deviceType
      animation
      pauseTime
      transitionSpeed
      stopAnimation
      displayArrows
      displayNavigationBullets
      image {
        alterText
        title
        url
        description
        type
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateWishList = /* GraphQL */ `
  subscription OnCreateWishList(
    $filter: ModelSubscriptionWishListFilterInput
    $owner: String
  ) {
    onCreateWishList(filter: $filter, owner: $owner) {
      pk
      id
      userId
      productId
      productName
      productImage
      mainCategory
      mainCategoryId
      subCategory
      subCategoryId
      brandName
      brandId
      model
      rating
      regularPrice
      salePrice
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateWishList = /* GraphQL */ `
  subscription OnUpdateWishList(
    $filter: ModelSubscriptionWishListFilterInput
    $owner: String
  ) {
    onUpdateWishList(filter: $filter, owner: $owner) {
      pk
      id
      userId
      productId
      productName
      productImage
      mainCategory
      mainCategoryId
      subCategory
      subCategoryId
      brandName
      brandId
      model
      rating
      regularPrice
      salePrice
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteWishList = /* GraphQL */ `
  subscription OnDeleteWishList(
    $filter: ModelSubscriptionWishListFilterInput
    $owner: String
  ) {
    onDeleteWishList(filter: $filter, owner: $owner) {
      pk
      id
      userId
      productId
      productName
      productImage
      mainCategory
      mainCategoryId
      subCategory
      subCategoryId
      brandName
      brandId
      model
      rating
      regularPrice
      salePrice
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateConfiguration = /* GraphQL */ `
  subscription OnCreateConfiguration(
    $filter: ModelSubscriptionConfigurationFilterInput
    $owner: String
  ) {
    onCreateConfiguration(filter: $filter, owner: $owner) {
      pk
      id
      configurationDescription
      continuousSettings {
        prefixValue
        numberOfDigits
        includeYearSuffix
        startingNumber
        runningNumber
        __typename
      }
      type
      modifiedTime
      modifiedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateConfiguration = /* GraphQL */ `
  subscription OnUpdateConfiguration(
    $filter: ModelSubscriptionConfigurationFilterInput
    $owner: String
  ) {
    onUpdateConfiguration(filter: $filter, owner: $owner) {
      pk
      id
      configurationDescription
      continuousSettings {
        prefixValue
        numberOfDigits
        includeYearSuffix
        startingNumber
        runningNumber
        __typename
      }
      type
      modifiedTime
      modifiedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteConfiguration = /* GraphQL */ `
  subscription OnDeleteConfiguration(
    $filter: ModelSubscriptionConfigurationFilterInput
    $owner: String
  ) {
    onDeleteConfiguration(filter: $filter, owner: $owner) {
      pk
      id
      configurationDescription
      continuousSettings {
        prefixValue
        numberOfDigits
        includeYearSuffix
        startingNumber
        runningNumber
        __typename
      }
      type
      modifiedTime
      modifiedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateMailLog = /* GraphQL */ `
  subscription OnCreateMailLog(
    $filter: ModelSubscriptionMailLogFilterInput
    $owner: String
  ) {
    onCreateMailLog(filter: $filter, owner: $owner) {
      pk
      id
      referenceId
      referenceForm
      userId
      mailDetails {
        sentTo
        sentDate
        __typename
      }
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const onUpdateMailLog = /* GraphQL */ `
  subscription OnUpdateMailLog(
    $filter: ModelSubscriptionMailLogFilterInput
    $owner: String
  ) {
    onUpdateMailLog(filter: $filter, owner: $owner) {
      pk
      id
      referenceId
      referenceForm
      userId
      mailDetails {
        sentTo
        sentDate
        __typename
      }
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const onDeleteMailLog = /* GraphQL */ `
  subscription OnDeleteMailLog(
    $filter: ModelSubscriptionMailLogFilterInput
    $owner: String
  ) {
    onDeleteMailLog(filter: $filter, owner: $owner) {
      pk
      id
      referenceId
      referenceForm
      userId
      mailDetails {
        sentTo
        sentDate
        __typename
      }
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const onCreateDeliveryPartner = /* GraphQL */ `
  subscription OnCreateDeliveryPartner(
    $filter: ModelSubscriptionDeliveryPartnerFilterInput
    $owner: String
  ) {
    onCreateDeliveryPartner(filter: $filter, owner: $owner) {
      pk
      id
      logo
      logoUrl
      companyName
      displayName
      apiKey
      apiKeyExpiryDate
      contactPerson
      address
      email
      phoneNumber
      status
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateDeliveryPartner = /* GraphQL */ `
  subscription OnUpdateDeliveryPartner(
    $filter: ModelSubscriptionDeliveryPartnerFilterInput
    $owner: String
  ) {
    onUpdateDeliveryPartner(filter: $filter, owner: $owner) {
      pk
      id
      logo
      logoUrl
      companyName
      displayName
      apiKey
      apiKeyExpiryDate
      contactPerson
      address
      email
      phoneNumber
      status
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteDeliveryPartner = /* GraphQL */ `
  subscription OnDeleteDeliveryPartner(
    $filter: ModelSubscriptionDeliveryPartnerFilterInput
    $owner: String
  ) {
    onDeleteDeliveryPartner(filter: $filter, owner: $owner) {
      pk
      id
      logo
      logoUrl
      companyName
      displayName
      apiKey
      apiKeyExpiryDate
      contactPerson
      address
      email
      phoneNumber
      status
      searchKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateLedger = /* GraphQL */ `
  subscription OnCreateLedger(
    $filter: ModelSubscriptionLedgerFilterInput
    $owner: String
  ) {
    onCreateLedger(filter: $filter, owner: $owner) {
      pk
      id
      type
      transDate
      transReference
      transDetails
      transTo
      transToId
      transAmount
      transType
      paymentStatus
      buyerName
      orderRef
      orderAmount
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLedger = /* GraphQL */ `
  subscription OnUpdateLedger(
    $filter: ModelSubscriptionLedgerFilterInput
    $owner: String
  ) {
    onUpdateLedger(filter: $filter, owner: $owner) {
      pk
      id
      type
      transDate
      transReference
      transDetails
      transTo
      transToId
      transAmount
      transType
      paymentStatus
      buyerName
      orderRef
      orderAmount
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLedger = /* GraphQL */ `
  subscription OnDeleteLedger(
    $filter: ModelSubscriptionLedgerFilterInput
    $owner: String
  ) {
    onDeleteLedger(filter: $filter, owner: $owner) {
      pk
      id
      type
      transDate
      transReference
      transDetails
      transTo
      transToId
      transAmount
      transType
      paymentStatus
      buyerName
      orderRef
      orderAmount
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateProductVisit = /* GraphQL */ `
  subscription OnCreateProductVisit(
    $filter: ModelSubscriptionProductVisitFilterInput
    $owner: String
  ) {
    onCreateProductVisit(filter: $filter, owner: $owner) {
      pk
      id
      prodId
      userId
      userName
      visitedAt
      place
      visitedInfo {
        platform
        location
        time
        ipAddress
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateProductVisit = /* GraphQL */ `
  subscription OnUpdateProductVisit(
    $filter: ModelSubscriptionProductVisitFilterInput
    $owner: String
  ) {
    onUpdateProductVisit(filter: $filter, owner: $owner) {
      pk
      id
      prodId
      userId
      userName
      visitedAt
      place
      visitedInfo {
        platform
        location
        time
        ipAddress
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteProductVisit = /* GraphQL */ `
  subscription OnDeleteProductVisit(
    $filter: ModelSubscriptionProductVisitFilterInput
    $owner: String
  ) {
    onDeleteProductVisit(filter: $filter, owner: $owner) {
      pk
      id
      prodId
      userId
      userName
      visitedAt
      place
      visitedInfo {
        platform
        location
        time
        ipAddress
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreatePromoCodes = /* GraphQL */ `
  subscription OnCreatePromoCodes(
    $filter: ModelSubscriptionPromoCodesFilterInput
    $owner: String
  ) {
    onCreatePromoCodes(filter: $filter, owner: $owner) {
      pk
      id
      promoImage
      promoURL
      code
      discount {
        type
        value
        __typename
      }
      redemptionType
      searchKey
      validFrom
      validTo
      description
      terms
      advancedSetting {
        appliedTo
        specificType {
          name
          id
          __typename
        }
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdatePromoCodes = /* GraphQL */ `
  subscription OnUpdatePromoCodes(
    $filter: ModelSubscriptionPromoCodesFilterInput
    $owner: String
  ) {
    onUpdatePromoCodes(filter: $filter, owner: $owner) {
      pk
      id
      promoImage
      promoURL
      code
      discount {
        type
        value
        __typename
      }
      redemptionType
      searchKey
      validFrom
      validTo
      description
      terms
      advancedSetting {
        appliedTo
        specificType {
          name
          id
          __typename
        }
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeletePromoCodes = /* GraphQL */ `
  subscription OnDeletePromoCodes(
    $filter: ModelSubscriptionPromoCodesFilterInput
    $owner: String
  ) {
    onDeletePromoCodes(filter: $filter, owner: $owner) {
      pk
      id
      promoImage
      promoURL
      code
      discount {
        type
        value
        __typename
      }
      redemptionType
      searchKey
      validFrom
      validTo
      description
      terms
      advancedSetting {
        appliedTo
        specificType {
          name
          id
          __typename
        }
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateJwtTokenIdentifier = /* GraphQL */ `
  subscription OnCreateJwtTokenIdentifier(
    $filter: ModelSubscriptionJwtTokenIdentifierFilterInput
    $owner: String
  ) {
    onCreateJwtTokenIdentifier(filter: $filter, owner: $owner) {
      pk
      jtiValue
      issueDate
      expiryDateTime
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateJwtTokenIdentifier = /* GraphQL */ `
  subscription OnUpdateJwtTokenIdentifier(
    $filter: ModelSubscriptionJwtTokenIdentifierFilterInput
    $owner: String
  ) {
    onUpdateJwtTokenIdentifier(filter: $filter, owner: $owner) {
      pk
      jtiValue
      issueDate
      expiryDateTime
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteJwtTokenIdentifier = /* GraphQL */ `
  subscription OnDeleteJwtTokenIdentifier(
    $filter: ModelSubscriptionJwtTokenIdentifierFilterInput
    $owner: String
  ) {
    onDeleteJwtTokenIdentifier(filter: $filter, owner: $owner) {
      pk
      jtiValue
      issueDate
      expiryDateTime
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateUnits = /* GraphQL */ `
  subscription OnCreateUnits(
    $filter: ModelSubscriptionUnitsFilterInput
    $owner: String
  ) {
    onCreateUnits(filter: $filter, owner: $owner) {
      pk
      id
      value
      sequenceNumber
      searchKey
      type
      status
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUnits = /* GraphQL */ `
  subscription OnUpdateUnits(
    $filter: ModelSubscriptionUnitsFilterInput
    $owner: String
  ) {
    onUpdateUnits(filter: $filter, owner: $owner) {
      pk
      id
      value
      sequenceNumber
      searchKey
      type
      status
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUnits = /* GraphQL */ `
  subscription OnDeleteUnits(
    $filter: ModelSubscriptionUnitsFilterInput
    $owner: String
  ) {
    onDeleteUnits(filter: $filter, owner: $owner) {
      pk
      id
      value
      sequenceNumber
      searchKey
      type
      status
      createdBy
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateShipping = /* GraphQL */ `
  subscription OnCreateShipping(
    $filter: ModelSubscriptionShippingFilterInput
    $owner: String
  ) {
    onCreateShipping(filter: $filter, owner: $owner) {
      id
      pk
      city
      countryId
      countryName
      state
      deliveryCharge
      coverage
      zipCodes
      latitude
      longitude
      radius
      searchKey
      status
      createdAt
      updatedAt
      createdBy
      modifiedBy
      owner
      __typename
    }
  }
`;
export const onUpdateShipping = /* GraphQL */ `
  subscription OnUpdateShipping(
    $filter: ModelSubscriptionShippingFilterInput
    $owner: String
  ) {
    onUpdateShipping(filter: $filter, owner: $owner) {
      id
      pk
      city
      countryId
      countryName
      state
      deliveryCharge
      coverage
      zipCodes
      latitude
      longitude
      radius
      searchKey
      status
      createdAt
      updatedAt
      createdBy
      modifiedBy
      owner
      __typename
    }
  }
`;
export const onDeleteShipping = /* GraphQL */ `
  subscription OnDeleteShipping(
    $filter: ModelSubscriptionShippingFilterInput
    $owner: String
  ) {
    onDeleteShipping(filter: $filter, owner: $owner) {
      id
      pk
      city
      countryId
      countryName
      state
      deliveryCharge
      coverage
      zipCodes
      latitude
      longitude
      radius
      searchKey
      status
      createdAt
      updatedAt
      createdBy
      modifiedBy
      owner
      __typename
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct(
    $filter: ModelSubscriptionProductFilterInput
    $owner: String
  ) {
    onCreateProduct(filter: $filter, owner: $owner) {
      createdAt
      id
      pk
      globalPk
      productName
      mainCategory
      mainCategoryID
      subCategory
      subCategoryID
      brandName
      brandID
      countryName
      countryID
      productDescription
      productFeatures
      keyWord
      featuredImage {
        alterText
        title
        url
        description
        type
        __typename
      }
      galleryImages {
        alterText
        title
        url
        description
        type
        __typename
      }
      singleVariant
      attrName
      attrNameID
      attrValue {
        value
        attrValueID
        __typename
      }
      productSpecification {
        name
        value
        __typename
      }
      seoDescription
      stockStatus
      searchKey
      status
      scheduleDate
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      showWhenZeroStock
      allowPreOrder
      overallRating
      noOfRating
      leadTime
      totalStockQuantity
      totalReservedStock
      displaySalePrice
      displayRegularPrice
      thumbnailUrl
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct(
    $filter: ModelSubscriptionProductFilterInput
    $owner: String
  ) {
    onUpdateProduct(filter: $filter, owner: $owner) {
      createdAt
      id
      pk
      globalPk
      productName
      mainCategory
      mainCategoryID
      subCategory
      subCategoryID
      brandName
      brandID
      countryName
      countryID
      productDescription
      productFeatures
      keyWord
      featuredImage {
        alterText
        title
        url
        description
        type
        __typename
      }
      galleryImages {
        alterText
        title
        url
        description
        type
        __typename
      }
      singleVariant
      attrName
      attrNameID
      attrValue {
        value
        attrValueID
        __typename
      }
      productSpecification {
        name
        value
        __typename
      }
      seoDescription
      stockStatus
      searchKey
      status
      scheduleDate
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      showWhenZeroStock
      allowPreOrder
      overallRating
      noOfRating
      leadTime
      totalStockQuantity
      totalReservedStock
      displaySalePrice
      displayRegularPrice
      thumbnailUrl
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct(
    $filter: ModelSubscriptionProductFilterInput
    $owner: String
  ) {
    onDeleteProduct(filter: $filter, owner: $owner) {
      createdAt
      id
      pk
      globalPk
      productName
      mainCategory
      mainCategoryID
      subCategory
      subCategoryID
      brandName
      brandID
      countryName
      countryID
      productDescription
      productFeatures
      keyWord
      featuredImage {
        alterText
        title
        url
        description
        type
        __typename
      }
      galleryImages {
        alterText
        title
        url
        description
        type
        __typename
      }
      singleVariant
      attrName
      attrNameID
      attrValue {
        value
        attrValueID
        __typename
      }
      productSpecification {
        name
        value
        __typename
      }
      seoDescription
      stockStatus
      searchKey
      status
      scheduleDate
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      showWhenZeroStock
      allowPreOrder
      overallRating
      noOfRating
      leadTime
      totalStockQuantity
      totalReservedStock
      displaySalePrice
      displayRegularPrice
      thumbnailUrl
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateProductImage = /* GraphQL */ `
  subscription OnCreateProductImage(
    $filter: ModelSubscriptionProductImageFilterInput
    $owner: String
  ) {
    onCreateProductImage(filter: $filter, owner: $owner) {
      pk
      id
      alterText
      title
      url
      description
      mainCategory
      mainCategoryID
      subCategory
      subCategoryID
      thumbnailUrl
      type
      productID
      isDeleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateProductImage = /* GraphQL */ `
  subscription OnUpdateProductImage(
    $filter: ModelSubscriptionProductImageFilterInput
    $owner: String
  ) {
    onUpdateProductImage(filter: $filter, owner: $owner) {
      pk
      id
      alterText
      title
      url
      description
      mainCategory
      mainCategoryID
      subCategory
      subCategoryID
      thumbnailUrl
      type
      productID
      isDeleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteProductImage = /* GraphQL */ `
  subscription OnDeleteProductImage(
    $filter: ModelSubscriptionProductImageFilterInput
    $owner: String
  ) {
    onDeleteProductImage(filter: $filter, owner: $owner) {
      pk
      id
      alterText
      title
      url
      description
      mainCategory
      mainCategoryID
      subCategory
      subCategoryID
      thumbnailUrl
      type
      productID
      isDeleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateProductDetails = /* GraphQL */ `
  subscription OnCreateProductDetails(
    $filter: ModelSubscriptionProductDetailsFilterInput
    $owner: String
  ) {
    onCreateProductDetails(filter: $filter, owner: $owner) {
      id
      pk
      productID
      attrName
      attrNameID
      attrValue
      attrValueID
      featuredImage {
        alterText
        title
        url
        description
        type
        __typename
      }
      model
      manufacturePartCode
      sku
      stockStatus
      stockQuantity
      reservedStock
      stockUnit
      itemsPerUnit
      regularPrice
      salePrice
      dataSheet
      submittal
      catalog
      iom
      storageInfo {
        wareHouseId
        wareHouse
        rackNo
        rowNo
        binNo
        note
        __typename
      }
      shipplingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      partialReturn
      type
      isDeleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateProductDetails = /* GraphQL */ `
  subscription OnUpdateProductDetails(
    $filter: ModelSubscriptionProductDetailsFilterInput
    $owner: String
  ) {
    onUpdateProductDetails(filter: $filter, owner: $owner) {
      id
      pk
      productID
      attrName
      attrNameID
      attrValue
      attrValueID
      featuredImage {
        alterText
        title
        url
        description
        type
        __typename
      }
      model
      manufacturePartCode
      sku
      stockStatus
      stockQuantity
      reservedStock
      stockUnit
      itemsPerUnit
      regularPrice
      salePrice
      dataSheet
      submittal
      catalog
      iom
      storageInfo {
        wareHouseId
        wareHouse
        rackNo
        rowNo
        binNo
        note
        __typename
      }
      shipplingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      partialReturn
      type
      isDeleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteProductDetails = /* GraphQL */ `
  subscription OnDeleteProductDetails(
    $filter: ModelSubscriptionProductDetailsFilterInput
    $owner: String
  ) {
    onDeleteProductDetails(filter: $filter, owner: $owner) {
      id
      pk
      productID
      attrName
      attrNameID
      attrValue
      attrValueID
      featuredImage {
        alterText
        title
        url
        description
        type
        __typename
      }
      model
      manufacturePartCode
      sku
      stockStatus
      stockQuantity
      reservedStock
      stockUnit
      itemsPerUnit
      regularPrice
      salePrice
      dataSheet
      submittal
      catalog
      iom
      storageInfo {
        wareHouseId
        wareHouse
        rackNo
        rowNo
        binNo
        note
        __typename
      }
      shipplingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      partialReturn
      type
      isDeleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder(
    $filter: ModelSubscriptionOrderFilterInput
    $owner: String
  ) {
    onCreateOrder(filter: $filter, owner: $owner) {
      pk
      id
      userId
      createdAt
      userName
      email
      phoneNumber
      orderType
      totalProducts
      orderSummary {
        cartTotal
        productDiscount
        deliveryFee
        tax
        __typename
      }
      totalAmount
      paymentDetails {
        paymentMode
        paymentMethod
        cardType
        cardHolderName
        cardNumber
        CardExpiryDate
        UPI
        paymentDate
        referenceNumber
        chequeNumber
        bankName
        chequeDate
        receivedAmount
        remaningAmount
        chequeStatus
        partialPaymentDetails {
          chequeNumber
          bankName
          chequeDate
          receivedAmount
          chequeStatus
          paymentDate
          updatedAt
          updatedBy
          __typename
        }
        __typename
      }
      paymentStatus
      status
      wishListId
      couponDetails {
        couponCode
        amount
        discount {
          type
          value
          __typename
        }
        advancedSetting {
          appliedTo
          specificType {
            name
            id
            __typename
          }
          __typename
        }
        __typename
      }
      discountByCoupon
      shippingUserDetails {
        userName
        phoneNumber
        email
        __typename
      }
      shippingAddress {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      deliveryInfo {
        deliveryPartner
        deliveryType
        productWeight
        __typename
      }
      billingUserDetails {
        userName
        phoneNumber
        email
        __typename
      }
      billingAddress {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      deliveryDate
      invoiceNumber
      invoiceURL
      orderPdf
      searchKey
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder(
    $filter: ModelSubscriptionOrderFilterInput
    $owner: String
  ) {
    onUpdateOrder(filter: $filter, owner: $owner) {
      pk
      id
      userId
      createdAt
      userName
      email
      phoneNumber
      orderType
      totalProducts
      orderSummary {
        cartTotal
        productDiscount
        deliveryFee
        tax
        __typename
      }
      totalAmount
      paymentDetails {
        paymentMode
        paymentMethod
        cardType
        cardHolderName
        cardNumber
        CardExpiryDate
        UPI
        paymentDate
        referenceNumber
        chequeNumber
        bankName
        chequeDate
        receivedAmount
        remaningAmount
        chequeStatus
        partialPaymentDetails {
          chequeNumber
          bankName
          chequeDate
          receivedAmount
          chequeStatus
          paymentDate
          updatedAt
          updatedBy
          __typename
        }
        __typename
      }
      paymentStatus
      status
      wishListId
      couponDetails {
        couponCode
        amount
        discount {
          type
          value
          __typename
        }
        advancedSetting {
          appliedTo
          specificType {
            name
            id
            __typename
          }
          __typename
        }
        __typename
      }
      discountByCoupon
      shippingUserDetails {
        userName
        phoneNumber
        email
        __typename
      }
      shippingAddress {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      deliveryInfo {
        deliveryPartner
        deliveryType
        productWeight
        __typename
      }
      billingUserDetails {
        userName
        phoneNumber
        email
        __typename
      }
      billingAddress {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      deliveryDate
      invoiceNumber
      invoiceURL
      orderPdf
      searchKey
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder(
    $filter: ModelSubscriptionOrderFilterInput
    $owner: String
  ) {
    onDeleteOrder(filter: $filter, owner: $owner) {
      pk
      id
      userId
      createdAt
      userName
      email
      phoneNumber
      orderType
      totalProducts
      orderSummary {
        cartTotal
        productDiscount
        deliveryFee
        tax
        __typename
      }
      totalAmount
      paymentDetails {
        paymentMode
        paymentMethod
        cardType
        cardHolderName
        cardNumber
        CardExpiryDate
        UPI
        paymentDate
        referenceNumber
        chequeNumber
        bankName
        chequeDate
        receivedAmount
        remaningAmount
        chequeStatus
        partialPaymentDetails {
          chequeNumber
          bankName
          chequeDate
          receivedAmount
          chequeStatus
          paymentDate
          updatedAt
          updatedBy
          __typename
        }
        __typename
      }
      paymentStatus
      status
      wishListId
      couponDetails {
        couponCode
        amount
        discount {
          type
          value
          __typename
        }
        advancedSetting {
          appliedTo
          specificType {
            name
            id
            __typename
          }
          __typename
        }
        __typename
      }
      discountByCoupon
      shippingUserDetails {
        userName
        phoneNumber
        email
        __typename
      }
      shippingAddress {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      deliveryInfo {
        deliveryPartner
        deliveryType
        productWeight
        __typename
      }
      billingUserDetails {
        userName
        phoneNumber
        email
        __typename
      }
      billingAddress {
        addressLine1
        addressLine2
        street
        landmark
        state
        city
        postalCode
        country
        __typename
      }
      deliveryDate
      invoiceNumber
      invoiceURL
      orderPdf
      searchKey
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateOrderItem = /* GraphQL */ `
  subscription OnCreateOrderItem(
    $filter: ModelSubscriptionOrderItemFilterInput
    $owner: String
  ) {
    onCreateOrderItem(filter: $filter, owner: $owner) {
      pk
      id
      productId
      mainCategoryId
      mainCategory
      subCategoryId
      subCategory
      userId
      wareHouseId
      attrValueID
      wareHouseName
      userName
      email
      phoneNumber
      createdAt
      quantity
      stockUnit
      itemsPerUnit
      taxCode
      taxAmount
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      regularPrice
      totalPrice
      itemDetail {
        productName
        price
        image
        brand
        model
        tax
        currentStock
        sku
        qrcode
        __typename
      }
      sku
      attrName
      attrValue
      deliveryStatus
      deliveryBy
      deliveryTracking {
        status
        date
        trackingBy
        phoneNumber
        remarks
        receipt
        __typename
      }
      deliveryPartner
      outForDeliveryDate
      outForDeliveryTime
      expectedDeliveryDate
      trackingID
      trackingUrl
      deliveryPersonInfo {
        name
        contactNumber
        vehiclenumber
        notes
        __typename
      }
      orderedOn
      returnRefundActivity {
        returnReference
        reason
        comment
        quantity
        subTotal
        total
        attachments
        paymentMode
        paymentMethod
        returnType
        returnAddress {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        pickUpDetails {
          pickUpAddress {
            addressLine1
            addressLine2
            street
            landmark
            state
            city
            postalCode
            country
            __typename
          }
          pickUpBy
          deliveryPartner
          name
          phoneNumber
          expectedPickUpDate
          time
          __typename
        }
        receivedInfo {
          receivedOn
          receivedAs
          images
          __typename
        }
        updateInStock
        updateInStockQty
        refundInfo {
          date
          paymentMethod
          refundAmount
          refundInfo
          proofImage
          proofImageUrl
          rejectedReason
          __typename
        }
        status
        returnStatusUpdate {
          returnStatus
          updatedAt
          updatedBy
          __typename
        }
        rejectReason
        createdAt
        __typename
      }
      eta
      paymentStatus
      billOfLading
      packingList
      searchKey
      shippingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      returnAvailable
      disputeResolved
      brandId
      returnValue
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateOrderItem = /* GraphQL */ `
  subscription OnUpdateOrderItem(
    $filter: ModelSubscriptionOrderItemFilterInput
    $owner: String
  ) {
    onUpdateOrderItem(filter: $filter, owner: $owner) {
      pk
      id
      productId
      mainCategoryId
      mainCategory
      subCategoryId
      subCategory
      userId
      wareHouseId
      attrValueID
      wareHouseName
      userName
      email
      phoneNumber
      createdAt
      quantity
      stockUnit
      itemsPerUnit
      taxCode
      taxAmount
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      regularPrice
      totalPrice
      itemDetail {
        productName
        price
        image
        brand
        model
        tax
        currentStock
        sku
        qrcode
        __typename
      }
      sku
      attrName
      attrValue
      deliveryStatus
      deliveryBy
      deliveryTracking {
        status
        date
        trackingBy
        phoneNumber
        remarks
        receipt
        __typename
      }
      deliveryPartner
      outForDeliveryDate
      outForDeliveryTime
      expectedDeliveryDate
      trackingID
      trackingUrl
      deliveryPersonInfo {
        name
        contactNumber
        vehiclenumber
        notes
        __typename
      }
      orderedOn
      returnRefundActivity {
        returnReference
        reason
        comment
        quantity
        subTotal
        total
        attachments
        paymentMode
        paymentMethod
        returnType
        returnAddress {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        pickUpDetails {
          pickUpAddress {
            addressLine1
            addressLine2
            street
            landmark
            state
            city
            postalCode
            country
            __typename
          }
          pickUpBy
          deliveryPartner
          name
          phoneNumber
          expectedPickUpDate
          time
          __typename
        }
        receivedInfo {
          receivedOn
          receivedAs
          images
          __typename
        }
        updateInStock
        updateInStockQty
        refundInfo {
          date
          paymentMethod
          refundAmount
          refundInfo
          proofImage
          proofImageUrl
          rejectedReason
          __typename
        }
        status
        returnStatusUpdate {
          returnStatus
          updatedAt
          updatedBy
          __typename
        }
        rejectReason
        createdAt
        __typename
      }
      eta
      paymentStatus
      billOfLading
      packingList
      searchKey
      shippingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      returnAvailable
      disputeResolved
      brandId
      returnValue
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteOrderItem = /* GraphQL */ `
  subscription OnDeleteOrderItem(
    $filter: ModelSubscriptionOrderItemFilterInput
    $owner: String
  ) {
    onDeleteOrderItem(filter: $filter, owner: $owner) {
      pk
      id
      productId
      mainCategoryId
      mainCategory
      subCategoryId
      subCategory
      userId
      wareHouseId
      attrValueID
      wareHouseName
      userName
      email
      phoneNumber
      createdAt
      quantity
      stockUnit
      itemsPerUnit
      taxCode
      taxAmount
      taxDetail {
        taxCharge
        taxID
        taxName
        __typename
      }
      regularPrice
      totalPrice
      itemDetail {
        productName
        price
        image
        brand
        model
        tax
        currentStock
        sku
        qrcode
        __typename
      }
      sku
      attrName
      attrValue
      deliveryStatus
      deliveryBy
      deliveryTracking {
        status
        date
        trackingBy
        phoneNumber
        remarks
        receipt
        __typename
      }
      deliveryPartner
      outForDeliveryDate
      outForDeliveryTime
      expectedDeliveryDate
      trackingID
      trackingUrl
      deliveryPersonInfo {
        name
        contactNumber
        vehiclenumber
        notes
        __typename
      }
      orderedOn
      returnRefundActivity {
        returnReference
        reason
        comment
        quantity
        subTotal
        total
        attachments
        paymentMode
        paymentMethod
        returnType
        returnAddress {
          addressLine1
          addressLine2
          street
          landmark
          state
          city
          postalCode
          country
          __typename
        }
        pickUpDetails {
          pickUpAddress {
            addressLine1
            addressLine2
            street
            landmark
            state
            city
            postalCode
            country
            __typename
          }
          pickUpBy
          deliveryPartner
          name
          phoneNumber
          expectedPickUpDate
          time
          __typename
        }
        receivedInfo {
          receivedOn
          receivedAs
          images
          __typename
        }
        updateInStock
        updateInStockQty
        refundInfo {
          date
          paymentMethod
          refundAmount
          refundInfo
          proofImage
          proofImageUrl
          rejectedReason
          __typename
        }
        status
        returnStatusUpdate {
          returnStatus
          updatedAt
          updatedBy
          __typename
        }
        rejectReason
        createdAt
        __typename
      }
      eta
      paymentStatus
      billOfLading
      packingList
      searchKey
      shippingInfo {
        weightValue
        weightUnit
        lengthValue
        lengthUnit
        heightValue
        heightUnit
        widthValue
        widthUnit
        __typename
      }
      returnAvailable
      disputeResolved
      brandId
      returnValue
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateReportMaster = /* GraphQL */ `
  subscription OnCreateReportMaster(
    $filter: ModelSubscriptionReportMasterFilterInput
    $owner: String
  ) {
    onCreateReportMaster(filter: $filter, owner: $owner) {
      pk
      id
      reportName
      description
      sequenceNumber
      searchKey
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateReportMaster = /* GraphQL */ `
  subscription OnUpdateReportMaster(
    $filter: ModelSubscriptionReportMasterFilterInput
    $owner: String
  ) {
    onUpdateReportMaster(filter: $filter, owner: $owner) {
      pk
      id
      reportName
      description
      sequenceNumber
      searchKey
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteReportMaster = /* GraphQL */ `
  subscription OnDeleteReportMaster(
    $filter: ModelSubscriptionReportMasterFilterInput
    $owner: String
  ) {
    onDeleteReportMaster(filter: $filter, owner: $owner) {
      pk
      id
      reportName
      description
      sequenceNumber
      searchKey
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
