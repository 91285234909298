import React, { useEffect, useState } from "react";
import OtpInput from 'react-otp-input';

const MyOtpInput = ({ otp, setOtp }) => {
    return (
        <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderInput={(props) => <input {...props} />}
        />
    );
}
export default MyOtpInput;