import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import Footer from "../../../pages/footer";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { AccountFieldNameConstant, ValidateName } from "../../../constants/validateMsgConstant";
import ValidateField from "../../../utils/useValid";
import { CreateEntry } from "../../../providers/queryData";
import { showErrorToastr, showSuccessToastr } from "../../../common/toastrService";
import { resourceconstant } from "../../../constants";
import customLogger from "../../../common/loggerService";
import useProfile from "../../../hooks/useProfile";
import useLogoImage from "../../../hooks/useLogoImage";
const formValues = {
  newEmail: '',
}
const NewEmail = ({ setEmailNewChange, setEmailChange, emailNewOtpChange, emailNewChange, emailOtpChange, setEmailOtpChange, setEmailNewOtpChange }) => {
  const navigate = useNavigate();

  const [values, setValues] = useState(formValues)
  const [errors, setErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [newMail, setNewMail] = useState(null)
  const [userProfile, setUserProfile] = useProfile()
  const userid = userProfile?.attributes?.sub;
  const [logoUrl, setLogoUrl] = useLogoImage();

  const HandleNewEmailChange = (e) => {
    const { name, value } = e.target
    setErrors({
      ...errors,
      [name]: ""
    });
    setValues({
      ...values,
      [name]: value,
    });
  }

  const HandleBlur = (e) => {
    const { name, value } = e.target;
    const fieldErrors = validation({
      ...values,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: fieldErrors[name]
    });
  };

  const validation = (values) => {
    const errors = {};
    errors.newEmail = ValidateField(
      values.newEmail,
      AccountFieldNameConstant?.EMAIL,
      [ValidateName?.REQUIRED, ValidateName?.EMAIL],
    );

    let error = {}
    Object.keys(errors).map((key, index) => {
      if (errors[key] !== undefined)
        error[key] = errors[key]
    })
    return error
  }

  const ChangeNewEmail = (e) => {
    e.preventDefault();
    setErrors(validation(values));
    setIsSubmit(true);
  }

  useEffect(() => {
    const HandleEmail = async () => {
      try {
        const data = {
          user_id: userid,
          new_email: values?.newEmail
        };

        await CreateEntry(resourceconstant?.PROFILE_NEW_MAIL, true, setNewMail, data)
      } catch (error) {
        if (error.response) {
          customLogger.error('Response:', error.response.data);
        }
      }
    };
    if (Object.keys(errors).length === 0 && isSubmit) {
      HandleEmail();
    }
  }, [errors]);

  useEffect(() => {
    if (newMail?.status === true) {
      localStorage.setItem('profileNewEmail', values.newEmail)
      showSuccessToastr(newMail?.message)
      navigate('/new-email-otp')
    }
    else if(newMail?.status === false) {
      showErrorToastr(newMail?.message);
    }
  }, [newMail])

  return (
    <>
      <div className="login-page">
        <Card>
          <Row>
            <Col md={12}>
              <div className="login-sec">
                <div className="logo-sec">
                  <Link to="/change-email" style={{ color: "inherit" }}>
                    <div class="back-btn">
                      <i className="fa fa-chevron-left"></i>
                    </div>
                  </Link>
                </div>
                <div className="login-form" style={{ maxWidth: "650px" }}>
                  <form>
                    <Row>
                      <Col md={12} className="mt-3">
                        <h1>New Email?</h1>
                      </Col>
                      <Col sm={12} md={12}>
                        <p>
                          Update your email to ensure secure access and receive important notifications. Enter your new email for uninterrupted service.
                        </p>
                      </Col>
                      <Col sm={12} md={12}>
                        <div className="form-group mt-3">
                          <label>
                            New Email
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Enter"
                            name="newEmail"
                            id="newEmail"
                            onBlur={(e) => HandleBlur(e)}
                            onChange={(e) => HandleNewEmailChange(e)} />
                        </div>
                        {
                          errors?.newEmail && <p className="form-error">{errors?.newEmail}</p>
                        }
                      </Col>
                      <Col md={12}>
                        <button
                          className="btn submit-btn"
                          onClick={(e) => ChangeNewEmail(e)}>
                          Continue
                        </button>
                      </Col>
                      <Col md={12}>
                        <p className="text-center">
                          By continuing, you agree to {logoUrl?.brandName}’s{" "}
                          <Link
                            to="/terms-condition"
                            state={{ activeKey: "terms" }}
                            onClick={() => {
                              window.scroll(0, 0);
                            }}>
                            Conditions of Use
                          </Link>{" "}
                          and{" "}
                          <Link
                            to="/privacy-policy"
                            onClick={() => {
                              window.scroll(0, 0);
                            }}>
                            Privacy Policy
                          </Link>
                          .
                        </p>
                        <hr />
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
export default NewEmail;