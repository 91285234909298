import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container, Nav, Tab, Col } from 'react-bootstrap';
import Notificationwindow from './index';
import useNotification from '../../hooks/useNotification';
import useUserDetails from '../../hooks/useUserDetails';
function ViewAllNotification() {
  const [notifications, setNotifications] = useNotification();
  const {
    handleClearAllNotifications,
    getIcon,
    getToday,
    handleNotificationClick,
    handleDeleteNotification
  } = Notificationwindow();

  const [filteredNotifications, setFilteredNotifications] = useState(notifications);
  const [useuserDetails, setUseUserDetails] = useUserDetails()

  const renderNotificationItem = (notification, index) => {
    const todayDate = new Date().toLocaleDateString('en-GB');
    const isToday = notification.date === todayDate;
    return (
      <div key={notification.id}>
        <div className={`note-item ${notification.status === 1 ? 'active' : ''}`}>
          <div className="d-flex">
            <div className="note-icon on">
              {getIcon(notification)}
            </div>
            <div className='w-100'>
              <div className="description" style={{ cursor: "pointer" }}>
                <h2 className="title" onClick={() => handleNotificationClick(notification)}>{notification.title}</h2>
                <p className="date">{notification.time}</p>
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                <p className="message">{notification.message}</p>
                <a className="delete-icon" title="Delete" onClick={() => handleDeleteNotification(notification.id)}>
                  <i className='fa fa-trash'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  };

  const renderNotificationsByDate = (notifications) => {
    const groupedByDate = {};

    notifications?.forEach(notification => {
      const dateKey = notification?.date;
      if (!groupedByDate[dateKey]) {
        groupedByDate[dateKey] = [];
      }
      groupedByDate[dateKey]?.push(notification);
    });

    return Object.keys(groupedByDate)?.map((date, index) => (
      <div key={index}>
        <p className="day-date">{getToday(date)}</p>
        {groupedByDate[date].map((notification, idx) => renderNotificationItem(notification, idx))}
      </div>
    ));
  };

  const renderNotifications = (filterFn) => {
    const filteredByStatus = filterFn === 'read'
      ? notifications?.filter(notification => notification.status === 2)
      : filterFn === 'unread'
        ? notifications?.filter(notification => notification.status === 1)
        : notifications;

    return renderNotificationsByDate(filteredByStatus);
  };

  return (
    <section id="viewall" className='my-3'>
      <Container>
        <Card>
          <CardBody>
            <div className="viewall-notification">
              <div className='d-flex align-items-center justify-content-between'>
                <h2>Notifications</h2>
                {useuserDetails?.id && (
                <a href='#' className='clearall' onClick={handleClearAllNotifications}>Clear All</a>
                )}
              </div>
              <Tab.Container defaultActiveKey="all">
                <div className='tabs'>
                  <Nav>
                    <Nav.Item>
                      <Nav.Link eventKey="all">All <span className="badge">{notifications?.length}</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="read">Read <span className="badge">{notifications?.filter(notification => notification.status === 2).length}</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="unread">Unread <span className="badge">{notifications?.filter(notification => notification.status === 1).length}</span></Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                {notifications?.length > 0 ? (
                  notifications?.filter(notification => notification.status === 1).map((notification) => (
                    <div className='notify-block'>
                      <Tab.Content>
                        <Tab.Pane eventKey="all">
                          {renderNotifications('all')}
                        </Tab.Pane>
                        <Tab.Pane eventKey="read">
                          {renderNotifications('read')}
                        </Tab.Pane>
                        <Tab.Pane eventKey="unread">
                          {renderNotifications('unread')}
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  ))
                ) : (
                  <div className='no-results'>
                    <p>No New Notifications</p>
                  </div>
                )}
              </Tab.Container>
            </div>
          </CardBody>
        </Card>
      </Container>
    </section>
  );
}

export default ViewAllNotification;
