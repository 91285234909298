import { useContext, useEffect, useState} from 'react';
import { UserProfileContext } from "../context/UserProfileContext";
import Amplify, { Auth } from 'aws-amplify';

function useProfile(){
 
  const {userProfile, setUserProfile} = useContext(UserProfileContext);

  useEffect(()=>{
    const GetUserDetails = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        setUserProfile(currentUser);
      } catch (error) {
        setUserProfile({}); 
      }
    };
    GetUserDetails();
},[])

  return [ userProfile, setUserProfile ];
}

export default useProfile;