import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Stepper from "@keyvaluesystems/react-stepper";
import { formatDate,GetTimeFormat, DateFormatShow } from "../utils/validate"
import moment from "moment"
import { GetOrderStatus } from "../utils/generalMethod"
import { resourceconstant } from '../constants';
import { GetData } from '../providers/queryData';

export default function OrderUpdates({ orderDetail, modalShow, setModalShow, orderItem }) {
    const [updateStep, setUpdateStep] = useState([])
    const [wareHouse, setWareHouse] = useState([])
    const [listWareHouse, setListWareHouse] = useState([])

    const ListWareHouse = () => {
        let filter = {
            id: {
               eq: `${orderItem?.wareHouseId}`
            }
        }
        GetData(resourceconstant.LIST_WAREHOUSES, filter, setWareHouse)
    };

    useEffect(() => {
        if (wareHouse?.severity === "success" && orderItem) {
            setListWareHouse(wareHouse?.data?.data?.listWareHouses?.items)
        }
    }, [wareHouse, orderItem])

    useEffect(() => {
        if(orderItem?.wareHouseId){
            ListWareHouse()
        }
    }, [orderItem])

    useEffect(() => {
        if (orderItem) {
            let arrStep = []
            orderItem?.deliveryTracking?.map((item, index) => {
                arrStep.push({
                    "stepLabel": GetOrderStatusDate(item,orderItem),
                    "stepDescription": <span dangerouslySetInnerHTML={{ __html: GetOrderStatus(item, orderItem, orderDetail, listWareHouse?.[0]) }}></span>,
                    "completed": (item?.status === "Delivered" || item?.status === "Received" || orderItem?.deliveryTracking?.length > (index + 1)) ? true : false,
                })

            })
            setUpdateStep(arrStep)
        }
    }, [orderItem, listWareHouse])

    const GetOrderStatusDate = (item,orderItem) =>{
        if(item?.status === "OutForDelivery")
        return <p>{DateFormatShow(orderItem?.outForDeliveryDate,false)}<span>{moment(item?.date).format("hh:mm a")}</span></p>
        else
        return <p>{DateFormatShow(item?.date,false)}<span>{moment(item?.date).format("hh:mm a")}</span></p>
        
    }
    return (
        <div>
            {/* Viewall updates modal */}
            <Modal show={modalShow} onHide={() => setModalShow(false)} size="md" className='update-modal' aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton className='pb-1'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Track Order
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='order-updates'>
                        <p>Order # : <span>{orderItem?.pk?.replace("ORDERI#", "")}</span></p>
                        <p>Ordered On : <span>{DateFormatShow(orderItem?.createdAt,false)}</span></p>
                    </div>
                    <div className='order-status'>
                        <Stepper showDescriptionsForAllSteps={true}
                            steps={updateStep}
                            currentStepIndex={1}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}