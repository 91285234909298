import Pagination from 'react-bootstrap/Pagination';
import React from 'react';
import { toBeRequired } from '@testing-library/jest-dom/matchers';
function ListPagination({ currentPage, totalPages, onPageChange }) {

  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const paginationItems = [];
  let start = 1
  let temptotal = totalPages > 10 ? 10 : totalPages
  if (currentPage > 10) temptotal = currentPage
  if (currentPage > 10) start = currentPage - 9
  for (let i = start; i <= temptotal; i++) {
    paginationItems.push(
      <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
        {i}
      </Pagination.Item>
    );
  }

  return (
    <Pagination className='justify-content-center justify-content-lg-end'>
      <Pagination.First className='arrow' onClick={() => handlePageChange(1)} />
      {/* <Pagination.Prev
      className='prev'
      onClick={() => handlePageChange(currentPage - 1)}
      disabled={currentPage === 1}
    /> */}
      {paginationItems}
      <Pagination.Next
        className='next'
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
      <Pagination.Last
        className='arrow'
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
}

export default ListPagination;