import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { OrderConstant } from "../../constants"

function OrderShow({ setFilterShow, filterShow, filterForm, HandleChangeFilter, ApplyFilter, ClearFilter }) {
    const handleClose = () => setFilterShow(false);
    return (
        <Modal show={filterShow} onHide={handleClose} animation={false} className='filter-form'>
            <Modal.Header closeButton>
                <Modal.Title>Filters</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Order Date</Form.Label>
                        <Form.Control name="orderDate" aria-label="Date" type="date" value={filterForm?.orderDate} placeholder="Select" onChange={HandleChangeFilter} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Status</Form.Label>
                        <Form.Select name="orderStatus" onChange={HandleChangeFilter}>
                            <option value="">Select</option>
                            {
                                OrderConstant?.ORDER_STATUS?.map((item) => {
                                    return (<option value={item?.value} selected={item?.value === filterForm?.orderStatus}>{item?.label}</option>)
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn clear-all-btn"
                    data-dismiss="modal"
                    onClick={(e) => ClearFilter()}
                >
                    Clear all
                </button>
                <button
                    type="button"
                    className="btn apply-btn"
                    data-dismiss="modal"
                    onClick={(e) => ApplyFilter()}
                >
                    Apply
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default OrderShow;
