import React, { createContext, useState } from 'react';

export const SearchValueContext = createContext();

export const SearchValueProvider = ({ children }) => {
    const [searchValueTerm, setSearchValueTerm] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [search, setSearch] = useState('')

    return (
        <SearchValueContext.Provider value={{ searchValueTerm, setSearchValueTerm, searchTerm, setSearchTerm, search, setSearch }}>
            {children}
        </SearchValueContext.Provider>
    );
};

