import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, CardHeader, CardBody, CardFooter } from 'react-bootstrap';
import customLogger from "../../common/loggerService";
import Amplify, { Auth } from 'aws-amplify';
import { resourceconstant, Status } from "../../constants";
import { GetData, PostData, CreateEntry } from "../../providers/queryData";
import useProfile from "../../hooks/useProfile";
import { useNavigate } from 'react-router-dom';
import useCart from "../../hooks/useCartPage";
import { FormatValueDecimal, FormatSeperateValue } from "../../utils/validate";
import useUserDetails from '../../hooks/useUserDetails'
import { showErrorToastr } from "../../common/toastrService";
import { ToastrMessages } from "../../constants/toastrMessages";
import useLogoImage from "../../hooks/useLogoImage";
import Spinner from "../../common/spinner";
import FilterSpinner from "../../common/filterSpinner";
const CheckoutOrderSummery = ({ selectedCouponProvider, loading, setProductItem, productsItem, setProductList, productList, setCartData, location, setChecked, setUpdateCart, deliveryFeeItem, discountValue, selectedCode, itemQuantity, setIsClickContinue, ListCarts, countryItemBill, countryItemShipp, cartTotal, regularTotal, salePrice, productDiscount, totalTax, totalAmount, isSelfPickup, setIsChangeSummery, isChangeSummery, shippValues, billValues, cartItems, productDetailList, setIsPlaceOrder }) => {
    const [logoUrl, setLogoUrl] = useLogoImage();
    const [quantity, setQuantity] = useState(1);
    const [responseData, setResponseData] = useState(null);
    const [orderData, setOrderData] = useState({})
    const [updateOrderData, setUpdateOrderData] = useState([])
    const [userProfile, setUserProfile] = useProfile()
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [useuserDetails, setUseUserDetails] = useUserDetails();
    const [cartPageItem, setCartPageItem] = useCart()
    const userid = userProfile?.attributes?.sub;
    const navigate = useNavigate()
    const checkoutData = location?.state?.checkoutData;
    const listingCart = checkoutData?.data ? checkoutData?.data : cartPageItem

    const updateQuantityCheckout = async (cartItemId, quantity) => {
        try {
            const cartItems = [{
                "userID": cartItemId?.userId,
                "productID": cartItemId?.productID,
                "productImage": cartItemId?.productImage,
                "productName": cartItemId?.productName,
                "price": cartItemId?.price,
                "salesPrice": cartItemId?.salesPrice,
                "selectedQuantity": quantity,
                "brandDetail": {
                    "brandID": cartItemId?.brandDetail?.brandID,
                    "brandName": cartItemId?.brandDetail?.brandName,
                },
                "shippingInfo": {
                    "heightUnit": `${cartItemId.shippingInfo.heightUnit}`,
                    "heightValue": `${cartItemId.shippingInfo.heightValue}`,
                    "lengthUnit": `${cartItemId.shippingInfo.lengthUnit}`,
                    "lengthValue": `${cartItemId.shippingInfo.lengthValue}`,
                    "weightUnit": `${cartItemId.shippingInfo.weightUnit}`,
                    "weightValue": `${cartItemId.shippingInfo.weightValue}`,
                    "widthUnit": `${cartItemId.shippingInfo.widthUnit}`,
                    "widthValue": `${cartItemId.shippingInfo.widthValue}`
                },
                "wareHouseName": `${cartItemId.wareHouseName}`,
                "wareHouseId": `${cartItemId.wareHouseId}`,
                "userName": cartItemId?.userName,
                "userMail": cartItemId?.userMail,
                "model": cartItemId?.model,
                "taxCharge": cartItemId?.taxCharge,
                "sku": `${cartItemId?.sku}`,
                "searchKey": `${cartItemId.searchKey}`,
                "totalPrice": cartItemId.totalPrice,
                "mobileNumber": `${cartItemId.mobileNumber}`,
                "currentStock": `${cartItemId?.currentStock}`,
                "attrName": cartItemId.attrName,
                "attrValue": cartItemId.attrValue,
                "attrValueID": cartItemId?.attrValueID,
                "mainCategory": `${cartItemId?.mainCategory}`,
                "subCategoryN": `${cartItemId?.subCategoryN}`,
                "stockUnit": `${cartItemId?.stockUnit}`,
                "itemsPerUnit": cartItemId?.itemsPerUnit,
                "currentStock": cartItemId?.currentStock,
                "mainCategoryId": cartItemId?.mainCategoryId,
                "subCategoryId": cartItemId?.subCategoryId
            }];
            const item = CreateEntry(resourceconstant?.PRODUCT_ADD_TO_CART, true, setResponseData, cartItems)
            setCartPageItem(prevCartPageItem =>
                prevCartPageItem.map(item =>
                    item.productID === cartItemId.productID && item.attrValueID === cartItemId.attrValueID ? { ...item, selectedQuantity: quantity } : item
                )
            );
        } catch (err) {
            customLogger.error(err);
        }
    };

    const decrement = async (cartItemId) => {
        setIsButtonDisabled(false);
        if (cartItemId.selectedQuantity > 1) {
            const newQuantity = cartItemId.selectedQuantity - 1;
            const updatedListingCart = listingCart.map(item =>
                item.id === cartItemId.id ? { ...item, selectedQuantity: newQuantity } : item
            );
            if (checkoutData) {
                navigate(location.pathname, {
                    state: {
                        ...location.state,
                        checkoutData: {
                            ...checkoutData,
                            data: updatedListingCart,
                        },
                    },
                });
            }
            else {
                await updateQuantityCheckout(cartItemId, newQuantity);
            }
        }
    };

    const increment = async (cartItemId, stock, allowOrder) => {
        setIsButtonDisabled(false);
        if (allowOrder || cartItemId.selectedQuantity + 1 <= stock) {
            const newQuantity = cartItemId.selectedQuantity + 1;
            setQuantity(newQuantity);
            const updatedListingCart = listingCart.map(item =>
                item.id === cartItemId.id ? { ...item, selectedQuantity: newQuantity } : item
            );
            if (checkoutData) {
                navigate(location.pathname, {
                    state: {
                        ...location.state,
                        checkoutData: {
                            ...checkoutData,
                            data: updatedListingCart,
                        },
                    },
                });
            }
            else {
                await updateQuantityCheckout(cartItemId, newQuantity);
            }
        }
    };

    const HandleCreateOrder = async () => {
        setIsButtonDisabled(true);
        const currentUser = await Auth.currentAuthenticatedUser();
        const userid = currentUser.attributes.sub;
        const userEmail = currentUser.attributes.email;
        const userName = useuserDetails?.userName || localStorage.getItem('fullName');
        const phoneNo = currentUser.attributes.phone_number || useuserDetails?.phoneNumber;
        const orderID = localStorage.getItem('orderId')
        const userCartList = listingCart.filter(cart => cart.userID === userid || cart.userId === userid);

        let condition = false
        let data = {
            "order": {
                "pk": `ORDER#`,
                ...(isChangeSummery && { "id": `${orderID}` }),
                "email": `${userEmail}`,
                "phoneNumber": `${phoneNo}`,
                "userId": `${userid}`,
                "status": "PENDING",
                "totalAmount": totalAmount,
                "orderType": "nomal0",
                "couponDetails": {
                    "couponCode": selectedCode,
                    "amount": parseFloat(discountValue),
                    "discount": selectedCouponProvider?.discount,
                    "advancedSetting": selectedCouponProvider?.advancedSetting
                },
                "discountByCoupon": 0,
                "fullName": `${userName}`,
                "shippingAddress": {
                    "addressLine1": `${shippValues?.address?.addressLine1 ? shippValues?.address?.addressLine1 : ""}`,
                    "addressLine2": `${shippValues?.address?.addressLine2 ? shippValues?.address?.addressLine2 : ""}`,
                    "landmark": `${shippValues?.address?.landmark ? shippValues?.address?.landmark : ""}`,
                    "city": `${shippValues?.address?.city ? shippValues?.address?.city : ""}`,
                    "state": `${shippValues?.address?.state ? shippValues?.address?.state : ""}`,
                    "zip": `${shippValues?.address?.postalCode ? shippValues?.address?.postalCode : ''}`,
                    "streetOrCity": `${shippValues?.address?.street ? shippValues?.address?.street : ""}`,
                    "country": `${shippValues?.address?.country ? shippValues?.address?.country : ""}`
                },
                "shippingUserDetails": {
                    "userName": `${shippValues?.name}`,
                    "phoneNumber": `${shippValues?.phoneNumber}`,
                    "email": `${shippValues?.email}`,
                },
                "billingAddress": {
                    "addressLine1": `${billValues?.address?.addressLine1 ? billValues?.address?.addressLine1 : ""}`,
                    "addressLine2": `${billValues?.address?.addressLine2 ? billValues?.address?.addressLine2 : ""}`,
                    "city": `${billValues?.address?.city ? billValues?.address?.city : ""}`,
                    "state": `${billValues?.address?.state ? billValues?.address?.state : ""}`,
                    "zip": `${billValues?.address?.postalCode ? billValues?.address?.postalCode : ""}`,
                    "country": `${billValues?.address?.country ? billValues?.address?.country : ""}`,
                    "streetOrCity": `${billValues?.address?.street ? billValues?.address?.street : ""}`,
                    "landmark": `${billValues?.address?.landmark ? billValues?.address?.landmark : ""}`
                },
                "billingUserDetails": {
                    "userName": `${billValues?.name}`,
                    "phoneNumber": `${billValues?.phoneNumber}`,
                    "email": `${billValues?.email}`,
                },
                "orderSummary": {
                    "cartTotal": salePrice,
                    "deliveryFee": deliveryFeeItem?.deliveryCharge,
                    "productDiscount": `${productDiscount}`,
                    "tax": totalTax,
                },
                "deliveryInfo": {
                    "deliveryType": `${isSelfPickup ? "Self Pickup" : "Doorstep"}`
                },
                "totalProducts": `${listingCart?.length}`,
                "wishListId": "fgvdf",
                "paymentStatus": "PENDING"
            },
            "orderItems": userCartList.map(item => {
                const outStocks = productsItem?.filter(cart => cart?.id === item?.productID)
                const getDeliveryDateUTC = () => {
                    const now = new Date();
                    const currentHour = now.getUTCHours();

                    let deliveryDate = new Date(now);
                    if (currentHour >= 12) {
                        deliveryDate.setUTCDate(deliveryDate.getUTCDate() + 1);
                    }

                    if (outStocks[0]?.leadTime && outStocks[0]?.allowPreOrder) {
                        deliveryDate.setUTCDate(deliveryDate.getUTCDate() + parseInt(outStocks[0]?.leadTime));

                        if (deliveryDate.getUTCDay() === 5) {
                            deliveryDate.setUTCDate(deliveryDate.getUTCDate() + 1);
                        }
                    }
                    else if (deliveryDate.getUTCDay() === 5) {
                        deliveryDate.setUTCDate(deliveryDate.getUTCDate() + 1);
                    }

                    const awsDateFormat = deliveryDate.toISOString();
                    return awsDateFormat;
                };

                const stock = productDetailList?.filter(product => product?.productID === item?.productID);
                const stockItem = item?.attrValueID
                    ? stock?.filter(product => product?.attrValueID === item?.attrValueID && product?.productID === item?.productID)
                    : stock;
                const outStock = productsItem?.filter(product => product?.id === item?.productID);

                const isOutOfStock = !outStock?.[0]?.allowPreOrder && (
                    outStock?.[0]?.showWhenZeroStock && stockItem?.[0]?.stockStatus === Status?.OUTOFSTOCKSTATUS ||
                    stockItem?.[0]?.stockQuantity - stockItem[0]?.reservedStock === 0 ||
                    item?.selectedQuantity > stockItem?.[0]?.stockQuantity - stockItem[0]?.reservedStock ||
                    stockItem?.length === 0
                );

                if (isOutOfStock) {
                    condition = true;
                }

                return {
                    "pk": `ORDERI#`,
                    "productId": `${item?.productID}`,
                    "shippingInfo": {
                        "heightUnit": `${item?.shippingInfo?.heightUnit}`,
                        "heightValue": `${item?.shippingInfo?.heightValue}`,
                        "lengthUnit": `${item?.shippingInfo?.lengthUnit}`,
                        "lengthValue": `${item?.shippingInfo?.lengthValue}`,
                        "weightUnit": `${item?.shippingInfo?.weightUnit}`,
                        "weightValue": `${item?.shippingInfo?.weightValue}`,
                        "widthUnit": `${item?.shippingInfo?.widthUnit}`,
                        "widthValue": `${item?.shippingInfo?.widthValue}`
                    },
                    "phoneNumber": `${phoneNo}`,
                    "wareHouseId": `${item?.storageInfo?.wareHouseId ? item?.storageInfo?.wareHouseId : item?.wareHouseId}`,
                    "wareHouseName": `${item?.storageInfo?.wareHouseName ? item?.storageInfo?.wareHouseName : item?.wareHouseName}`,
                    "regularPrice": item?.price,
                    "attrName": item.attrName,
                    "attrValue": item.attrValue,
                    "attrValueID": item?.attrValueID,
                    "eta": getDeliveryDateUTC(),
                    "itemDetail": {
                        "productName": item?.productName,
                        "image": `${item?.productImage}`,
                        "brand": `${item?.brandDetail?.brandName ? item?.brandDetail?.brandName : item?.brandName}`,
                        "model": `${item?.model}`,
                        "qrcode": "https://meplinksbackendacd7b5864e124bc8aebaa3e7d9336e16201803-prod.s3.amazonaws.com/qrcode/d6d97818-d2fb-40b2-903f-47d2c3d3f863-qrcode.png",
                        "price": item?.salesPrice,
                        "sku": `${item?.sku}`,
                        "currentStock": `${item?.currentStock}`
                    },
                    "brandId": `${item?.brandDetail?.brandID ? item?.brandDetail?.brandID : item?.brandID}`,
                    "paymentStatus": "Pending",
                    "shippingCharge": 55.55,
                    "quantity": item?.selectedQuantity,
                    "taxAmount": parseFloat(item?.taxCharge),
                    "totalPrice": item?.salesPrice * item?.selectedQuantity + item?.taxCharge * item?.selectedQuantity,
                    "mainCategory": item?.mainCategory,
                    "subCategory": item?.subCategoryN,
                    "mainCategoryId": item?.mainCategoryId,
                    "subCategoryId": item?.subCategoryId,
                    "trackingID": "",
                    "returnAvailable": "",
                    "taxDetail": [
                        {
                            "taxCharge": item?.taxCharge,
                            "taxID": "DSC",
                            "taxName": "S"
                        }
                    ],
                }
            })
        };
        try {

            if (condition) {
                showErrorToastr(ToastrMessages?.CARTOUTOFSTOCK);
            }
            else {
                await CreateEntry(resourceconstant.CREATE_ORDER, true, setOrderData, data)
            }

        } catch (err) {
            customLogger.error(err);
        }
    };

    useEffect(() => {
        if (orderData?.message === "Success") {
            const orderItemId = orderData?.orderid;
            localStorage.setItem('orderId', orderItemId);
            localStorage.setItem('deliveryType', isSelfPickup ? "Self Pickup" : "Doorstep")
            setIsPlaceOrder(true)
            if (isChangeSummery) {
                setIsChangeSummery(false)
            }
        }
    }, [orderData]);

    const formatDateWithSuffix = (date) => {
        const day = date.getUTCDate();
        const month = date.toLocaleString('en-US', { month: 'long' });
        const year = date.getUTCFullYear();

        let suffix = 'th';
        if (day === 1 || day === 21 || day === 31) suffix = 'st';
        else if (day === 2 || day === 22) suffix = 'nd';
        else if (day === 3 || day === 23) suffix = 'rd';

        return `${day}${suffix} ${month} ${year}`;
    }


    return (
        <CardBody>
            {!loading && listingCart?.length > 0 ? (
                listingCart?.map((cart, index) => {
                    if (userid === cart?.userId || cart?.userID) {
                        const stock = productDetailList?.filter(item => item?.productID === cart?.productID)
                        const stockItem = cart?.attrValueID ? stock?.filter(item => item?.attrValueID === cart?.attrValueID) : stock; 
                        const regularPrice = cart?.price;
                        const salePrice = cart?.salesPrice;
                        const outStock = productsItem?.filter(item => item?.id === cart?.productID)
                        const discountPercentage = ((regularPrice - salePrice) / regularPrice) * 100;
                        const handleLinkClick = (e) => {
                            e.preventDefault();
                            navigate(`/product-detail/${cart.productID || cart.id}`, {
                                state: {
                                    values: {
                                        subproduct_id: cart.productID || cart.id,
                                        allProducts: cart.productID || cart.id,
                                        search: cart.productID || cart.id,
                                    },
                                },
                            });
                        };

                        const getDeliveryDateUTC = () => {
                            const now = new Date();
                            const currentHour = now.getUTCHours();

                            let deliveryDate = new Date(now);
                            if (currentHour >= 12) {
                                deliveryDate.setUTCDate(deliveryDate.getUTCDate() + 1);
                            }

                            if (outStock[0]?.leadTime && outStock[0]?.allowPreOrder) {
                                deliveryDate.setUTCDate(deliveryDate.getUTCDate() + parseInt(outStock[0]?.leadTime));

                                if (deliveryDate.getUTCDay() === 5) {
                                    deliveryDate.setUTCDate(deliveryDate.getUTCDate() + 1); // Skip to Saturday
                                }
                            }

                            else if (deliveryDate.getUTCDay() === 5) {
                                deliveryDate.setUTCDate(deliveryDate.getUTCDate() + 1); // Skip to Saturday
                            }

                            const formattedDate = formatDateWithSuffix(deliveryDate);
                            return `Delivery by ${formattedDate}`;
                        };

                        return (
                            <Row style={{ 'borderBottom': '1px solid #bab3b3' }} className='mb-2'>
                                <Col md={12} key={index}>
                                    <div className='table-responsive'>
                                        <table className='table table-borderless mb-0'>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <img width="100%" src={cart.productImage} alt="product" onClick={handleLinkClick} style={{ cursor: "pointer" }} />
                                                    </td>
                                                    <td>
                                                        <h6> {cart.productName}
                                                            {cart.attrValue && ` - ${cart.attrName} ${cart.attrValue}`}</h6>
                                                        <p className="pdt-model" style={{ color: "black", fontSize: "16px", fontWeight: "400", marginBottom: "0.5rem" }}>
                                                            <span>Brand: </span>{cart?.brandDetail?.brandName || cart?.brandName}
                                                            {cart?.model && (
                                                                <>
                                                                    <span> | </span>
                                                                    <span>Model: </span>{cart.model}
                                                                </>
                                                            )}
                                                            {cart?.sku && (
                                                                <>
                                                                    <span> | </span>
                                                                    <span>SKU: </span>{cart.sku}
                                                                </>
                                                            )}
                                                        </p>
                                                        <div className='d-flex justify-content-start gap-2 price-details'>
                                                            <h2>{logoUrl?.currency} {FormatValueDecimal(cart.salesPrice)}</h2>
                                                            {discountPercentage > 0 && (
                                                                <>
                                                                    <h3>{logoUrl?.currency} {FormatValueDecimal(cart.price)}</h3>
                                                                    <h4>{FormatSeperateValue(discountPercentage) + '%'} Off</h4>
                                                                </>
                                                            )}
                                                        </div>
                                                        <p className='qty-price'><span>Unit Tax: </span>{FormatValueDecimal(cart.taxCharge)} <span>|</span> <span>Total:</span> {FormatValueDecimal(cart?.salesPrice * cart.selectedQuantity)}</p>
                                                        {!isSelfPickup && (
                                                            <div>
                                                                <p>{getDeliveryDateUTC()}</p>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <h5>Quantity</h5>
                                                            <div className='d-flex justify-content-start gap-2'>
                                                                <button type='button' className='btn' onClick={() => decrement(cart)} disabled={!outStock[0]?.allowPreOrder && stockItem[0]?.stockQuantity <= 1 || cart.selectedQuantity === 1} style={!outStock[0]?.allowPreOrder && stockItem[0]?.stockQuantity - stockItem[0]?.reservedStock <= 1 || cart.selectedQuantity === 1 ? { border: "none", color: 'grey' } : {}}><i className="fa fa-minus" aria-hidden="true"></i></button>
                                                                <button type='button' className='btn count-num'>{cart.selectedQuantity}</button>
                                                                <button type='button' className='btn' onClick={() => increment(cart, stockItem[0]?.stockQuantity - stockItem[0]?.reservedStock, outStock[0]?.allowPreOrder)} disabled={!outStock[0]?.allowPreOrder && cart.selectedQuantity >= stockItem[0]?.stockQuantity - stockItem[0]?.reservedStock} style={!outStock[0]?.allowPreOrder && cart.selectedQuantity >= stockItem[0]?.stockQuantity - stockItem[0]?.reservedStock ? { border: "none", color: 'grey' } : {}}><i className="fa fa-plus" aria-hidden="true"></i></button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        )
                    }
                })) : (
                loading && <FilterSpinner />
            )}
            <Row>
                <Col md={12}>
                    <button type='button' className='btn' onClick={HandleCreateOrder} disabled={isButtonDisabled || loading || totalAmount < 0}>Continue With Payment</button>
                </Col>
            </Row>
        </CardBody>
    )
}

export default CheckoutOrderSummery
