import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, CardHeader, CardBody, CardFooter } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";
import { FormatValueDecimal } from "../../utils/validate";
import { PlaceHolderText } from "../../constants/placeHolderText";
import CouponCode from "../cart/couponCode"
import { GetData } from "../../providers/queryData";
import { resourceconstant } from "../../constants";
import { showErrorToastr } from "../../common/toastrService";
import { ToastrMessages } from "../../constants/toastrMessages";
import Accordion from 'react-bootstrap/Accordion';
import Coupon from "../../assets/images/icons/coupon.png"
import useProfile from '../../hooks/useProfile'
import useLogoImage from "../../hooks/useLogoImage";
import FilterSpinner from "../../common/filterSpinner";
const PriceOrderSummery = ({ loading, productDetailList, setSelectedCouponProvider, isChangeSummery, isPlaceOrder, userCartList, setSelectedPromo, selectedPromo, deliveryFeeItem, setSelectedCode, selectedCode, discountValue, setDiscountValue, setPromoCode, promoCode, setCouponCode, couponCode, promoCodeList, setPromoCodeList, selectedCoupon, setSelectedCoupon, searchTerm, setSearchTerm, filteredPromoCodes, setFilteredPromoCodes, selectCouponValue, setSelectCouponValue, filteredPromoCode, setFilteredPromoCode, filteredDatePromoCode, setFilteredDatePromoCode, commonSearch, setCommonSearch, originalData, setOriginalData, cartPageItem, quantity, cartTotal, regularTotal, salePrice, productDiscount, totalTax, totalAmount, listingCart, cartItems }) => {
    const [logoUrl, setLogoUrl] = useLogoImage();
    const [couponData, setCouponData] = useState('');

    const [userProfile] = useProfile()
    const userid = userProfile?.attributes?.sub;

    const PromoCodeList = () => {
        let filter = {
        }
        GetData(resourceconstant.PROMO_CODE_LIST, filter, setPromoCode)
    };

    useEffect(() => {
        if (promoCode?.severity === "success") {
            const data = promoCode?.data?.data?.listPromoCodes?.items;
            const currentDate = new Date();

            const normalizeToEndOfDay = (date) => {
                if (!date) return null;
                const endOfDay = new Date(date);
                endOfDay.setHours(23, 59, 59, 999);
                return endOfDay;
            };

            const validDatePromoCodes = data?.filter(item => {
                const validFrom = item?.validFrom ? new Date(item.validFrom) : null;
                const validTo = item?.validTo ? normalizeToEndOfDay(new Date(item.validTo)) : null;
                const isValidDateRange = (!validFrom || currentDate >= validFrom) && (!validTo || currentDate <= validTo);
                const isActive = item?.status === "ACTIVE";
                return isValidDateRange && isActive;
            });

            const promoCodesWithoutSpecificType = validDatePromoCodes?.filter(item => {
                const appliedTo = item?.advancedSetting?.appliedTo;
                const specificType = item?.advancedSetting?.specificType;
                if (appliedTo === "products") {
                    return true;
                }
                return !(appliedTo === "customers" && specificType && specificType.length >= 0);
            });

            const promoCodesWithSpecificType = validDatePromoCodes?.filter(item => {
                const appliedTo = item?.advancedSetting?.appliedTo;
                const specificTypeIds = item?.advancedSetting?.specificType?.map(type => type.id);

                if (appliedTo === "products") {
                    return userCartList?.some(cartItem =>
                        specificTypeIds.includes(cartItem?.subCategoryId) ||
                        specificTypeIds.includes(cartItem?.mainCategoryId)
                    );
                } else if (appliedTo === "customers") {
                    return userCartList?.some(cartItem => specificTypeIds.includes(cartItem?.userId));
                }

            });
            setPromoCodeList(validDatePromoCodes);
            setFilteredPromoCode(validDatePromoCodes);
            setFilteredDatePromoCode(promoCodesWithoutSpecificType);
        }
    }, [promoCode, userCartList]);

    useEffect(() => {
        PromoCodeList()
    }, [])


    const handleCouponCode = () => {
        if (selectedCoupon) {
            showErrorToastr(ToastrMessages?.COUPONEXIST);
        } else {
            setCouponCode(true);
        }
    }

    const handleSearchChange = (event) => {
        if (selectedCoupon) {
            showErrorToastr(ToastrMessages?.COUPONEXIST)
        } else {
            setSearchTerm(event.target.value);
        }
    };

    const handleSearchClick = () => {
        if (searchTerm) {
            const filteredList = filteredPromoCode.filter((item) =>
                item.code.toLowerCase() === searchTerm.toLowerCase()
            );

            if (filteredList.length > 0) {
                const coupon = filteredList[0];
                const appliedTo = coupon?.advancedSetting?.appliedTo;
                const specificTypeIds = coupon?.advancedSetting?.specificType?.map(type => type.id) || [];

                const selected =
                    appliedTo === "products"
                        ? userCartList?.filter(
                            (cartItem) =>
                                specificTypeIds.includes(cartItem?.subCategoryId) ||
                                specificTypeIds.includes(cartItem?.mainCategoryId)
                        )
                        : appliedTo === "customers"
                            ? specificTypeIds.includes(userid)
                            : false;
                setSelectedPromo(selected)
                if (selected === true || selected?.length > 0 || !coupon?.advancedSetting) {
                    setSelectedCoupon(coupon);
                } else {
                    showErrorToastr(ToastrMessages?.COUPONCODENOTFOUND)
                    setSelectedCoupon('')
                }
            } else {
                showErrorToastr(ToastrMessages?.COUPONCODENOTFOUND)
            }

            setSearchTerm('');
        } else {
            setSearchTerm('');
            setSelectedCoupon('');
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    };

    const HandleCross = () => {
        setSearchTerm('');
        setSelectedCoupon('');
        setDiscountValue('');
        setSelectedCode('');
        setSelectedCouponProvider('')
        setCouponData('')
        localStorage.removeItem('couponData')
    };

    const renderDiscountValue = (discount) => {
        if (!discount) return null;
        let couponAmounts = [];

        if (selectedPromo?.length > 0) {
            couponAmounts = selectedPromo.reduce((acc, item) => {
                const itemDiscount = userCartList.reduce((sum, data) => {
                    if (selectedCoupon?.discount?.type === "percentage" && item.productID === data.productID && item.attrValueID === data.attrValueID) {
                        const added = data.selectedQuantity * item.salesPrice;
                        const percentage = added * discount / 100;
                        return sum + percentage;
                    }
                    return sum;
                }, 0);
                return acc + itemDiscount;
            }, 0);
        }
        const totalDiscountValue = selectedPromo?.length > 0
            ? couponAmounts
            : selectedCoupon?.discount?.type === "percentage"
                ? parseFloat(cartTotal * discount) / 100
                : discount;

        setDiscountValue(totalDiscountValue)
        return `{logoUrl?.currency} ${totalDiscountValue}`;
    };

    useEffect(() => {
        if (selectedCoupon && Array.isArray(filteredPromoCode)) {
            const selected = filteredPromoCode.find(item => item?.code === selectedCoupon?.code);

            if (!selected) {
                setSelectedCoupon('');
                setDiscountValue('');
                setSelectedCode('');
                setSelectedCouponProvider('')
                setCouponData('')
                localStorage.removeItem('couponData');
            }
        }
    }, [selectedCoupon, filteredPromoCode]);

    const renderCoupons = () => {
        if (selectedCoupon) {
            renderDiscountValue(selectedCoupon?.discount?.value);
            setSelectedCode(selectedCoupon?.code);
            setSelectedCouponProvider(selectedCoupon)

            return (
                <tr>
                    <th>Coupon Discount <br />({selectedCoupon?.code})</th>
                    <td className='text-end' style={{ color: "#1A0003" }}>
                        {logoUrl?.currency} {FormatValueDecimal(discountValue)}
                    </td>
                </tr>
            );
        }
        return null;
    };

    return (
        <Row className="gx-1">
            <Col md={12} className='mb-1'>
                {(!isPlaceOrder || isChangeSummery) && (
                    <Card className='order-summary-card coupon-card'>
                        <CardHeader>
                            <h2>Apply Coupon</h2>
                        </CardHeader>
                        <CardBody>
                            <Row className="justify-content-center">
                                <Col md={12}>
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={PlaceHolderText?.COUPONCODE}
                                            aria-label="Enter coupon code"
                                            aria-describedby="basic-addon2"
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                            onKeyPress={handleKeyPress}
                                        />
                                        <span className="input-group-text" onClick={handleSearchClick}>
                                            <div style={{ color: "#A3999A" }}>|</div>  <i className="fa fa-search"></i>
                                        </span>
                                    </div>
                                    {selectedCoupon && (
                                        <div className='coupons'>
                                            <p><span>{selectedCoupon?.code}</span></p>
                                            <p>{selectedCoupon?.description}</p>
                                            {selectedCoupon?.terms && (
                                                <Accordion>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header> View T & C</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p dangerouslySetInnerHTML={{ __html: selectedCoupon?.terms }} />
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            )}
                                            <button className="close-button" onClick={HandleCross}>✖</button>

                                        </div>
                                    )
                                    }
                                </Col>
                                {filteredDatePromoCode?.length > 0 ? (
                                    <Col md={12} className='mt-3 d-flex align-items-center justify-content-between'>
                                        <p>View more offers</p>
                                        <i className="fa fa-chevron-right" onClick={handleCouponCode}></i>
                                    </Col>
                                ) : (
                                    <>
                                        <p style={{ textAlign: "center" }}>No coupons available to add</p>
                                    </>
                                )}
                            </Row>
                        </CardBody>
                    </Card>
                )}
            </Col>
            <Col md={12} className='mb-1'>
                <Card className='order-summary-card'>
                    <CardHeader>
                        <h2>Order Summary</h2>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                <div className='table-responsive'>
                                    <table className='table table-borderless mb-0'>
                                        <tbody>
                                            <tr>
                                                <th>Cart Total</th>
                                                <td className='text-end'>{logoUrl?.currency} {cartTotal ? FormatValueDecimal(cartTotal) : 0}</td>
                                            </tr>
                                            {productDiscount !== 0 && (
                                                <tr>
                                                    <th>You Saved</th>
                                                    <td className='text-end' style={{ color: "#1BAA57" }}>{logoUrl?.currency} {FormatValueDecimal(productDiscount)}</td>
                                                </tr>
                                            )}
                                            {renderCoupons()}
                                            {(deliveryFeeItem?.deliveryCharge !== undefined && deliveryFeeItem?.deliveryCharge !== null) && (
                                                <tr>
                                                    <th>Delivery Fee</th>
                                                    {deliveryFeeItem?.deliveryCharge === 0 ? (
                                                        <td className='text-end'>Free</td>
                                                    ) : (
                                                        <td className='text-end'>{logoUrl?.currency} {FormatValueDecimal(deliveryFeeItem?.deliveryCharge)}</td>
                                                    )}
                                                </tr>
                                            )}
                                            <tr>
                                                <th>Tax</th>
                                                <td className='text-end'>{logoUrl?.currency} {FormatValueDecimal(totalTax ? totalTax : 0)}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Total</th>
                                                <th>{logoUrl?.currency} {FormatValueDecimal(totalAmount ? totalAmount : 0)}</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <CouponCode selectedPromo={selectedPromo} setSelectedPromo={setSelectedPromo} listingCartCheckout={userCartList} filteredPromoCodes={filteredPromoCodes} setFilteredPromoCodes={setFilteredPromoCodes} setSearchTerm={setSearchTerm} originalData={originalData} filteredDatePromoCode={filteredDatePromoCode} setFilteredDatePromoCode={setFilteredDatePromoCode} commonSearch={commonSearch} setCommonSearch={setCommonSearch} selectedCoupon={selectedCoupon} setSelectedCoupon={setSelectedCoupon} promoCodeList={promoCodeList} setCouponCode={setCouponCode} couponCode={couponCode} />
            </Col>

        </Row>
    )
}

export default PriceOrderSummery