import React, { useEffect, useState } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 525 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 525, min: 0 },
      items: 2
    }
  };
const BillingAddressSlider = ({ setIsSameCheck, isChangeAddress, countryItem, setShippValues, setChangeShipp, isSelfPickup, setIsShipped, isSameCheck, shippValues, changeBill, changeShipp, setChangeBill, setIsChangeAddress, userAddress, setBillValues, setIsBilled, billValues, setCheckedBill, checkedBill }) => {
    
  const defaultAddress = userAddress?.addressDetails?.find(item => item.default);
  useEffect(() => {
    if (defaultAddress) {
      setCheckedBill(defaultAddress);
      setBillValues(defaultAddress);
      if (isSelfPickup) {
        setIsShipped(true);
        setIsChangeAddress(false);
        setShippValues(defaultAddress);
        setChangeShipp(defaultAddress);
      }
      if (!isSameCheck && !isSelfPickup && isChangeAddress && JSON.stringify(shippValues) !== JSON.stringify(billValues)) {
        setCheckedBill([]);
        setBillValues([]);
        setIsBilled(false);
      }
      if (!isSameCheck && !isSelfPickup) {
        setCheckedBill([]);
        setBillValues([]);
        setIsBilled(false);
      }
      if(!isSelfPickup && isSameCheck){
        setCheckedBill(shippValues);
        setBillValues(shippValues);
        setIsBilled(true);
        setIsChangeAddress(false)
      }
      if(isSelfPickup && isChangeAddress) {
        setBillValues(billValues)
        setCheckedBill(billValues)
        setIsBilled(true)
      }
      setIsBilled(true);
      setChangeBill(defaultAddress);
      if(billValues && isChangeAddress){
        setBillValues(billValues)
        setChangeBill(changeBill)
        setIsBilled(true)
      }
      if(JSON.stringify(shippValues) !== JSON.stringify(billValues) && !isSelfPickup){
        setBillValues(billValues)
        setChangeBill(changeBill)
        setIsBilled(true)
        setIsSameCheck(false)
      }
      if (isChangeAddress && JSON.stringify(shippValues) !== JSON.stringify(billValues))
        {
          setIsSameCheck(false);
        }
    } else {
      setCheckedBill('');
      setBillValues([]);
      setIsBilled(false);
      setChangeBill([]);
    }
  }, [defaultAddress]);

  return(
      <Carousel
      responsive={responsive}
      arrows={true}
      className='address-list'
      >
        {(userAddress?.addressDetails || []).map((item, index) => {
           const country = countryItem?.filter((country)=>country?.id===item?.address?.country) 
           const handleCheckboxChangeBill = (event) => {
            const data = event.target.checked;
            if (data) {
                setCheckedBill(data);
                setBillValues(item); 
                setIsBilled(true);
        
                if (isSelfPickup) {
                    setIsShipped(true);
                    setIsChangeAddress(false);
                    setChangeShipp(item);
                    setShippValues(item);
                }
                if(!isSelfPickup && isSameCheck){
                  setCheckedBill(shippValues);
                  setBillValues(shippValues); 
                  setIsBilled(true);
                  setIsChangeAddress(false)
                }
                
                setChangeBill(item);
        
                if (changeShipp?.length !== 0) {
                    setIsChangeAddress(false);
                }
        
                if (JSON.stringify(shippValues) === JSON.stringify(item) && !isSelfPickup) {
                    setIsSameCheck(true); 
                }
            } else {
                setCheckedBill('');
                setBillValues([]);
                setIsBilled(false);
                setChangeBill([]);
            }
        };
             
         return (
          <div className='address-tile' key={index}>
              <div className='user-detail'>
                <input 
                  type='checkbox' 
                  checked={checkedBill && item === billValues} 
                  onChange={handleCheckboxChangeBill} 
                />
                <h2>{item.name}</h2>
                <p>
                  {item?.address?.addressLine1 && (
                    <>
                      {item.address.addressLine1} <br />
                    </>
                  )}
                  {item?.address?.addressLine2 && (
                    <>
                      {item.address.addressLine2}, <br />
                    </>
                  )}
                  {item?.address?.street && (
                    <>
                      {item.address.street}, <br />
                    </>
                  )}
                  {item?.address?.city && (
                    <>
                      {item.address.city}, <br />
                    </>
                  )}
                  {item?.address?.state && (
                    <>
                      {item.address.state}, <br />
                    </>
                  )}
                  {item?.address?.country && (
                    <>
                      {item.address.country}
                    </>
                  )}
                  {item?.address?.postalCode && (
                    <>
                      {' - '}{item.address.postalCode}
                    </>
                  )}
                </p>
                <p>{item?.phoneNumber}</p>
                <p>{item?.email}</p>
              </div>
            </div>
         );
      })}
     </Carousel>
    )
}

export default BillingAddressSlider