import React from 'react';
import { Spinner } from 'react-bootstrap';

const FilterSpinner = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner
                animation="border"
                role="status"
                style={{
                    border: '10px solid #F3F3F3',
                    borderRadius: '50%',
                    borderTop: '10px solid #E61919',
                    width: '50px',
                    height: '50px',
                    // animation: 'spin 2s linear infinite',
                }}
            >
            </Spinner>
        </div>
    );
};

export default FilterSpinner;
