import React, { useState, useEffect, useMemo } from 'react'
import { Container, Row, Col, Card, Form, CardHeader, CardBody, CardFooter } from 'react-bootstrap';
import { FormatValueDecimal } from '../../utils/validate';
import useLogoImage from '../../hooks/useLogoImage';

const ShareCartSummery = ({ shareCartItem, CartList, cartList, cartResponseData, listingCart }) => {
    const [logoUrl, setLogoUrl] = useLogoImage();

    const cartTotal = shareCartItem?.reduce((total, item) => total + (item?.salesPrice * item.selectedQuantity), 0);
    const regularTotal = shareCartItem?.reduce((total, item) => total + (item?.price * item.selectedQuantity), 0);
    const salePrice = shareCartItem?.reduce((total, item) => total + (item?.salesPrice * item.selectedQuantity), 0);

    const productDiscount = shareCartItem?.reduce((total, item) => {
        const discountPercentage = regularTotal - salePrice;
        return discountPercentage;
    }, 0);
    const totalTax = shareCartItem?.reduce((total, item) => total + (item?.taxCharge * item.selectedQuantity), 0);
    const totalAmount = cartTotal + totalTax;
    
    return (
        <Card className='order-summary-card'>
            <CardHeader>
                <h2>Order Summary</h2>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md={12}>
                        <div className='table-responsive'>
                            <table className='table table-borderless mb-0'>
                                <tbody>
                                    <tr>
                                        <th>Cart Total</th>
                                        <td className='text-end'>{logoUrl?.currency} {cartTotal ? FormatValueDecimal(cartTotal) : 0}</td>
                                    </tr>
                                    <tr>
                                        <th>You Saved</th>
                                        <td className='text-end' style={{ color: "#1BAA57" }}> {logoUrl?.currency} {FormatValueDecimal(productDiscount)}</td>
                                    </tr>
                                    <tr>
                                        <th>Tax</th>
                                        <td className='text-end'>{logoUrl?.currency} {FormatValueDecimal(totalTax ? totalTax : 0)}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Total</th>
                                        <th>{logoUrl?.currency} {FormatValueDecimal(totalAmount ? totalAmount : 0)}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default ShareCartSummery