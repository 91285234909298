import React, { useEffect, useState } from "react";
import {
    Row,
    Col
} from "react-bootstrap";
import { resourceconstant } from "../../../constants";
import { GetData } from "../../../providers/queryData";
import { DateFormatShow, CheckNull } from "../../../utils/validate";
import { Link } from 'react-router-dom';
import { GetDropDownMaster, UpdateProductReview } from "../../../utils/generalMethod";
import useProfile from "../../../hooks/useProfile";
import useUserDetails from '../../../hooks/useUserDetails'

const ProductRatingComp = ({ productId }) => {
    const [userProfile, setUserProfile] = useProfile()
    const [userDetails, setUserDetails] = useUserDetails();
    const [rating, setRating] = useState([]);
    const [ratingList, setRatingList] = useState([]);
    const [reportVisible, setReportVisible] = useState(null);
    const [abusiveReason, setAbusiveReason] = useState([]);
    const [abusiveReasonList, setAbusiveReasonList] = useState([]);
    const [checkAbusive, setCheckAbusive] = useState([]);
    const [showComment, setShowComment] = useState(false);
    const [comment, setComment] = useState("")
    const [updateReviewResponse, setUpdateReviewResponse] = useState(null)
    const [showSuccess, setShowSuccess] = useState(null)
    const [showError, setShowError] = useState(null)
    const [formError, setFormError] = useState({ commentError: "", abusiveError: "" })

    const ProductRatings = async () => {
        let filter = {};
        GetData(resourceconstant.PRODUCT_RATING_LIST, filter, setRating);
    };

    useEffect(() => {
        ProductRatings();
    }, []);

    useEffect(() => {
        if (rating?.severity === "success") {
            setRatingList(rating?.data?.data?.listProductReviews?.items?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
        }
    }, [rating]);

    const filteredRatings = ratingList?.filter(item => item.productId === productId && item?.removeAbusiveComment !== true);

    const totalRating = filteredRatings?.reduce((total, item) => total + item.overallRating, 0);
    const averageRating = filteredRatings?.length ? (totalRating / filteredRatings.length).toFixed(1) : 0;
    const oneRating = filteredRatings?.filter(item => item.overallRating === 1)
    const twoRating = filteredRatings?.filter(item => item.overallRating === 2)
    const threeRating = filteredRatings?.filter(item => item.overallRating === 3)
    const fourRating = filteredRatings?.filter(item => item.overallRating === 4)
    const fiveRating = filteredRatings?.filter(item => item.overallRating === 5)

    const renderStars = () => {
        const fullStars = Math.floor(averageRating);
        const halfStar = averageRating - fullStars >= 0.5 ? 1 : 0;
        const emptyStars = 5 - fullStars - halfStar;

        return (
            <>
                {Array(fullStars).fill().map((_, i) => <i key={`full-${i}`} className='fa fa-star'></i>)}
                {halfStar ? <i key="half" className='fa fa-star-half-o'></i> : null}
                {Array(emptyStars).fill().map((_, i) => <i key={`empty-${i}`} className='fa fa-star-o'></i>)}
            </>
        );
    };

    const handleReport = (item) => {
        setReportVisible({ reviewId: item?.id, reportVisibleShow: true });
        setComment("");
        setCheckAbusive([])
    };

    useEffect(() => {
        let data = {
            pk: { eq: `ABUSIVEREASON#` },
            status: { eq: `ACTIVE` }
        };
        GetDropDownMaster(data, setAbusiveReason)
    }, [])

    useEffect(() => {
        if (abusiveReason?.severity === "success") {
            setAbusiveReasonList(abusiveReason?.data?.data?.listDropDownMasters?.items?.sort(function (a, b) {
                return a.sequenceNumber - b.sequenceNumber
            }))
        }
    }, [abusiveReason])

    const HandleAbusiveReasonChange = (e) => {
        if (e.target.checked === true) {
            setCheckAbusive([...checkAbusive, e.target.value])
            setFormError({ ...formError, abusiveError: "" })
        }
        else setCheckAbusive(checkAbusive?.filter((item) => item !== e.target.value))
    }

    useEffect(() => {
        if (checkAbusive?.indexOf("Others") > -1)
            setShowComment(true)
        else {
            setShowComment(false)
            setComment("");
        }
    }, [checkAbusive])

    const Validation = () => {
        const errors = {};
        if (checkAbusive?.length <= 0) {
            errors.abusiveError = "Please select comment";
        }
        else if (checkAbusive?.indexOf("Others") > -1) {
            if (CheckNull(comment) === "") {
                errors.commentError = "Message is required"
            }
        }
        setFormError(errors)
        return errors;
    }

    const HandleReportComment = (e, item) => {
        e.preventDefault();
        if (Object.keys(Validation()).length === 0) {
            const selectedAbusive = checkAbusive?.map((item, index) => {
                if (item === "Others") return comment;
                else return item;
            });

            let newReport = {
                "userId": userProfile?.attributes?.sub,
                "userName": userProfile?.attributes['custom:firstName'] + userProfile?.attributes['custom:lastName'],
                "email": userProfile?.attributes?.email,
                "phoneNumber": userDetails?.phoneNumber,
                "reasons": selectedAbusive,
                "reportedDate": new Date()
            }

            let previousAbusive = item?.abusiveReportedBy?.length > 0 ? [...item?.abusiveReportedBy] : [];
            previousAbusive?.map((item, index) => {
                return delete item?.__typename;
            });

            let data = {
                pk: "REVP#",
                id: item?.id,
                abusiveReportedBy: item?.abusiveReportedBy === null ? [newReport] : [...previousAbusive, newReport]
            }
            UpdateProductReview(data, setUpdateReviewResponse)
        }
    }

    useEffect(() => {
        if (updateReviewResponse?.severity === "success") {
            setShowSuccess({ id: updateReviewResponse?.data?.data?.updateProductReview?.id, show: true })
            setShowError(null)
        }
        if (updateReviewResponse?.severity === "error") {
            setShowSuccess(null)
            setShowError({ id: updateReviewResponse?.data?.data?.updateProductReview?.id, show: true })
        }
        if (updateReviewResponse?.severity === "success" || updateReviewResponse?.severity === "error") {
            if (updateReviewResponse?.severity === "success") ProductRatings()
            setUpdateReviewResponse(null)
            setFormError({})
            setCheckAbusive([])
            setShowComment(false)
            setComment("")
            setReportVisible(null)
        }

        setTimeout(() => {
            setShowSuccess(null);
            setShowError(null);
        }, 5000);
    }, [updateReviewResponse])

    return (
        <>
            <div className="customer-review mt-1">
                <Row>
                    {filteredRatings.length > 0 ?
                        (<><Col md={5} className="pe-xxl-0 pe-xl-0 pe-lg-0 pe-md-0">
                            <div className="ratings">
                                <p>Customer Reviews ({filteredRatings.length})</p>
                                <h2 className="rate-count">{averageRating}</h2>
                                {renderStars()}
                                <p>All reviews come from verified purchasers</p>
                                <div className="rate-progress">
                                    <div className="rate-left">
                                        <p>5</p>
                                        <i className="fa fa-star"></i>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" style={{ width: `${fiveRating?.length * 100 / filteredRatings?.length}%` }} aria-valuenow={fiveRating?.length * 100 / filteredRatings?.length} aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <p>{fiveRating?.length > 0 ? fiveRating?.length : ""}</p>
                                </div>
                                <div className="rate-progress">
                                    <div className="rate-left">
                                        <p>4</p>
                                        <i className="fa fa-star"></i>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" style={{ width: `${fourRating?.length * 100 / filteredRatings?.length}%` }} aria-valuenow={fourRating?.length * 100 / filteredRatings?.length} aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <p>{fourRating?.length > 0 ? fourRating?.length : ""}</p>
                                </div>
                                <div className="rate-progress">
                                    <div className="rate-left">
                                        <p>3</p>
                                        <i className="fa fa-star"></i>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" style={{ width: `${threeRating?.length * 100 / filteredRatings?.length}%` }} aria-valuenow={threeRating?.length * 100 / filteredRatings?.length} aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <p>{threeRating?.length > 0 ? threeRating?.length : ""}</p>
                                </div>
                                <div className="rate-progress">
                                    <div className="rate-left">
                                        <p>2</p>
                                        <i className="fa fa-star"></i>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" style={{ width: `${twoRating?.length * 100 / filteredRatings?.length}%` }} aria-valuenow={twoRating?.length * 100 / filteredRatings?.length} aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <p>{twoRating?.length > 0 ? twoRating?.length : ""}</p>
                                </div>
                                <div className="rate-progress">
                                    <div className="rate-left">
                                        <p>1</p>
                                        <i className="fa fa-star"></i>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" style={{ width: `${oneRating?.length * 100 / filteredRatings?.length}%` }} aria-valuenow={oneRating?.length * 100 / filteredRatings?.length} aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <p>{oneRating?.length > 0 ? oneRating?.length : ""}</p>
                                </div>
                            </div>
                        </Col>
                            <Col md={7} className="ps-xxl-1 ps-xl-1 ps-lg-1 ps-md-1 pe-2">
                                <div className="ratings overflow-y-scroll">
                                    {
                                        filteredRatings?.map((item, index) => {
                                            return (
                                                <>
                                                    <div className="review-list">
                                                        <div className="reviewer">
                                                            <div className="user">
                                                                <i className="fa fa-user"></i>
                                                            </div>
                                                            <div className="user-detail">
                                                                <h2>{item?.userName}</h2>
                                                                <p className="mb-0"> {item?.overallRating} {[1, 2, 3, 4, 5].map((index) => {
                                                                    if (index <= item.overallRating)
                                                                        return <i key={index} className="fa fa-star"></i>;
                                                                    else
                                                                        return <i key={index} className="fa fa-star-o"></i>;
                                                                })
                                                                }
                                                                </p>
                                                                <p className="date">{DateFormatShow(item?.createdAt, false)}</p>
                                                            </div>
                                                        </div>

                                                        <p>{item?.title}</p>
                                                        <p className="comment">{item?.reviewComment}</p>
                                                        {/* <p className="date my-3">{DateFormatShow(item?.createdAt, false)}</p> */}
                                                    </div>

                                                    <div className="user-report mt-2">
                                                        {item?.userId !== userProfile?.attributes?.sub ?
                                                            item?.abusiveReportedBy?.some((abusive) => abusive?.userId === userProfile?.attributes?.sub) === true ?
                                                                <p className="error-msg">Reported</p> : reportVisible?.reviewId !== item?.id && CheckNull(userProfile?.attributes?.sub) != "" ? <p onClick={() => handleReport(item)}>Report</p> : "" : ""}
                                                        {showSuccess?.show && showSuccess?.id === item?.id && (<div className="d-flex">
                                                            <i className="fa fa-check"></i>
                                                            <p className="success-msg">Thank you for your report.We’ll investigate in the next few days.</p>
                                                        </div>)}
                                                        {showError?.show && showError?.id === item?.id && (<div className="d-flex">
                                                            <i className="fa fa-check"></i>
                                                            <p className="error-msg">Sorry! some problem happens to sent report.</p>
                                                        </div>)}
                                                        {reportVisible?.reportVisibleShow && reportVisible?.reviewId === item?.id && (
                                                            <>
                                                                <div className="reporting-comments mt-2">
                                                                    <p className="title">Report This Review</p>
                                                                    <h6>Optional: Why are you reporting this?</h6>
                                                                    {abusiveReasonList?.map((item) => {
                                                                        return (
                                                                            <div class="form-check my-2">
                                                                                <input class="form-check-input" type="checkbox" value={item?.value} onChange={(e) => HandleAbusiveReasonChange(e)} />
                                                                                <label class="form-check-label">
                                                                                    <p>{item?.value}</p>
                                                                                    {/* <p><span>Not about the product</span></p> */}
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                    {formError?.abusiveError && <p className="form-error">{formError?.abusiveError}</p>}
                                                                </div>
                                                                {showComment && (<div className="user-message">
                                                                    <div className="form-group mb-1">
                                                                        <h4 className="sub-title">Your Message</h4>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <textarea className='form-control' placeholder='Enter' rows="5" value={comment} onBlur={() => Validation()} onChange={(e) => setComment(e.target.value)}></textarea>
                                                                        {formError?.commentError && <p className="form-error">{formError?.commentError}</p>}
                                                                    </div>
                                                                </div>)}
                                                                <p>
                                                                    We'll check if this review meets our{" "}
                                                                    <Link
                                                                        to="/community-policy"
                                                                        state={{ activeKey: "terms" }}
                                                                        onClick={() => {
                                                                            window.scroll(0, 0);
                                                                        }} className="text-decoration-none"
                                                                    >
                                                                        community guidelines.
                                                                    </Link>{" "}
                                                                    and{" "}
                                                                    If it doesn't, we'll remove it.
                                                                </p>
                                                                <Col sm={12} md={12} className="d-flex justify-content-start gap-2 buttons mt-3">
                                                                    <button className="btn cancel-btn" onClick={() => setReportVisible(null)}>
                                                                        Cancel
                                                                    </button>
                                                                    <button className="btn shop-btn" onClick={(e) => HandleReportComment(e, item)}>
                                                                        Submit
                                                                    </button>
                                                                </Col>
                                                            </>
                                                        )}
                                                    </div>

                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </Col></>) :
                        <>
                            <Col md={5} className="pe-xxl-0 pe-xl-0 pe-lg-0 pe-lg-0 pe-md-0 d-none">
                                <div className="ratings">
                                    <p>Customer Reviews </p>
                                    <h2 className="rate-count">No Ratings</h2>
                                    <i className="fa fa-star-o"></i>
                                    <i className="fa fa-star-o"></i>
                                    <i className="fa fa-star-o"></i>
                                    <i className="fa fa-star-o"></i>
                                    <i className="fa fa-star-o"></i>
                                    <p>All reviews come from verified purchasers</p>
                                    <div className="rate-progress">
                                        <div className="rate-left">
                                            <p>5</p>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style={{ width: `${fiveRating?.length * 100 / filteredRatings?.length}%` }} aria-valuenow={fiveRating?.length * 100 / filteredRatings?.length} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <p>{fiveRating?.length > 0 ? fiveRating?.length : ""}</p>
                                    </div>
                                    <div className="rate-progress">
                                        <div className="rate-left">
                                            <p>4</p>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style={{ width: `${fourRating?.length * 100 / filteredRatings?.length}%` }} aria-valuenow={fourRating?.length * 100 / filteredRatings?.length} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <p>{fourRating?.length > 0 ? fourRating?.length : ""}</p>
                                    </div>
                                    <div className="rate-progress">
                                        <div className="rate-left">
                                            <p>3</p>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style={{ width: `${threeRating?.length * 100 / filteredRatings?.length}%` }} aria-valuenow={threeRating?.length * 100 / filteredRatings?.length} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <p>{threeRating?.length > 0 ? threeRating?.length : ""}</p>
                                    </div>
                                    <div className="rate-progress">
                                        <div className="rate-left">
                                            <p>2</p>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style={{ width: `${twoRating?.length * 100 / filteredRatings?.length}%` }} aria-valuenow={twoRating?.length * 100 / filteredRatings?.length} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <p>{twoRating?.length > 0 ? twoRating?.length : ""}</p>
                                    </div>
                                    <div className="rate-progress">
                                        <div className="rate-left">
                                            <p>1</p>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style={{ width: `${oneRating?.length * 100 / filteredRatings?.length}%` }} aria-valuenow={oneRating?.length * 100 / filteredRatings?.length} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <p>{oneRating?.length > 0 ? oneRating?.length : ""}</p>
                                    </div>

                                </div>

                            </Col>
                            <Col md={7} className="ps-xxl-1 ps-xl-1 ps-lg-1 ps-md-1 pe-2 d-none">
                                <div className="ratings overflow-y-scroll d-flex align-items-center justify-content-center no-reviews">
                                    <h2>No Reviews Yet</h2>
                                </div>
                            </Col>
                        </>
                    }

                </Row>
            </div>
        </>
    );
};

export default ProductRatingComp;
