import { useContext, useEffect, useState } from 'react';
import { LogoContext } from "../context/LogoImageUrl";
import { GetCompanyDetails } from "../utils/generalMethod"

function useLogoImage() {

    const { logoUrl, setLogoUrl } = useContext(LogoContext);
    const [logoResponse, setLogoReposnse] = useState(null);

    useEffect(() => {
        const GetCountryDetails = async () => {
            await GetCompanyDetails({}, setLogoReposnse)
        }
        GetCountryDetails();
    }, [])

    useEffect(() => {
        if (!logoResponse) return;
        const item = logoResponse?.data?.data?.listCompanyDetails?.items
        setLogoUrl(item?.[0])
    }, [logoResponse])

    return [logoUrl, setLogoUrl];
}

export default useLogoImage;