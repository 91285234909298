import React, { useState } from "react";
  
export const UserDetailsContext = React.createContext({});
export const UserDetailsContextProvider = ({ children }) => {
    const [userDetails, setUserDetails] = useState([]);
  
    return (
        <UserDetailsContext.Provider value={{ userDetails, setUserDetails }}>
            {children}
        </UserDetailsContext.Provider>
    );
};