import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import CategoryProductCarousal from "./categoryProductCarousal";
import { Row, Col, Card, CardHeader, CardBody, Container } from "react-bootstrap";
import LatestProducts from "../../components/latestProducts";
import TopSellerProduct from "../../components/topSellerProducts";
import customLogger from "../../common/loggerService";
import NewArrivals from "../../components/newArrivals";
import { CreateEntry, GetData } from "../../providers/queryData";
import { resourceconstant, Status } from "../../constants";
import BannerCarousel from "../../components/bannerCarousel";
import { BestSellingProducts, CategoryWiseProducts, TopDealsProducts } from "../../utils/generalMethod";
import CategoryBestSellers from '../../components/categoryBestSellers'
import ReactGA from 'react-ga4';
const Home = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState(null)
    const [category, setCategory] = useState(null)
    const [productDetails, setProductDetails] = useState(null)
    const [bestSeller, setBestSeller] = useState(null)
    const [newArrivals, setNewArrivals] = useState([])
    const [productsList, setProductsList] = useState([])
    const [productDetailsList, setProductDetailsList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [bestSelling, setBestSelling] = useState(null)
    const [categorySeller, setCategorySeller] = useState(null);
    const [chunksData, setChunksData] = useState([])
    const [categorywiseName, setCategorywiseName] = useState('')
    const [topDealsProducts, setTopDealsProducts] = useState([])
    const [categoryWise, setCategoryWise] = useState([])
    const [categoryTitle, setCategoryTitle] = useState('')
    const hasBestSeller = useRef(false);
    const [title, setTitle] = useState('');
    const [carousals, setCarousals] = useState([])
    const [carousalImages, setCarousalImages] = useState([])
    const [subCategoryList, setSubCategoryList] = useState([])
    const [subCategory, setSubCategory] = useState([])
    const [loading, setLoading] = useState(false)
    const [topLoading, setTopLoading] = useState(false)
    const [catLoading, setCatLoading] = useState(false)
    const [newArrivalLoading, setNewArrivalLoading] = useState(false)
    const [filteredProductDetails, setFilteredProductDetails] = useState([])

    const chunks = [];
    let i = 0

    const GetCategoryList = () => {
        let filter = {
            pk: {
                eq: 'MAIN#',
            },
        }
        GetData(resourceconstant.LIST_CATEGORY, filter, setCategory)
    };

    const SubCategoryList = () => {
        let filter = {
            pk: { beginsWith: 'SUB#' }
        }
        GetData(resourceconstant.LIST_CATEGORY, filter, setSubCategory)
    };

    useEffect(() => {
        SubCategoryList()
    }, [])

    useEffect(() => {
        if (subCategory?.severity === "success") {
            setSubCategoryList(subCategory?.data?.data?.listCategories?.items)
        }
    }, [subCategory])

    const GetProductList = () => {
        let filter = {
            status: {
                eq: "published"
            }
        }
        GetData(resourceconstant.PRODUCT_LIST, filter, setProducts)
    };

    const ProductDetails = async () => {
        let filter = {}
        setNewArrivalLoading(true)
        GetData(resourceconstant.PRODUCT_DETAIL_LIST, filter, setProductDetails)
    };

    const CarousalImages = async () => {
        let filter = {
        }
        GetData(resourceconstant.CAROUSAL_IMAGES, filter, setCarousals)
    };

    useEffect(() => {
        if (carousals?.severity === "success") {
            const item = carousals?.data?.data?.listCarouselImages?.items
            const filtered = item?.filter(data => data?.status === "ACTIVE" && data?.type === 'Home Page' && data?.deviceType === "web")
            setCarousalImages(filtered)
        }
    }, [carousals])

    const CategoryCarousal = (index) => {
        let currentChunk = []
        currentChunk = chunksData?.[index];
        if (currentChunk) {
            return (
                <>
                    {currentChunk?.map((categoryItem, idx) => {
                        const shouldRender = filteredProductDetails?.filter(product => product?.mainCategoryID === categoryItem?.id);

                        const filteredProducts = shouldRender?.filter(product => {
                            return productDetailsList.some(details => details.productID === product.id);
                        });
                        const handleLinkClick = (e, platform) => {
                            e.preventDefault();
                            navigate(`/products/${decodeURIComponent(categoryItem.slug?.toLowerCase()).replace(/ /g, '-')}`, {
                                state: {
                                    values: {
                                        category_id: `${categoryItem.id}`,
                                        search: `${categoryItem.id}`,
                                    },
                                },
                                search: `?category_id=${categoryItem.id}`
                            });
                            ReactGA.event({
                                category: 'Mep Products',
                                action: 'Click',
                                label: platform,
                            });
                            window.scroll(0, 0);
                        };

                        return (
                            filteredProducts?.length > 3 && (
                                <Row key={idx} className="mt-2">
                                    <Col md={12}>
                                        <Row className="carousel-row">
                                            <Col md={12} className="ps-3 ps-md-2 pe-3 pe-md-0">
                                                <Card>
                                                    <CardHeader>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h2 className="text-title">{categoryItem.catName}</h2>
                                                            <Link to={`/products/${decodeURIComponent(categoryItem.slug?.toLowerCase()).replace(/ /g, '-')}`} onClick={(e) => handleLinkClick(e, categoryItem.slug)}>
                                                                View all
                                                            </Link>
                                                        </div>
                                                    </CardHeader>
                                                    <CardBody className="pt-0">
                                                        <Row>
                                                            <Col md={12}>
                                                                <CategoryProductCarousal handleClickAnalytics={handleClickAnalytics} productDetails={productDetailsList} productsList={filteredProductDetails} categoryItem={categoryItem} />
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        {categoryItem.webBannerImg && (
                                            <Row className="home-web-banner-row">
                                                <Col md={12} className="ps-3 ps-md-2 pe-3 pe-md-0">
                                                    <div>
                                                        <img src={categoryItem.webBannerImg} alt="Web Banner" className="w-100" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                </Row>
                            )
                        );
                    })}
                </>
            );
        }

        return null;
    };

    const renderProductSection = (categoryName, key) => {
        const filteredProducts = categorySeller?.slice(1, 3).filter(product => product.catName === categoryName);

        const title = key === "bestsellingproducts" ? "Best Sellers" : key === "recentlyaddedproducts" ? "New Products" : key === "recentlypurchasedproducts" && "Recently purchased product"
        return (
            <Col md={6} className="best-sellers-column ps-3 ps-md-2 pe-3 pe-md-0" key={categoryName}>
                <Card>
                    <CardHeader>
                        <div className="d-flex justify-content-between align-items-center">
                            <h2>{title} {title && 'in'} {categoryName}</h2>
                            {filteredProducts[0]?.products?.length > 4 && (<a href="/all-products" onClick={(e) =>
                                HandleCategoryBestSellers(e, filteredProducts[0]?.products, "all-products")}>View all</a>)}
                        </div>
                    </CardHeader>
                    <CardBody className="pt-0">
                        <Row>
                            <Col md={12}>
                                <CategoryBestSellers catLoading={catLoading} products={productsList} bestSeller={filteredProducts[0]?.products} />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        );
    };

    const HandleNewArrivals = async (e, platform) => {
        e.preventDefault()
        navigate('/all-products', {
            state: {
                newArrivals: newArrivals || [],
                allProducts: true
            }
        });
        ReactGA.event({
            category: 'Mep Products',
            action: 'Click',
            label: platform,
        });
    }

    const bestSellingProd = bestSeller?.bestsellers?.bestsellingproducts || bestSeller?.bestsellers?.recentlyaddedproducts || bestSeller?.bestsellers?.recentlypurchasedproducts

    const HandleBestSellers = async (e, platform) => {
        e.preventDefault()
        navigate('/all-products', {
            state: {
                bestSellers: bestSellingProd || [],
                allProducts: true
            }
        });
        ReactGA.event({
            category: 'Mep Products',
            action: 'Click',
            label: platform,
        });
    }
    const HandleTopDeals = async (e, platform) => {
        e.preventDefault()
        navigate('/all-products', {
            state: {
                topDeals: topDealsProducts?.topdeals || [],
                allProducts: true
            }
        });
        ReactGA.event({
            category: 'Mep Products',
            action: 'Click',
            label: platform,
        });
    }

    const HandleCategoryBestSellers = async (e, products, platform) => {
        e.preventDefault()
        navigate('/all-products', {
            state: {
                categorywisebestsellingproducts: products || [],
                allProducts: true
            }
        });
        ReactGA.event({
            category: 'Mep Products',
            action: 'Click',
            label: platform,
        });
    }

    useEffect(() => {
        if (category?.severity === "success") {
            setCategoryList(category?.data?.data?.listCategories?.items)
        }
    }, [category])

    useEffect(() => {
        if (productDetails?.severity === "success" && productsList) {
            const data = productDetails?.data?.data?.listProductDetails?.items;
            const filteredProductsList = productsList?.filter(product =>
                data?.some(detail => detail.productID === product.id && detail.stockStatus === Status?.INSTOCKSTATUS ||
                    (detail.stockStatus === Status?.OUTOFSTOCKSTATUS && (product.allowPreOrder || product.showWhenZeroStock)))
            );
            const filteredProducts = data?.filter(product =>
                productsList?.some(detail => detail.id === product.productID && product.stockStatus === Status?.INSTOCKSTATUS ||
                    (product.stockStatus === Status?.OUTOFSTOCKSTATUS && (detail.allowPreOrder || detail.showWhenZeroStock)))
            );
            setFilteredProductDetails(filteredProductsList);

            setProductDetailsList(filteredProducts);
        }
    }, [productDetails, productsList]);

    useEffect(() => {
        if (products?.severity === "success") {
            setProductsList(products?.data?.data?.listProducts?.items)
        }
    }, [products])

    useEffect(() => {
        async function fetchData() {
            try {
                filteredProductDetails.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                const lastAddedProductMap = new Map();
                subCategoryList.forEach(subCategory => {
                    const relatedProducts = filteredProductDetails.filter(product => product.subCategoryID === subCategory.id);

                    if (relatedProducts.length > 0) {
                        lastAddedProductMap.set(subCategory.id, relatedProducts[0]);
                    }
                });
                const lastAddedProducts = Array.from(lastAddedProductMap.values());
                setNewArrivals(lastAddedProducts?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
                setNewArrivalLoading(false)
            } catch (error) {
                customLogger.error(error);
            }
        }
        fetchData();
    }, [filteredProductDetails, productDetailsList]);

    useEffect(() => {
        if (categoryList?.length > 0) {
            const totalCategories = categoryList?.length;
            const numSections = 4;
            const chunkSize = Math.floor(totalCategories / numSections);
            const remainder = totalCategories % numSections;

            let startIndex = 0;
            for (let i = 0; i < numSections; i++) {
                let size = chunkSize;
                if (i < remainder) {
                    size++;
                }
                const endIndex = startIndex + size;
                chunks.push(categoryList?.slice(startIndex, endIndex));
                startIndex = endIndex;
            }
            setChunksData(chunks)
        }
    }, [categoryList])

    useEffect(() => {
        if (bestSelling?.message)
            setLoading(false)
        setBestSeller(bestSelling)
    }, [bestSelling])

    useEffect(() => {
        if (topDealsProducts?.message)
            setTopLoading(false)
    }, [topDealsProducts])


    useEffect(() => {
        if (categoryWise?.message)
            setCatLoading(false)
    }, [categoryWise])

    useEffect(() => {
        if (categoryWise) {
            const categoryItems = categoryWise?.bestsellerscategorywise;
            if (categoryItems) {
                const title = categoryItems[0]?.key === "bestsellingproducts" ? "Best Sellers" : categoryItems[0]?.key === "recentlyaddedproducts" ? "New Products" : categoryItems[0]?.key === "recentlypurchasedproducts" && "Recently purchased product"
                setCategoryTitle(title);
                setCategorySeller(categoryItems);
            }
        }
    }, [categoryWise]);

    useEffect(() => {
        const data = bestSeller?.bestsellers
        if (data?.bestsellingproducts) {
            setTitle("Best Sellers");
        }
        else if (data?.recentlyaddedproducts) {
            setTitle("New Products");
        }
        else if (data?.recentlypurchasedproducts) {
            setTitle("Recently purchased product");
        }
    }, [bestSeller])

    useEffect(() => {
        GetCategoryList()
        GetProductList()
        ProductDetails()
        CarousalImages()
    }, [])

    useEffect(() => {
        if (!hasBestSeller.current) {
            const viewAll = {
                viewall: false
            }
            setLoading(true)
            setTopLoading(true)
            setCatLoading(true)
            BestSellingProducts("", setBestSelling, false)
            CreateEntry(resourceconstant?.TOP_DEALS, false, setTopDealsProducts, viewAll)
            CategoryWiseProducts("", setCategoryWise, false)
            hasBestSeller.current = true;
        }
    }, [productsList]);

    const handleClickAnalytics = (e, platform) => {
        ReactGA.event({
            category: 'Mep Products',
            action: 'Click',
            label: platform,
        });
    };

    return (
        <>
            <div className="home-wrapper">
                <BannerCarousel carousalImages={carousalImages} />
                <Container>
                    <Row className="product-list product-list-row">
                        <Col md={12}>
                            <Row>
                                <Col md={6} className="new-arrivals-column mb-xxl-0 mb-xl-0 mb-lg-0 mb-1 ps-3 ps-md-2 pe-3 pe-md-0">
                                    <Card>
                                        <CardHeader>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h2>New Arrivals</h2>
                                                <a href="/all-products" onClick={(e) => HandleNewArrivals(e, "all-products")}>View all</a>
                                            </div>
                                        </CardHeader>
                                        <CardBody className="pt-0">
                                            <Row>
                                                <Col md={12}>
                                                    <NewArrivals handleClickAnalytics={handleClickAnalytics} newArrivalLoading={newArrivalLoading} productDetails={productDetailsList} newArrivals={newArrivals} />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={6} className="top-deals-column mb-xxl-0 mb-xl-0 mb-lg-0 mb-1 ps-3 ps-md-2 pe-3 pe-md-0">
                                    <Card>
                                        <CardHeader>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h2>Top Deals</h2>
                                                {topDealsProducts?.topdeals?.length >= 4 && (<a href="/all-products" onClick={(e) => HandleTopDeals(e, "all-products")}>View all</a>)}
                                            </div>
                                        </CardHeader>
                                        <CardBody className="pt-0">
                                            <Row>
                                                <Col md={12}>
                                                    <TopSellerProduct handleClickAnalytics={handleClickAnalytics} topLoading={topLoading} products={filteredProductDetails} topDeals={topDealsProducts?.topdeals} />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>
                            {CategoryCarousal(0)}
                            <Row className="mt-2">
                                <Col md={6} className="latest-products-column ps-3 ps-md-2 pe-3 pe-md-0 mb-2">
                                    <Card>
                                        <CardHeader>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h2>{title}</h2>
                                                {bestSellingProd?.length > 4 && (
                                                    <Link
                                                        to={{
                                                            pathname: '/all-products',
                                                            state: {
                                                                bestSellers: bestSellingProd || [],
                                                                allProducts: true
                                                            }
                                                        }}
                                                        onClick={(e) => HandleBestSellers(e, "all-products")}
                                                    >
                                                        View all
                                                    </Link>
                                                )}
                                            </div>
                                        </CardHeader>
                                        <CardBody className="pt-0">
                                            <Row>
                                                <Col md={12}>
                                                    <LatestProducts handleClickAnalytics={handleClickAnalytics} loading={loading} products={filteredProductDetails} bestSeller={bestSellingProd} />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={6} className="best-sellers-column ps-3 ps-md-2 pe-3 pe-md-0 mb-2">
                                    <Card>
                                        <CardHeader>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h2>{categoryTitle} {categoryTitle && 'in'} {categorySeller?.[0]?.catName}</h2>
                                                {categorySeller?.[0]?.products?.length > 4 && (<a href="/all-products" onClick={(e) => HandleCategoryBestSellers(e, categorySeller?.[0]?.products, "all-products")}>View all</a>)}
                                            </div>
                                        </CardHeader>
                                        <CardBody className="pt-0">
                                            <Row>
                                                <Col md={12}>
                                                    <CategoryBestSellers handleClickAnalytics={handleClickAnalytics} catLoading={catLoading} category={true} products={filteredProductDetails} bestSeller={categorySeller?.[0]?.products} />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            {CategoryCarousal(1)}
                            {CategoryCarousal(2)}
                            <Row className="mt-2">
                                {categorySeller?.slice(1, 3)?.map(category => (
                                    renderProductSection(category.catName, category?.key)
                                ))}
                            </Row>
                            {CategoryCarousal(3)}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Home;
