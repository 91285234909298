/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "me-south-1",
    "aws_appsync_graphqlEndpoint": "https://kx5ewmpq6remrgvdhof2ciudv4.appsync-api.me-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "me-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-wfcsl7ny2nfr7levftr3hkcf5i",
    "aws_cognito_identity_pool_id": "me-south-1:1976c6c1-4563-429e-a834-12db5280a6b2",
    "aws_cognito_region": "me-south-1",
    "aws_user_pools_id": "me-south-1_JeD6qHIMq",
    "aws_user_pools_web_client_id": "3cti6p6ma0kog4kb4h37kob9gg",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "jerseymepa1d95feba3e148e4937bf474442d8a6ca2b1e-prod",
    "aws_user_files_s3_bucket_region": "me-south-1"
};


export default awsmobile;
