import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  CardHeader,
  CardBody,
  CardFooter,
  Accordion,
} from "react-bootstrap";
import CheckoutOrderSummery from "./checkoutOrderSummary";
import { Link, useLocation } from "react-router-dom";
import PriceOrderSummery from "./priceOrderSummery";
import PaymentMethod from "./paymentMethod";
import ShippingAddress from "./shippingAddress";
import useProfile from "../../hooks/useProfile";
import { GetUserDetails } from "../../utils/generalMethod";
import { GetData } from "../../providers/queryData";
import { resourceconstant } from "../../constants";
import AddressIcon from "../../assets/images/icons/addaddress.png";
import useCountry from "../../hooks/useCountry";
import useCart from "../../hooks/useCartPage";
import { useNavigate } from "react-router-dom";
import { showErrorToastr } from "../../common/toastrService";
import { ToastrMessages } from "../../constants/toastrMessages";
import Spinner from "../../common/spinner";
const Checkout = (props) => {
  const [cartPageItem, setCartPageItem] = useCart()
  const [productDetailList, setProductDetailList] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [checked, setChecked] = useState(false);
  const [isPlaceOrder, setIsPlaceOrder] = useState(false);
  const [newAddress, setNewAddress] = useState(false);
  const [userProfile, setUserProfile] = useProfile();
  const [userDetails, setUserDetails] = useState([]);
  const [billValues, setBillValues] = useState([]);
  const [shippValues, setShippValues] = useState([]);
  const [isShipped, setIsShipped] = useState(false);
  const [isBilled, setIsBilled] = useState(false);
  const [isChangeAddress, setIsChangeAddress] = useState(false);
  const [isChangeSummery, setIsChangeSummery] = useState(false);
  const [checkedShipp, setCheckedShipp] = useState("");
  const [checkedBill, setCheckedBill] = useState("");
  const [cartData, setCartData] = useState([]);
  const [productDetail, setProductDetail] = useState([]);
  const [changeBill, setChangeBill] = useState([]);
  const [changeShipp, setChangeShipp] = useState([]);
  const [isSelfPickup, setIsSelfPickup] = useState(true);
  const [isSameCheck, setIsSameCheck] = useState(true);
  const [countryList, setCountryList] = useCountry();
  const [isClickContinue, setIsClickContinue] = useState(false)
  const [couponCode, setCouponCode] = useState(false);
  const [promoCode, setPromoCode] = useState([])
  const [promoCodeList, setPromoCodeList] = useState([])
  const [selectedCoupon, setSelectedCoupon] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPromoCodes, setFilteredPromoCodes] = useState('');
  const [selectCouponValue, setSelectCouponValue] = useState('')
  const [filteredPromoCode, setFilteredPromoCode] = useState('')
  const [filteredDatePromoCode, setFilteredDatePromoCode] = useState('')
  const [commonSearch, setCommonSearch] = useState('')
  const [originalData, setOriginalData] = useState([]);
  const [discountValue, setDiscountValue] = useState('');
  const [selectedCode, setSelectedCode] = useState('')
  const [deliveyFee, setDeliveryFee] = useState([])
  const [deliveryFeeItem, setDeliveryFeeItem] = useState([])
  const [shippingData, setShippingData] = useState([])
  const [selectedPromo, setSelectedPromo] = useState([])
  const navigate = useNavigate()
  const [updateCart, setUpdateCart] = useState(false)
  const [productList, setProductList] = useState([])
  const [productsItem, setProductItem] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedCouponProvider, setSelectedCouponProvider] = useState('')
  const [wareHouse, setWareHouse] = useState([])
  const [listWareHouse, setListWareHouse] = useState([])

  const location = useLocation();
  const items = location?.state?.values;
  const checkoutData = location?.state?.checkoutData;
  let countryItemShipp = countryList?.filter(
    (country) => country?.id === shippValues?.address?.country
  );
  let countryItemBill = countryList?.filter(
    (country) => country?.id === billValues?.address?.country
  );
  let countryItem = countryList;
  const ListCarts = () => {
    let filter = {};
    GetData(resourceconstant.LIST_CARTS, filter, setCartData);
  };

  const ProductDetailsList = async () => {
    let filter = {};
    GetData(resourceconstant.PRODUCT_DETAIL_LIST, filter, setProductDetail);
    setLoading(true)
  };

  const ProductList = () => {
    let filter = {
      status: {
        eq: "published"
      }
    }
    GetData(resourceconstant.PRODUCT_LIST, filter, setProductList)
    setLoading(true)
  };

  useEffect(() => {
    if (productDetail?.severity === "success") {
      setLoading(false)
      setProductDetailList(
        productDetail?.data?.data?.listProductDetails?.items
      );
    }
  }, [productDetail]);

  useEffect(() => {
    if (productList?.severity === "success") {
      setLoading(false)
      setProductItem(
        productList?.data?.data?.listProducts?.items
      );
    }
  }, [productList]);

  useEffect(() => {
    const redirect = localStorage.getItem('checkoutDirect')
    if (redirect === "false") {
      navigate('/')
    }
  }, [])


  const HandleNewAddress = () => {
    setNewAddress(true);
  };

  const HandleChange = (value) => {
    if (value === "addressDetail") {
      if (isChangeAddress && JSON.stringify(shippValues) !== JSON.stringify(billValues)) {
        setIsSameCheck(false);
      }
      else {
        setIsSameCheck(true)
      }
      setIsChangeAddress(true);
      setIsClickContinue(false)
    } else if (value === "summery") {
      setIsChangeSummery(true);
    }
  };

  useEffect(() => {
    if (userProfile?.attributes?.sub) {
      let data = {
        id: { eq: `${userProfile?.attributes?.sub}` },
      };
      GetUserDetails(data, setUserDetails);
    }
  }, [userProfile?.attributes?.sub]);

  useEffect(() => {
    ListCarts();
    ProductDetailsList();
    ProductList()
  }, []);
  const userAddress = userDetails?.data?.data?.listUsers?.items?.[0];

  useEffect(() => {
    if (checkoutData && checkoutData.length > 0) {
      const selectedProduct = checkoutData[0];

      const newCartItems = {
        cartTotal: selectedProduct?.price,
        productDiscount: selectedProduct?.price - selectedProduct?.salesPrice,
        totalTax: selectedProduct?.taxCharge,
        totalAmount: selectedProduct?.salesPrice,
        salePrice: selectedProduct?.salesPrice,
      };

      setCartItems(newCartItems);
    } else {
      setCartItems(items);
    }
  }, []);

  const HandleSelfPickup = (name) => {
    if (name === "self") {
      setIsSelfPickup(true);
      setIsShipped(true);
      setShippValues(billValues);
      setChangeShipp(billValues);
      setIsSameCheck(false);
    } else if (name === "door") {
      setIsSelfPickup(false);
      if(JSON.stringify(shippValues) === JSON.stringify(billValues)){
        setBillValues(shippValues)
        setChangeBill(shippValues)
        setIsBilled(true)
        setIsSameCheck(true);
      }
      else{
        setBillValues(shippValues)
        setChangeBill(shippValues)
        setIsBilled(true)
        setIsSameCheck(false);
      }
      setBillValues(shippValues)
      setChangeBill(shippValues)
      setIsBilled(true)
    }
  };

  const HandleSameCheckbox = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setBillValues(shippValues)
      setIsSameCheck(true);
      setIsBilled(true);
    } else {
      setBillValues([]);
      setCheckedBill('');
      setIsSameCheck(false);
      setIsBilled(false);
    }
  };

  const HandleContinueClick = () => {
    const condition = isSelfPickup === false ? deliveryFeeItem?.deliveryCharge !== undefined && deliveryFeeItem?.deliveryCharge !== null : true

    if (shippValues?.length !== 0 && billValues?.length !== 0 && condition) {
      setIsClickContinue(true)
      setIsChangeAddress(false)
    }
    else if ((deliveryFeeItem?.deliveryCharge === undefined || deliveryFeeItem?.deliveryCharge === null) && !isSelfPickup) {
      showErrorToastr(ToastrMessages?.DELIVERYNOTAVAILABLE)
    }
    else if (billValues?.length === 0) {
      showErrorToastr(ToastrMessages?.BILLINGADDRESS)
    }
  }

  const shouldBeRed =
    isClickContinue && ((isShipped && isBilled && changeShipp && changeBill && !isChangeAddress) ||
      (isPlaceOrder && isChangeSummery));

  const paymentFont = isPlaceOrder && !isChangeSummery && !isChangeAddress;

  const DeliveryData = () => {
    let filter = {
    }
    GetData(resourceconstant.DELIVERY_FEE, filter, setDeliveryFee)
  };

  useEffect(() => {
    if (deliveyFee?.severity === "success") {
      const deliveryItem = deliveyFee?.data?.data?.listShippings?.items || [];
      setShippingData(deliveryItem)
    }
  }, [deliveyFee]);

  useEffect(() => {
    if (isSelfPickup) {
      setDeliveryFeeItem([]);
      return;
    }

    if (shippingData && shippValues) {
      const { postalCode, country, city, state } = shippValues?.address || {};

      const zipDeliveryFee = shippingData.find(
        (item) =>
          item?.zipCodes?.some((zip) => zip === postalCode) &&
          item?.countryName?.trim()?.toLowerCase() === country?.trim()?.toLowerCase()
      );

      let stateDeliveryFee = null;

      if (!zipDeliveryFee) {
        stateDeliveryFee = shippingData.find((item) =>
          item?.state?.trim()?.toLowerCase() === state?.trim()?.toLowerCase() && item?.city?.trim()?.toLowerCase() === city?.trim()?.toLowerCase() && item?.countryName?.trim()?.toLowerCase() === country?.trim()?.toLowerCase()
        );

        if (!stateDeliveryFee) {
          stateDeliveryFee = shippingData.find((item) =>
            !item?.state &&
            item?.city?.trim()?.toLowerCase() === city?.trim()?.toLowerCase() && item?.countryName?.trim()?.toLowerCase() === country?.trim()?.toLowerCase()
          );
        }
      }

      let countryDeliveryFee = null;

      if (!zipDeliveryFee && !stateDeliveryFee) {
        countryDeliveryFee = shippingData.find((item) =>
          (!item?.city || item?.city?.trim() === '') &&
          (item?.state?.trim()?.toLowerCase() === state?.trim()?.toLowerCase() && item?.countryName?.trim()?.toLowerCase() === country?.trim()?.toLowerCase())

        );

        if (!countryDeliveryFee) {
          countryDeliveryFee = shippingData.find((item) =>
            (!item?.city || item?.city?.trim() === '') &&
            !item?.state &&
            item?.countryName?.trim()?.toLowerCase() === country?.trim()?.toLowerCase()
          );
        }
      }
      if (zipDeliveryFee) {
        setDeliveryFeeItem(zipDeliveryFee);
      } else if (stateDeliveryFee) {
        setDeliveryFeeItem(stateDeliveryFee);
      } else if (countryDeliveryFee) {
        setDeliveryFeeItem(countryDeliveryFee);
      } else {
        setDeliveryFeeItem([]);
      }
    }
  }, [shippingData, shippValues, isSelfPickup]);

  useEffect(() => {
    DeliveryData()
  }, [])

  useEffect(() => {
    if (productDetailList?.length > 0 && productsItem?.length > 0 && cartPageItem?.length > 0) {
      let updatedCartPageItem = [...cartPageItem];
      let shouldUpdate = false;

      cartPageItem.forEach((cart, index) => {
        const stock = productDetailList.filter(item => item.productID === cart.productID);
        const stockItem = cart.attrValueID
          ? stock.find(item => item.attrValueID === cart.attrValueID)
          : stock[0];

        const outStock = productsItem.find(item => item.id === cart.productID);
        const cartTotal = outStock?.taxDetail?.reduce(
          (total, item) => total + (item?.taxCharge * stockItem?.salePrice / 100),
          0
        );

        const updatedCart = {
          ...cart,
          price: stockItem?.regularPrice,
          salesPrice: stockItem?.salePrice,
          brandDetail: {
            brandName: outStock?.brandName,
            brandID: outStock?.brandID
          },
          sku: stockItem?.sku,
          taxCharge: cartTotal || 0,
          model: stockItem?.model,
          productName: outStock?.productName,
          mainCategory: outStock?.mainCategory,
          subCategoryN: outStock?.subCategory,
          mainCategoryId: outStock?.mainCategoryID,
          subCategoryId: outStock?.subCategoryID,
          stockUnit: stockItem?.stockUnit,
          itemsPerUnit: stockItem?.itemsPerUnit,
          currentStock: stockItem?.stockQuantity - stockItem?.reservedStock,
          attrValue: stockItem?.attrValue,
          attrName: stockItem?.attrName,
          wareHouseName: stockItem?.storageInfo?.wareHouse,
          wareHouseId: stockItem?.storageInfo?.wareHouseId,
          productImage: outStock?.thumbnailUrl
            ? outStock?.thumbnailUrl
            : outStock?.featuredImage?.url,
        };

        if (JSON.stringify(updatedCartPageItem[index]) !== JSON.stringify(updatedCart)) {
          updatedCartPageItem[index] = updatedCart;
          shouldUpdate = true;
        }
      });

      if (shouldUpdate) {
        setCartPageItem(updatedCartPageItem);
      }
    }
  }, [productDetailList, productsItem, cartPageItem]);

  const hasNavigated = useRef(false);

  useEffect(() => {

    if (productDetailList?.length > 0 && productsItem?.length > 0 && checkoutData?.data && !hasNavigated.current) {
      let updatedCheckoutItem = [...checkoutData?.data];
      let shouldNavigate = false;

      checkoutData.data.forEach((cart, index) => {
        const stock = productDetailList.filter(item => item.productID === cart.productID);
        const stockItem = cart.attrValueID
          ? stock.find(item => item.attrValueID === cart.attrValueID)
          : stock[0];

        const outStock = productsItem.find(item => item.id === cart.productID);
        const cartTotal = outStock?.taxDetail?.reduce(
          (total, item) => total + (item?.taxCharge * stockItem?.salePrice / 100),
          0
        );

        const updatedCheckout = {
          ...cart,
          price: stockItem?.regularPrice,
          salesPrice: stockItem?.salePrice,
          brandName: outStock?.brandName,
          brandID: outStock?.brandID,
          sku: stockItem?.sku,
          taxCharge: cartTotal || 0,
          model: stockItem?.model,
          productName: outStock?.productName,
          mainCategory: outStock?.mainCategory,
          subCategoryN: outStock?.subCategory,
          currentStock: stockItem?.stockQuantity - stockItem?.reservedStock,
          attrValue: stockItem?.attrValue,
          attrName: stockItem?.attrName,
          wareHouseName: stockItem?.storageInfo?.wareHouse,
          wareHouseId: stockItem?.storageInfo?.wareHouseId,
          productImage: outStock?.thumbnailUrl
            ? outStock?.thumbnailUrl
            : outStock?.featuredImage?.url,
          subCategoryId: outStock?.subCategoryID,
          mainCategoryId: outStock?.mainCategoryID,
        };

        if (JSON.stringify(updatedCheckoutItem[index]) !== JSON.stringify(updatedCheckout)) {
          updatedCheckoutItem[index] = updatedCheckout;
          shouldNavigate = true;
        }
      });

      if (shouldNavigate) {
        hasNavigated.current = true;
        navigate(location.pathname, {
          state: {
            ...location.state,
            checkoutData: {
              ...checkoutData,
              data: updatedCheckoutItem,
            },
          },
        });
      }
    }
  }, [productDetailList, productsItem, checkoutData, navigate, location]);

  const userCartList = checkoutData?.data ? checkoutData?.data : cartPageItem

  const cartTotal = productDetailList?.length > 0 ? userCartList?.reduce((total, item) => total + (item.salesPrice * item.selectedQuantity), 0) : 0;
  const regularTotal = productDetailList?.length > 0 ? userCartList?.reduce(
    (total, item) => total + (item.price * item.selectedQuantity),
    0
  ) : 0;
  const salePrice = productDetailList?.length > 0 ? userCartList?.reduce(
    (total, item) => total + (item.salesPrice * item.selectedQuantity),
    0
  ) : 0;
  const quantity = productDetailList?.length > 0 ? userCartList?.find(item => item.selectedQuantity) : 0

  const productDiscount = productDetailList?.length > 0 ? userCartList?.reduce((total, item) => {
    const discountPercentage = regularTotal - salePrice;
    return discountPercentage;
  }, 0) : 0;
  const totalTax = productDetailList?.length > 0 ? userCartList?.reduce(
    (total, item) => total + (item.taxCharge * item.selectedQuantity),
    0
  ) : 0;
  const deliveryAmount = deliveryFeeItem?.deliveryCharge ? deliveryFeeItem?.deliveryCharge : 0
  const withoutCoupon = cartTotal + totalTax + deliveryAmount
  const totalAmount = withoutCoupon - discountValue;

  const ListWareHouse = () => {
    let filter = {}
    GetData(resourceconstant.LIST_WAREHOUSES, filter, setWareHouse)
  };
  useEffect(() => {
    if (wareHouse?.severity === "success" && userCartList) {
      const data = wareHouse?.data?.data?.listWareHouses?.items
      const wareHouseItem = data?.filter(item => item?.id === userCartList[0]?.wareHouseId)
      setListWareHouse(wareHouseItem)
    }
  }, [wareHouse])

  useEffect(() => {
    ListWareHouse()
  }, [userCartList])

  const pickupAddress1 = [
    listWareHouse[0]?.addressLine1,
    listWareHouse[0]?.addressLine2,
  ].filter(Boolean).join(", ");

  const pickupAddress2 = [
    listWareHouse[0]?.city,
    listWareHouse[0]?.state,
    listWareHouse[0]?.country,
    - listWareHouse[0]?.zipCode
  ].filter(Boolean).join(", ");

  return (
    <section id="checkoutSec" className="pt-1">
      <Container>
        <Row className="gx-1">
          <Col xxl={12} xl={12} lg={12} md={12} xs={12} sm={12} className="delivery-details-card mb-1">
            <Row className="gx-1">
              <Col xxl={12} xl={12} lg={12} md={12} xs={12} sm={12} className="mb-1">
                <Card>
                  <CardHeader>
                    <h1>Checkout</h1>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
            <Row className="gx-1">
              <Col Col xxl={9} xl={9} lg={9} md={12} xs={12} sm={12}>
                <Row>
                  <Col md={12} className="mb-1">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col md={12} className="mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                              <h2>
                                <i class="fa fa-home me-2" aria-hidden="true"></i>
                                Delivery Details
                              </h2>
                              {isClickContinue && !isPlaceOrder && (
                                <button
                                  type="button"
                                  className="btn"
                                  id="addressDetail"
                                  onClick={() => HandleChange("addressDetail")}
                                >
                                  Change
                                </button>
                              )}
                            </div>
                          </Col>
                          <Col md={12}>
                            {isClickContinue === false && (
                              <>
                                <ul className="nav nav-pills gap-3 mb-3" id="pills-tab" role="tablist">
                                  <li className="nav-item" role="presentation">
                                    <button
                                      className={`nav-link ${isSelfPickup ? "active" : ""}`}
                                      id="pills-self-pickup-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-self-pickup"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-self-pickup"
                                      aria-selected="true"
                                      onClick={() => HandleSelfPickup("self")}
                                    >
                                      <i className="fa fa-user" aria-hidden="true"></i> Self Pickup
                                    </button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <button
                                      className={`nav-link ${!isSelfPickup ? "active" : ""}`}
                                      id="pills-doorstep-delivery-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-doorstep-delivery"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-doorstep-delivery"
                                      aria-selected="false"
                                      onClick={() => HandleSelfPickup("door")}
                                    >
                                      <i className="fa fa-bus" aria-hidden="true"></i> Doorstep Delivery
                                    </button>
                                  </li>
                                  {userAddress && userAddress?.addressDetails?.length > 0 && !isSelfPickup && (
                                    <li className="nav-item new-address-button" role="presentation">
                                      <Link
                                        to="/address"
                                        state={{ activeKey: "address", newAddress: true, checkoutData: checkoutData }}
                                      >
                                        <button
                                          type="button"
                                          className="btn shop-btn"
                                          onChange={HandleNewAddress}
                                        >
                                          <i className="fa fa-plus"></i>Add Address
                                        </button>
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                                {checkoutData && (
                                  <div>
                                    <div className="d-flex align-items-start gap-1 mb-1">
                                      <i className="fa fa-map-marker"></i>
                                      <h3>Pickup Address</h3>
                                    </div>
                                    <p className="fw-bold">
                                      {listWareHouse[0]?.name}
                                    </p>
                                    <p>{pickupAddress1}<br /> {pickupAddress2}</p>
                                    <p>{listWareHouse[0]?.phoneNumber}</p>
                                  </div>
                                )}
                                {!userAddress || !userAddress?.addressDetails || userAddress?.addressDetails?.length === 0 ? (
                                  <div className="save-address">
                                    <img src={AddressIcon} className="img-fluid mb-2" />
                                    <h2>No Addresses Added Yet</h2>
                                    <p>
                                      Tap "Add Address" button below to your first
                                      {isSelfPickup ? " pickup address" : " shipping address"} and <br /> continue shopping
                                    </p>
                                    <Link
                                      to="/address"
                                      state={{ activeKey: "address", newAddress: true, checkoutData: checkoutData }}
                                    >
                                      <button
                                        type="button"
                                        className="btn address-btn mt-2"
                                        onChange={HandleNewAddress}
                                      >
                                        <i className="fa fa-plus"></i>Add Address
                                      </button>
                                    </Link>
                                  </div>
                                ) : (
                                  <ShippingAddress
                                    userAddress={userAddress}
                                    setShippValues={setShippValues}
                                    setBillValues={setBillValues}
                                    setIsShipped={setIsShipped}
                                    setIsBilled={setIsBilled}
                                    billValues={billValues}
                                    shippValues={shippValues}
                                    setCheckedShipp={setCheckedShipp}
                                    setCheckedBill={setCheckedBill}
                                    checkedShipp={checkedShipp}
                                    checkedBill={checkedBill}
                                    setIsChangeAddress={setIsChangeAddress}
                                    setChangeShipp={setChangeShipp}
                                    setChangeBill={setChangeBill}
                                    changeShipp={changeShipp}
                                    changeBill={changeBill}
                                    isSelfPickup={isSelfPickup}
                                    HandleSameCheckbox={HandleSameCheckbox}
                                    isSameCheck={isSameCheck}
                                    setIsSameCheck={setIsSameCheck}
                                    setIsSelfPickup={setIsSelfPickup}
                                    countryItem={countryItem}
                                    HandleContinueClick={HandleContinueClick}
                                    setIsClickContinue={setIsClickContinue}
                                    deliveryFeeItem={deliveryFeeItem}
                                    shippingData={shippingData}
                                    isChangeAddress={isChangeAddress}
                                    isShipped={isShipped}
                                    HandleNewAddress={HandleNewAddress}
                                    checkoutData={checkoutData}
                                  />
                                )}
                              </>
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {!loading ? (
                    <Col md={12}>
                      <Card className="order-summary-details-card mb-1">
                        <CardHeader>
                          <h1>
                            <i
                              className={`fa fa-cart-plus me-2 ${shouldBeRed ? 'summary-details-icon' : ''}`}
                              style={{
                                color: shouldBeRed ? '' : 'initial',
                              }}
                              aria-hidden="true"
                            ></i>
                            Order Summary
                          </h1>
                          {isPlaceOrder && !isChangeSummery && (
                            <button
                              type="button"
                              className="btn"
                              id="summery"
                              onClick={() => HandleChange("summery")}
                            >
                              Change
                            </button>
                          )}
                        </CardHeader>
                        {isClickContinue && (
                          <>
                            {((isShipped && isBilled && !isPlaceOrder && changeShipp && changeBill && !isChangeAddress) ||
                              (isPlaceOrder && isChangeSummery && isClickContinue)) && (
                                <CheckoutOrderSummery
                                  cartTotal={cartTotal}
                                  regularTotal={regularTotal}
                                  salePrice={salePrice}
                                  productDiscount={productDiscount}
                                  totalTax={totalTax}
                                  totalAmount={totalAmount}
                                  isSelfPickup={isSelfPickup}
                                  setIsChangeSummery={setIsChangeSummery}
                                  isChangeSummery={isChangeSummery}
                                  productDetailList={productDetailList}
                                  shippValues={shippValues}
                                  billValues={billValues}
                                  cartItems={cartItems}
                                  setIsPlaceOrder={setIsPlaceOrder}
                                  countryItemShipp={countryItemShipp}
                                  countryItemBill={countryItemBill}
                                  ListCarts={ListCarts}
                                  checkoutData={checkoutData}
                                  itemQuantity={quantity}
                                  selectedCode={selectedCode}
                                  discountValue={discountValue}
                                  deliveryFeeItem={deliveryFeeItem}
                                  setUpdateCart={setUpdateCart}
                                  setChecked={setChecked}
                                  location={location}
                                  setCartData={setCartData}
                                  setProductItem={setProductItem}
                                  productsItem={productsItem}
                                  setProductList={setProductList}
                                  productList={productList}
                                  loading={loading}
                                  selectedCouponProvider={selectedCouponProvider}
                                />
                              )}
                          </>
                        )}
                      </Card>
                    </Col>
                  ) : loading && <Spinner />}
                  <Col md={12}>
                    <Card className="select-payment-method-card mb-2">
                      <CardHeader>
                        <h1>
                          <i
                            className={`fa fa-money me-2 ${paymentFont ? 'summary-details-icon' : ''}`}
                            style={{
                              color: paymentFont ? '' : 'initial',
                            }}
                            aria-hidden="true"
                          ></i>
                          Select a Payment Method
                        </h1>
                      </CardHeader>
                      {isPlaceOrder && !isChangeSummery && !isChangeAddress && (
                        <PaymentMethod
                          cartItems={cartItems}
                          checkoutData={checkoutData}
                        />
                      )}
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col xxl={3} xl={3} lg={3} md={12} xs={12} sm={12}>
                <PriceOrderSummery
                  cartTotal={cartTotal}
                  regularTotal={regularTotal}
                  salePrice={salePrice}
                  productDiscount={productDiscount}
                  totalTax={totalTax}
                  totalAmount={totalAmount}
                  cartItems={cartItems}
                  checkoutData={checkoutData}
                  quantity={quantity}
                  cartPageItem={cartPageItem}
                  userCartList={userCartList}
                  setCouponCode={setCouponCode}
                  couponCode={couponCode}
                  promoCodeList={promoCodeList}
                  setPromoCodeList={setPromoCodeList}
                  selectedCoupon={selectedCoupon}
                  setSelectedCoupon={setSelectedCoupon}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  filteredPromoCodes={filteredPromoCodes}
                  setFilteredPromoCodes={setFilteredPromoCodes}
                  selectCouponValue={selectCouponValue}
                  setSelectCouponValue={setSelectCouponValue}
                  filteredPromoCode={filteredPromoCode}
                  setFilteredPromoCode={setFilteredPromoCode}
                  filteredDatePromoCode={filteredDatePromoCode}
                  setFilteredDatePromoCode={setFilteredDatePromoCode}
                  commonSearch={commonSearch}
                  setCommonSearch={setCommonSearch}
                  originalData={originalData}
                  setOriginalData={setOriginalData}
                  setPromoCode={setPromoCode}
                  promoCode={promoCode}
                  setDiscountValue={setDiscountValue}
                  discountValue={discountValue}
                  setSelectedCode={setSelectedCode}
                  selectedCode={selectedCode}
                  deliveryFeeItem={deliveryFeeItem}
                  setSelectedPromo={setSelectedPromo}
                  selectedPromo={selectedPromo}
                  isPlaceOrder={isPlaceOrder}
                  isChangeSummery={isChangeSummery}
                  setSelectedCouponProvider={setSelectedCouponProvider}
                  productDetailList={productDetailList}
                  loading={loading}
                />
              </Col>
            </Row>



            {/* </Row> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Checkout;
