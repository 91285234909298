import { useContext, useEffect, useState } from 'react';
import { ProductContext } from "../context/ProductsContext";
import { GetAllProducts } from "../providers/queryData"
import { resourceconstant } from '../constants';

function useProducts() {

    const { productsList, setProductsList } = useContext(ProductContext);
    const [responseData, setResponseData] = useState(null);

    useEffect(() => {
        const productsData = localStorage.getItem("products")
        let tempArray;
        let filter = {};
        if (productsData) {
            const data = JSON.parse(productsData);
            tempArray = [...data].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        }
        if (tempArray?.length > 0) 
        {
            filter = {
                updatedAt: {gt: tempArray[0].updatedAt}
            };
        }
        GetAllProducts(resourceconstant?.LIST_ALL_PRODUCTS, filter, setResponseData)
    }, [])

    useEffect(() => {
        if (responseData?.severity === "success") {
            const productsData = localStorage.getItem("products")
            const data = JSON.parse(productsData);
            const mergedArray = [
                ...(Array.isArray(data) ? data : []), // Ensure data is an array
                ...(Array.isArray(responseData?.allData) ? responseData.allData.filter(item2 => !data?.some(item1 => item1.id === item2.id)) : [])
              ].map(item => {
                const matchingItem = responseData?.allData?.find(item2 => item2.id === item.id);
                return matchingItem ? { ...matchingItem } : { ...item };
              });

            const publishedProduct = mergedArray?.filter((item) => item?.status === "published" && (item?.showWhenZeroStock === true || item?.allowPreOrder === true || ((item?.totalStockQuantity * 1)-(item?.totalReservedStock*1)) > 0))
            setProductsList(publishedProduct);
            setResponseData(null)
            localStorage.setItem('products', JSON.stringify(publishedProduct));
        }
    }, [responseData])

    return [productsList, setProductsList];
}

export default useProducts;