import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Col, Container, Row } from "react-bootstrap";
function BestSellersSkeleton() {
    return (
        <Col md={6} className='col-6 px-1 skeleton-column'>
            <div className='carousel__item'>
                <div className='image-box'>
                    <Skeleton variant="rectangular" width={200} height={158} />
                </div>
                <div className='carousel__caption'>
                    <p className="prodt-name">
                        <Skeleton width={125} height={15} />
                    </p>
                    <p>Brand : <Skeleton width={190} height={15} />
                    </p>
                    <h4 class="price"><Skeleton width={190} height={15} /></h4>
                </div>
            </div>
        </Col>
    )
}

export default BestSellersSkeleton