import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PlaceHolderText } from '../../constants/placeHolderText';
import Accordion from 'react-bootstrap/Accordion';
import { showErrorToastr } from '../../common/toastrService';
import { ToastrMessages } from '../../constants/toastrMessages';
function Example({ setSelectedPromo, listingCartCheckout, originalData, filteredDatePromoCode, setFilteredDatePromoCode, commonSearch, setCommonSearch, selectedCoupon, setSelectedCoupon, promoCodeList, setCouponCode, couponCode }) {
    const handleClose = () => setCouponCode(false);
    const handleShow = () => setCouponCode(true);
    const [coupon, setCoupon] = useState('')
    const handleCouponChange = (coupon) => {
        setCoupon(coupon);
    };

    const handleApplyClick = () => {
        setCouponCode(false);
        setCoupon('')
        const appliedTo = coupon?.advancedSetting?.appliedTo;
        const specificTypeIds = coupon?.advancedSetting?.specificType?.map(type => type.id) || [];

        const selected = appliedTo === "products" && listingCartCheckout?.filter(cartItem =>
            specificTypeIds?.includes(cartItem?.subCategoryId) ||
            specificTypeIds?.includes(cartItem?.mainCategoryId)
        )
        setSelectedPromo(selected)
        if (selected?.length > 0 || !coupon?.advancedSetting) {
            setSelectedCoupon(coupon);
        } else {
            setSelectedCoupon('')
            showErrorToastr(ToastrMessages?.COUPONCODENOTFOUND)
        }
    };

    return (
        <>
            <Modal show={couponCode} onHide={handleClose} animation={false} className='Coupon-Offers'>
                <Modal.Header closeButton>
                    <Modal.Title>All Coupons</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {Array.isArray(filteredDatePromoCode) && filteredDatePromoCode.map((item, index) => (
                            <Col xxl={12} xl={12} md={12} lg={12} className='mx-2 mb-2' key={index}>
                                <Form.Check
                                    label={
                                        <div>
                                            <p>
                                                <span>{item?.code}</span><br />
                                                {item?.description}
                                            </p>
                                            {item?.terms && (
                                                <Accordion>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header> View T & C</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p dangerouslySetInnerHTML={{ __html: item?.terms }} />
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            )}
                                        </div>
                                    }
                                    name="radio"
                                    type="radio"
                                    onChange={() => handleCouponChange(item)}
                                />
                                <hr />
                            </Col>
                        ))}
                    </Row>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <Button
                        onClick={handleApplyClick}
                        disabled={!coupon}
                        style={{ backgroundColor: !coupon ? '#D1CCCD' : '#045EC6', color: !coupon ? "#1A0003" : "#FFFFFF", border: "none" }}>
                        Apply
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Example;