import React, { useEffect, useState } from "react";
import ProductList from "../../../common/ProductList";
import {useLocation } from "react-router-dom";
import ProductListQuery from "../../../common/ProductListQuery"

const SearchProducts = () => {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    if (location?.state?.searchKeyword) {
      setSearchTerm(location?.state?.searchKeyword)
    }
  }, [location?.state])
  return (
    <ProductListQuery page="allProducts" searchTerm={searchTerm} />
  );
};

export default SearchProducts;
